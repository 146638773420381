import { Flex, message, Select, Space } from 'antd'
import { IScheduleTransform, type ISchoolDay, type IWeekSelectionPreset } from 'common-api'
import { isEqual } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import TypeScale from '../../styles/TypeScale'
import { togglePresence } from '../../utils/collections'
import { toTranslate } from '../../utils/miscUtil'
import AddPeriodDialog from './components/AddPeriodDialog'
import MonthHeader from './components/MonthHeader'
import { PresetHeader } from './components/PeriodHeader'
import SchoolDayToggleButton from './components/SchoolDayToggleButton'
import WeekHeader from './components/WeekHeader'
import WspWeekToggleButton from './components/WspWeekToggleButton'
import { useGenerateCells } from './hooks'
import { CalendarGrid, TableHeader } from './styled'
import type { MonthCell, WspWeekCell } from './types'
import { getSchoolDayFromMoment, schoolYearOptions } from './utils'
import classes from './style.module.css'

export const PeriodsIndexPage = () => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()

    const presets = schedule.getWeekSelectionPresets()
    const settings = schedule.getSettings()

    const { t } = useTranslation()
    const cells = useGenerateCells(presets, settings)

    const saveSchoolYearAndDays = useCallback(
        (schoolYear: number, schoolDays: ISchoolDay[]) => {
            dispatch(
                locallyTriggeredScheduleTransform(
                    IScheduleTransform.scheduleSettingsTransform({
                        newSettings: {
                            ...settings,
                            schoolYear,
                            schoolDays
                        }
                    })
                )
            )
        },
        [dispatch, settings]
    )

    const saveWeekSelectionPresets = useCallback(
        (weekSelectionPresets: IWeekSelectionPreset[]) => {
            dispatch(
                locallyTriggeredScheduleTransform(
                    IScheduleTransform.weekSelectionPresetsTransform({
                        newWeekSelectionPresets: weekSelectionPresets
                    })
                )
            )
        },
        [dispatch]
    )

    const toggleSchoolDay = useCallback(
        (sd: ISchoolDay) => {
            saveSchoolYearAndDays(settings.schoolYear, togglePresence(settings.schoolDays, sd))
        },
        [saveSchoolYearAndDays, settings.schoolYear, settings.schoolDays]
    )

    const setSchoolYear = useCallback(
        (year: number) => {
            saveSchoolYearAndDays(year, settings.schoolDays)
        },
        [saveSchoolYearAndDays, settings.schoolDays]
    )

    const collapsedCells = useMemo(() => {
        let prevMonthCell: MonthCell | undefined = undefined
        return cells.filter((thisCell, i) => {
            if (thisCell.type === 'MONTH') {
                if (prevMonthCell === undefined) {
                    prevMonthCell = thisCell
                    return true
                }
                if (prevMonthCell.month === thisCell.month) {
                    prevMonthCell.rowSpan++
                    return false // remove current cell
                } else {
                    prevMonthCell = thisCell
                    return true
                }
            }
            return true
        })
    }, [cells])

    const handleWspWeekToggleClick = useCallback(
        (cell: WspWeekCell) => {
            const newPresets: IWeekSelectionPreset[] = presets.map((preset) => {
                if (preset.weekSelectionPresetId !== cell.wspId) {
                    return preset
                } else {
                    return {
                        ...preset,
                        weeks: togglePresence(preset.weeks, cell.week)
                    }
                }
            })
            saveWeekSelectionPresets(newPresets)
        },
        [presets, saveWeekSelectionPresets]
    )

    const handleNewPeriod = (displayName: string) => {
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.weekSelectionPresetsTransform({
                    newWeekSelectionPresets: [
                        ...presets,
                        {
                            weekSelectionPresetId: uuid(),
                            displayName: displayName,
                            weeks: []
                        }
                    ]
                })
            )
        )
    }

    const handleDeleteWeekSelectionPreset = (presetId: string) => {
        // Look for lectures times referencing this wsp
        const problematicLectures = schedule
            .getLectures()
            .filter((l) => l.getWeekSelection().getWeekSelectionPreset().weekSelectionPresetId === presetId)
        if (problematicLectures.length > 0) {
            message.error(toTranslate(`${problematicLectures.length} lektion(er) refererar till perioden`))
            return
        }

        // Look for reserved times referencing this wsp
        const problematicReservedTimes = schedule
            .getReservedTimes()
            .filter((rt) => rt.getWeekSelection().getWeekSelectionPreset().weekSelectionPresetId === presetId)
        if (problematicReservedTimes.length > 0) {
            message.error(toTranslate(`${problematicReservedTimes.length} reserverade tider refererar till perioden`))
            return
        }

        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.weekSelectionPresetDeleteTransform({ weekSelectionPresetId: presetId })
            )
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                gap: '4em',
                flexDirection: 'column',
                padding: '2em',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <h1 className={TypeScale.Heading_XL}>{t('SchoolYear')}</h1>
                <Space>
                    <Space>
                        <span>{t('SchoolYear')}:</span>
                        <Select
                            value={settings.schoolYear}
                            options={schoolYearOptions}
                            onChange={(newYear) => setSchoolYear(newYear)}
                        />
                    </Space>
                    <AddPeriodDialog onNewPeriod={handleNewPeriod} />
                </Space>
            </div>

            <CalendarGrid numPresets={presets.length}>
                <TableHeader style={{ gridColumnStart: 3 }}>{t('WeekDaysShort.Monday')}</TableHeader>
                <TableHeader>{t('WeekDaysShort.Tuesday')}</TableHeader>
                <TableHeader>{t('WeekDaysShort.Wednesday')}</TableHeader>
                <TableHeader>{t('WeekDaysShort.Thursday')}</TableHeader>
                <TableHeader>{t('WeekDaysShort.Friday')}</TableHeader>
                <TableHeader>{t('WeekDaysShort.Saturday')}</TableHeader>
                <TableHeader>{t('WeekDaysShort.Sunday')}</TableHeader>
                <div>{/* GAP */}</div>

                {presets.map((p) => (
                    <PresetHeader
                        key={`header-${p.weekSelectionPresetId}`}
                        displayName={p.displayName}
                        onDelete={() => handleDeleteWeekSelectionPreset(p.weekSelectionPresetId)}
                    />
                ))}

                {collapsedCells.map((cell, i) => {
                    if (cell.type === 'WEEK') {
                        return <WeekHeader key={i}>v.{cell.week}</WeekHeader>
                    }
                    if (cell.type === 'MONTH') {
                        return <MonthHeader key={i} rowSpan={cell.rowSpan} month={cell.month} />
                    }
                    if (cell.type === 'DAY') {
                        const schoolDay = getSchoolDayFromMoment(cell.date)
                        return (
                            <SchoolDayToggleButton
                                key={i}
                                isSchoolDay={settings.schoolDays.find((e) => isEqual(e, schoolDay)) !== undefined}
                                date={cell.date}
                                onClick={() => toggleSchoolDay(schoolDay)}
                            />
                        )
                    }
                    if (cell.type === 'GAP') {
                        return <div key={i} />
                    }
                    if (cell.type === 'WSP_WEEK') {
                        return (
                            <WspWeekToggleButton
                                key={i}
                                week={cell.week}
                                isSelected={presets
                                    .find((p) => p.weekSelectionPresetId === cell.wspId)!
                                    .weeks.includes(cell.week)}
                                onClick={() => handleWspWeekToggleClick(cell)}
                                isEnabled={cell.enabled}
                            />
                        )
                    }
                })}
                <div className={classes.bottomPad} style={{ gridColumn: '1/10' }}>
                    {/* Gap below schedule*/}
                </div>
                <div className={classes.bottomPad} style={{ gridColumn: '11/-1' }}>
                    {/* Gap below schedule*/}
                </div>
                <div style={{ gridColumn: '1/11' }}>
                    <Flex justify="space-between">
                        <div>
                            <strong>{toTranslate('Antal dagar')}:</strong> {settings.schoolDays.length}
                        </div>
                        <div>
                            <strong>{toTranslate('Antal veckor')}:</strong>
                        </div>
                    </Flex>
                </div>
                {presets.map((p) => (
                    <div style={{ textAlign: 'center' }} key={`footer-${p.weekSelectionPresetId}`}>
                        {p.weeks.length}
                    </div>
                ))}
            </CalendarGrid>
        </div>
    )
}
