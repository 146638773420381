import { Space, message } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { CourseId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import type { CourseAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'

type CourseActionsPanelProps = {
    courses: CourseAccessor[]
    onDelete: () => void
}

export const CourseActionsPanel = (props: CourseActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const getCourseRoundsReferencingCourse = (cId: CourseId) =>
        schedule.getCourseRounds().filter((cr) => cr.getCourse()?.getCourseId() === cId)

    const handleDeleteCourse = () => {
        const problematicCourseRounds = props.courses
            .map((cr) => cr.getCourseId())
            .flatMap(getCourseRoundsReferencingCourse)
        if (problematicCourseRounds.length > 0) {
            message.error(t('Errors.CourseRoundsReferencing', { count: problematicCourseRounds.length }))
            return
        }
        props.onDelete()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform(
                    props.courses.map((cr) =>
                        IScheduleTransform.courseDeleteTransform({
                            courseId: cr.getCourseId()
                        })
                    )
                )
            )
        )
        message.success(t('Success.CourseRemoved'))
    }

    return (
        <Space direction="vertical">
            <Button variant="secondary" onClick={handleDeleteCourse}>
                {t(props.courses.length === 1 ? 'RemoveCourse' : 'RemoveCourses')}
            </Button>
        </Space>
    )
}
