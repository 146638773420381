import type { ITimeProblemPair } from 'common-api'
import { useEffect, useRef } from 'react'
import { useDragLayer } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import type { ApplicationState } from '../../../../store'
import { useDevModeState } from '../../../../store/devmode/hooks'
import { startDragLecture } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { ScheduleSelector } from '../../../../store/scheduleselector/types'
import { WeekDaySelectorState } from '../../../../store/weekDaySelector/types'
import { DndItemTypes } from '../../../../utils/DndItemTypes'
import { addMinutes } from '../../../canteen-load/utils'
import EventComponent from '../EventComponent'
import { ProblemListPopover } from '../ProblemListPopover'
import { isEventAmongSelectedSchedules } from '../Schedule/utils'
import type { ScheduleEvent } from '../ScheduleEvent'
import { isLectureEvent, LectureEvent } from '../ScheduleEvent'
import { MINUTES_PER_SLOT, toMinuteOfDay } from '../util'
import RestrictedZones from './RestrictedZones'
import { DragLayerWrapper } from './styled'
import { isVirtualProblem, toProblemListProblem } from '../ProblemsList/utils'
import { DragEventType, getLayoutSpec } from './utils'

type DragLayerProps = {
    selectedSchedule?: ScheduleSelector
}

import { useTranslation } from 'react-i18next'

export const DragLayer = ({ selectedSchedule }: DragLayerProps) => {
    const devMode = useDevModeState()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const schedule = useLocalSchedule()

    const selectedSchedules = useSelector<ApplicationState, ScheduleSelector[]>(
        (state) => state.scheduleSelection.selectedSchedules
    )

    const schedulesToRender = selectedSchedule ? [selectedSchedule] : selectedSchedules

    const selectedWeekDays = useSelector<ApplicationState, WeekDaySelectorState['selectedWeekDays']>(
        (state) => state.weekDaySelector.selectedWeekDays
    )

    const problematicTimeSlots = useSelector<ApplicationState, ITimeProblemPair[]>((state) =>
        state.schedule.problematicTimeslotsForDraggedLecture.filter(
            (tpp) => devMode || !isVirtualProblem(tpp.problem.problem)
        )
    )

    const { itemType, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialClientOffset(),
        currentOffset: monitor.getClientOffset()
    }))

    const dragEvent = item as DragEventType

    const eventsToDrag = (event: ScheduleEvent): ScheduleEvent[] => {
        return event.accept<ScheduleEvent[]>({
            visitLecture: (l) =>
                l
                    .getLecture()
                    .getEventGroup()
                    .getLectures()
                    .map((l) => new LectureEvent(l)),
            visitReservedTime: (rt) => [rt]
        })
    }

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (dragEvent?.event) {
            if (isLectureEvent(dragEvent.event)) {
                const lecture = dragEvent.event.getLecture()
                dispatch(
                    startDragLecture(lecture.getSchedule().getScheduleId(), lecture.getEventGroup().getEventGroupId())
                )
            }
        }

        return () => {}
    }, [dispatch, dragEvent])

    const startOfDay = schedule.getSettings().startOfDay!
    const endOfDay = schedule.getSettings().endOfDay!

    function renderItem() {
        if (itemType !== DndItemTypes.EVENT) {
            return null
        }

        const dropBounds = ref.current?.getBoundingClientRect()

        if (!dropBounds) {
            return null
        }

        const layoutSpec = getLayoutSpec(
            startOfDay,
            endOfDay,
            {
                currentOffset,
                initialOffset,
                dropBounds,
                dragEvent
            },
            selectedWeekDays
        )

        if (layoutSpec) {
            const problems = problematicTimeSlots
                .filter((timeslot) => {
                    return timeslot.dayAndTime.day === layoutSpec.dayAndTime.day
                })
                .filter((timeslot) => {
                    const dragStart = toMinuteOfDay(layoutSpec.dayAndTime)
                    const start = toMinuteOfDay(timeslot.dayAndTime)
                    const end = start + MINUTES_PER_SLOT

                    return dragStart >= start && dragStart < end
                })
                .map((timeslot) => timeslot.problem)

            return (
                <>
                    {schedulesToRender.flatMap((scheduleSelector, scheduleIndex) => {
                        const isAmongSelectedSchedules = isEventAmongSelectedSchedules(schedule, dragEvent.event, [
                            scheduleSelector
                        ])

                        if (!isAmongSelectedSchedules) {
                            return []
                        }

                        const events = eventsToDrag(dragEvent.event)

                        return events.flatMap((itemEvent: ScheduleEvent, index: number) => {
                            return itemEvent.accept({
                                visitLecture: (lecture) => {
                                    const eventComponent = (
                                        <EventComponent
                                            key={itemEvent.getUniqueId() + scheduleIndex}
                                            transition="all 0.1s ease 0s, top 0s"
                                            event={itemEvent}
                                            layoutSpec={{
                                                ...layoutSpec,
                                                dayAndTime: {
                                                    day: layoutSpec.dayAndTime.day,
                                                    ...addMinutes(
                                                        layoutSpec.dayAndTime,
                                                        lecture.getLecture().getRelativeStartTimeInMinutes()
                                                    )
                                                },
                                                scheduleSelectorIndex: scheduleIndex,
                                                numSelectedSchedules: schedulesToRender.length,
                                                column: index,
                                                columns: events.length
                                            }}
                                        />
                                    )

                                    // Wrap component in ProblemListPopover if it's the last component
                                    const lastEventComponent = index === events.length - 1
                                    const possiblyWrappedEventComponent = lastEventComponent ? (
                                        <ProblemListPopover
                                            key={itemEvent.getUniqueId() + scheduleIndex}
                                            problems={problems.map((pws) => toProblemListProblem(schedule, pws, t))}
                                            open={problems.length > 0}
                                        >
                                            {eventComponent}
                                        </ProblemListPopover>
                                    ) : (
                                        eventComponent
                                    )

                                    return [possiblyWrappedEventComponent]
                                },
                                visitReservedTime: (rt) => {
                                    return [
                                        <EventComponent
                                            key={itemEvent.getUniqueId() + scheduleIndex}
                                            transition="all 0.1s ease 0s, top 0s"
                                            event={itemEvent}
                                            layoutSpec={{
                                                ...layoutSpec,
                                                dayAndTime: layoutSpec.dayAndTime,
                                                scheduleSelectorIndex: scheduleIndex,
                                                numSelectedSchedules: schedulesToRender.length,
                                                column: index,
                                                columns: events.length
                                            }}
                                        />
                                    ]
                                }
                            })
                        })
                    })}
                </>
            )
        }

        return null
    }

    return (
        <DragLayerWrapper ref={ref}>
            <RestrictedZones
                currentOffset={currentOffset}
                initialOffset={initialOffset}
                dropBounds={ref.current?.getBoundingClientRect() ?? new DOMRect()}
                dragEvent={dragEvent}
            />

            {renderItem()}
        </DragLayerWrapper>
    )
}
