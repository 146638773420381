import { Card, Flex } from 'antd'
import { useState } from 'react'
import { VisibleColumnsSelector } from '../../../../components/VisibleColumnsSelector'
import { useIsDummySchedule, useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { FilterInput } from '../../../schedule/components/FilterInput'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import { AddTeacherButton } from '../NewTeacherButton'
import { TableEditModeSwitch } from '../TableEditModeSwitch'
import { useTeacherColumns } from './columns'
import { tableCardBodyStyle, tableCardHeaderStyle } from './constants'
import classes from './style.module.css'
import { TeacherEditTable } from './TeacherEditTable'
import { TeacherViewTable } from './TeacherViewTable'
import type { TeachersTableProps } from './types'

import { TableEditMode } from '../TableEditModeSwitch/types'
import './table-card.css'

export const TeachersTable = ({ selectedRowKeys, setSelectedRowKeys }: TeachersTableProps) => {
    const [mode, setMode] = useState(TableEditMode.VIEW_MODE)
    const filtering = useFiltering()
    const isDummySchedule = useIsDummySchedule()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useTeacherColumns(filtering)

    const dataSource = schedule
        .getTeachers()
        .map((teacher) => ({ teacher }))
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ teacher }) => teacher.getTeacherSchoolId()))

    const setModeWrapper = (newMode: TableEditMode) => {
        setSelectedRowKeys([])
        setMode(newMode)
    }

    return (
        <Card
            className={classes.tableCard}
            styles={{
                header: tableCardHeaderStyle,
                body: tableCardBodyStyle
            }}
            title={
                <Flex gap={8} align="center">
                    <FilterInput {...filtering} disabled={mode === TableEditMode.EDIT_MODE || isDummySchedule} />
                    <VisibleColumnsSelector
                        columns={columns}
                        setVisibleColumns={setVisibleColumns}
                        disabled={mode === TableEditMode.EDIT_MODE || isDummySchedule}
                    />
                    <TableEditModeSwitch disabled={isDummySchedule} setMode={setModeWrapper} mode={mode} />
                    <AddTeacherButton
                        onTeacherCreated={(newTeacherId) => {
                            setSelectedRowKeys([newTeacherId])
                        }}
                    />
                </Flex>
            }
        >
            {mode === TableEditMode.VIEW_MODE ? (
                <TeacherViewTable
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    columns={columns}
                    dataSource={dataSource}
                />
            ) : (
                <TeacherEditTable />
            )}
        </Card>
    )
}
