import { useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import type { CourseRoundId } from '../../commonTypes'
import { DndItemTypes } from '../../utils/DndItemTypes'
import { CourseRoundCard } from './CourseRoundCard'
import { CrDragCourseRoundType } from './util'

interface DraggableCourseRoundProps {
    courseRoundId: CourseRoundId
    row?: number
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
    selected?: boolean
    isInFilter?: boolean
    onStartDrag: () => void
    onEndDrag: () => void
}

export const DraggableCourseRound = ({
    courseRoundId,
    isInFilter,
    onClick,
    onEndDrag,
    onStartDrag,
    row,
    selected
}: DraggableCourseRoundProps) => {
    const [_, drag, preview] = useDrag(
        () => ({
            type: DndItemTypes.EVENT,
            item: () => {
                onStartDrag()

                return { courseRoundId } as CrDragCourseRoundType
            },
            end: onEndDrag
        }),
        [courseRoundId, row, onStartDrag]
    )

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    return (
        <div onClick={onClick} ref={drag} style={{ height: '100%' }}>
            <CourseRoundCard isInFilter={isInFilter} selected={selected} courseRoundId={courseRoundId} />
        </div>
    )
}
