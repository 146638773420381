import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

/**
 * A hook that manages edit/view mode state using URL routing
 * Uses '/edit' suffix in URL to indicate edit mode
 * Syncs state across multiple components using the same hook
 *
 * @returns Object containing current mode and function to change mode
 */
export const useEditModeSwitch = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [isEditModeToggled, setToggleIsEditMode] = useState(() => {
        return location.pathname.endsWith('/edit')
    })

    useEffect(() => {
        const isEditMode = location.pathname.endsWith('/edit')
        setToggleIsEditMode(isEditMode)
    }, [location.pathname])

    const toggleEditMode = (isToggled: boolean) => {
        const newPath = isToggled ? `${location.pathname}/edit` : location.pathname.replace('/edit', '')
        navigate(newPath)
    }

    return {
        isEditModeToggled,
        toggleEditMode
    }
}
