import type { ISchoolDay, IWeekSelectionPreset } from 'common-api'
import dayjs, { type Dayjs } from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import type { IScheduleServicePeriod } from 'meitner-api'
import { Endpoints } from '../../services/Endpoints'
import { ALL_DAYS } from '../../utils/DayAndTimeUtil'
import { comparing } from '../../utils/compareUtil'
import { FIRST_SCHOOL_YEAR_WEEK } from '../periods/constants'

dayjs.extend(isoWeek)

export const listDstPeriods = async (targetScheduleId: string) =>
    Endpoints.meitnerApi
        .scheduleServiceListPeriods({
            schedule_id: targetScheduleId,
            pagination: { page: 0, size: 0 }
        })
        .then((response) => response.schedule_periods)

// Translate fields common to both create and update requests
const translateCommonFields = (year: number, schoolDays: ISchoolDay[], srcPeriod: IWeekSelectionPreset) => ({
    title: srcPeriod.displayName,
    description: '',
    external_id: srcPeriod.weekSelectionPresetId,
    calendar: convertWeekArrayToDatePeriodsString(year, schoolDays, srcPeriod.weeks),
    default_school_year: srcPeriod.displayName === 'Läsår' // TODO: Add a proper property for this information
})

export const createDstPeriod =
    (year: number, schoolDays: ISchoolDay[]) => async (targetScheduleId: string, srcPeriod: IWeekSelectionPreset) =>
        Endpoints.meitnerApi
            .scheduleServiceCreatePeriod({
                id: null,
                schedule_id: targetScheduleId,
                ...translateCommonFields(year, schoolDays, srcPeriod)
            })
            .then((response) => response.created.id!)

export const deleteDstPeriod = async (periodId: string) =>
    Endpoints.meitnerApi.scheduleServiceDeletePeriod({
        id: periodId
    })

export const createUpdateDstPeriod =
    (year: number, schoolDays: ISchoolDay[]) =>
    async (srcPeriod: IWeekSelectionPreset, dstPeriod: IScheduleServicePeriod) =>
        Endpoints.meitnerApi
            .scheduleServiceUpdatePeriod({
                ...dstPeriod,
                ...translateCommonFields(year, schoolDays, srcPeriod)
            })
            .then((response) => response.updated.id!)

// Format that is expected by the Meitner API
const formatDay = (day: Dayjs) => day.format('YYYY-MM-DD')

// Formats array of consecutive days as YYYY-MM-DD or YYYY-MM-DD>YYYY-MM-DD
const formatConsecutiveDays = (days: Dayjs[]) =>
    formatDay(days[0]) + (days.length === 1 ? '' : `>${formatDay(days[days.length - 1])}`)

const convertWeekArrayToDatePeriodsString = (schoolYear: number, schoolDays: ISchoolDay[], isoWeeks: number[]) => {
    const schoolDaysInPeriod = schoolDays.filter((sd) => isoWeeks.includes(sd.isoWeek))
    const daysInPeriod = schoolDaysInPeriod.map((sd) =>
        dayjs()
            .year(sd.isoWeek >= FIRST_SCHOOL_YEAR_WEEK ? schoolYear : schoolYear + 1)
            .isoWeek(sd.isoWeek)
            .isoWeekday(ALL_DAYS.indexOf(sd.dayOfWeek) + 1)
            .startOf('day')
    )

    daysInPeriod.sort(comparing((day) => day.unix()))

    // Split into chunks of consecutive days
    const chunksOfConsecutiveDays = []
    for (let j = 0; j < daysInPeriod.length - 1; j++) {
        if (daysInPeriod[j + 1].diff(daysInPeriod[j], 'day') !== 1) {
            chunksOfConsecutiveDays.push(daysInPeriod.splice(0, j + 1))
            j = 0
        }
    }

    if (daysInPeriod.length > 0) {
        chunksOfConsecutiveDays.push(daysInPeriod.splice(0))
    }

    return chunksOfConsecutiveDays.map(formatConsecutiveDays).join(',')
}
