import { Flex, Switch, Typography } from 'antd'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'
import classes from './style.module.css'
import { TableEditMode, TableEditModeSwitchProps } from './types'

export const TableEditModeSwitch = ({ disabled = false, mode, setMode }: TableEditModeSwitchProps) => {
    const id = useId()
    const { t } = useTranslation()

    return (
        <Flex gap=".5em" className={classes.modeSwitch}>
            <Switch
                id={id}
                value={mode == TableEditMode.EDIT_MODE}
                disabled={disabled}
                onChange={(isChecked) => setMode(isChecked ? TableEditMode.EDIT_MODE : TableEditMode.VIEW_MODE)}
            />
            <label htmlFor={id}>
                <Typography.Text strong={false}>{t('TableEditing')}</Typography.Text>
            </label>
        </Flex>
    )
}
