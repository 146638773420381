const MAXIMUM_SCORE = 4
const MINIMUM_ACCEPTABLE_SCORE = 2

type MatchRowsWithExistingData<T, K> = {
    existingData: T[]
    rows: K[]
    matchRow: keyof K
    matchScore: (row: K, data: T) => number
}

// Match rows with existing data
export const matchRowsWithExistingData = <T, K>({
    existingData,
    rows,
    matchRow,
    matchScore
}: MatchRowsWithExistingData<T, K>) => {
    /* eslint-disable no-loop-func */

    // (There are more clever ways to implement this functionality, such as the Hungarian method, Ford-Fulkerson, or
    // Kuhn's Algorithm, but the complexity is high, and the result is rarely better than the simple greedy algorithm
    // below.)

    // Algorithm:
    // 1. Set desiredScore to MAXIMUM_SCORE
    // 2. For each (row, data) pair that satisfies the desiredScore:
    //    2a. Store it in result map
    //    2b. Remove the matched row and data
    // 4. Lower desiredScore, and repeat process until all rows are matched or MINIMUM_ACCEPTABLE_SCORE is reached.

    const result = new Map<number, T>()
    const existingDataLeftToMatch = [...existingData]
    let rowsLeftToMatch = [...rows]
    let desiredScore = MAXIMUM_SCORE
    while (desiredScore >= MINIMUM_ACCEPTABLE_SCORE && rowsLeftToMatch.length > 0) {
        const unmatchRowsAtThisScore = []
        for (const rowToMatch of rowsLeftToMatch) {
            const i = existingDataLeftToMatch.findIndex((t) => matchScore(rowToMatch, t) >= desiredScore)
            if (i !== -1) {
                result.set(Number(rowToMatch[matchRow]), existingDataLeftToMatch[i])
                existingDataLeftToMatch.splice(i, 1)
            } else {
                unmatchRowsAtThisScore.push(rowToMatch)
            }
        }

        desiredScore--
        rowsLeftToMatch = unmatchRowsAtThisScore
    }

    return result
}
