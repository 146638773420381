import classNames from 'classnames'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import Focusable from '../Focusable'
import classes from './style.module.css'
import type { ButtonProps } from './types'

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant,
            type = 'button',
            size = 'md',
            fullWidth = false,
            loading = false,
            disabled = false,
            destructive = false,
            className,
            startIcon,
            endIcon,
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation()

        return (
            <Focusable>
                <button
                    {...props}
                    ref={ref}
                    type={type}
                    className={classNames(
                        classes.button,
                        className,
                        classes[`button--size-${size}`],
                        classes[`button--variant-${variant}`],
                        {
                            [classes['button--disabled']]: disabled,
                            [classes['button--loading']]: loading,
                            [classes['button--destructive']]: destructive,
                            [classes['button--fullWidth']]: fullWidth
                        }
                    )}
                    disabled={disabled || loading}
                >
                    {loading ? (
                        t('Loading')
                    ) : (
                        <>
                            {startIcon}
                            {props.children}
                            {endIcon}
                        </>
                    )}
                </button>
            </Focusable>
        )
    }
)

export default Button
