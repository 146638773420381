import Icon, {
    BarChartOutlined,
    CalendarOutlined,
    ControlOutlined,
    ExportOutlined,
    ImportOutlined,
    LeftOutlined,
    LineChartOutlined,
    ReadOutlined,
    RedoOutlined,
    SettingOutlined,
    TableOutlined,
    TeamOutlined
} from '@ant-design/icons'
import { Button, Flex, Layout, Menu, Modal, Space } from 'antd'
import { ScheduleType } from 'common-api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import SvgDoor from '../../icons/SvgDoor'
import SvgLunch from '../../icons/SvgLunch'
import SvgTeacher from '../../icons/SvgTeacher'
import { useDevModeState } from '../../store/devmode/hooks'
import { useLocalSchedule } from '../../store/schedule/hooks'
import styled from '../../utils/styled'
import { MeitnerLogo } from '../MeitnerLogo'
import { ScheduleChecklistPanel } from '../schedule-checklist/ScheduleChecklistPanel'
import { ScheduleChecklistProgressBar } from '../schedule-checklist/ScheduleChecklistProgressBar'
import { AutoSchedulerSseConnectionIndicator } from '../SseConnectionIndicator/AutoSchedulerSseConnectionIndicator'
import { ScheduleSseConnectionIndicator } from '../SseConnectionIndicator/ScheduleSseConnectionIndicator'

const { Sider } = Layout

const siderBorder = 'thin solid #d9d9d9'

const MenuSider = () => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [isScheduleChecklistModalOpen, setIsScheduleChecklistModalOpen] = useState(false)
    const { pathname } = useLocation()
    const devMode = useDevModeState()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const schedule = useLocalSchedule()

    const showCourses = schedule.getSettings().scheduleType === ScheduleType.GY
    const selectedColorStyle = (pn: string) => (pathname === pn ? { color: 'inherit' } : { color: 'black' })

    return (
        <Sider
            theme="light"
            style={{ borderRight: siderBorder }}
            collapsible
            collapsed={isCollapsed}
            onCollapse={(value) => setIsCollapsed(value)}
        >
            <div>
                <MeitnerLogo isCollapsed={isCollapsed} />
                <a
                    href={`${process.env.REACT_APP_MEITNER_ADMIN_URL}/school/scheduling/`}
                    style={{
                        display: 'block',
                        color: '#444',
                        border: '1px solid #ddd',
                        background: '#f8f8f8',
                        margin: '.4em',
                        padding: '.8em 1.5em',
                        borderRadius: '8px'
                    }}
                >
                    <LeftOutlined style={{ color: '#999' }} /> Tillbaka till Admin
                </a>
                <StyledMenu
                    mode="inline"
                    openKeys={[
                        pathname,
                        ...(pathname === '/lectures' ? ['/schedule'] : []),
                        ...(pathname === '/teaching-assignments' ? ['/course-rounds'] : []),
                        ...(pathname.startsWith('/reports') ? ['/reports'] : [])
                    ]}
                    selectedKeys={[pathname]}
                >
                    <StyledSubMenu
                        title={
                            <Link to="/schedule" style={selectedColorStyle('/schedule')}>
                                {t('MainMenu.Schedule')}
                            </Link>
                        }
                        key="/schedule"
                        selected={pathname === '/schedule'}
                        onTitleClick={() => navigate('/schedule')}
                        icon={<CalendarOutlined style={selectedColorStyle('/schedule')} />}
                    >
                        <Menu.Item key="/lectures" icon={<TableOutlined />}>
                            <Link to="/lectures">{t('MainMenu.Lectures')}</Link>
                        </Menu.Item>
                    </StyledSubMenu>
                    <StyledSubMenu
                        title={
                            <Link to="/course-rounds" style={selectedColorStyle('/course-rounds')}>
                                {t('MainMenu.ServiceDistribution')}
                            </Link>
                        }
                        key="/course-rounds"
                        selected={pathname === '/course-rounds'}
                        onTitleClick={() => navigate('/course-rounds')}
                        icon={<ReadOutlined style={selectedColorStyle('/course-rounds')} />}
                    >
                        <Menu.Item key="/teaching-assignments" icon={<BarChartOutlined rotate={90} />}>
                            <Link to="/teaching-assignments">{t('GraphicalView')}</Link>
                        </Menu.Item>
                    </StyledSubMenu>
                    <Menu.Item key="/schedule-parameters" icon={<ControlOutlined />}>
                        <Link to="/schedule-parameters">{t('Settings')}</Link>
                    </Menu.Item>
                    <Menu.Item icon={<Icon component={SvgTeacher} />} key="/teachers">
                        <Link to="/teachers">{t('Staff')}</Link>
                    </Menu.Item>
                    <Menu.Item icon={<Icon component={SvgDoor} />} key="/rooms">
                        <Link to="/rooms">{t('MainMenu.Rooms')}</Link>
                    </Menu.Item>
                    <Menu.Item icon={<TeamOutlined />} key="/student-groups">
                        <Link to="/student-groups">{t('MainMenu.StudentGroups')}</Link>
                    </Menu.Item>
                    <Menu.Item icon={<RedoOutlined />} key="/periods">
                        <Link to="/periods">{t('MainMenu.Periods')}</Link>
                    </Menu.Item>
                    {showCourses && (
                        <Menu.Item icon={<ReadOutlined />} key="/courses">
                            <Link to="/courses">{t('MainMenu.Courses')}</Link>
                        </Menu.Item>
                    )}
                    <Menu.Item icon={<ReadOutlined />} key="/subjects">
                        <Link to="/subjects">{t('Subjects')}</Link>
                    </Menu.Item>
                    <StyledSubMenu
                        icon={<LineChartOutlined style={selectedColorStyle('/reports')} />}
                        key="/reports"
                        selected={false}
                        title={t('MainMenu.Reports')}
                        onTitleClick={() => navigate('/reports/canteen-load')}
                    >
                        <Menu.Item icon={<Icon component={SvgLunch} />} key="/reports/canteen-load">
                            <Link to="/reports/canteen-load">{t('MainMenu.CanteenLoad')}</Link>
                        </Menu.Item>
                    </StyledSubMenu>
                    <Menu.Item icon={<ExportOutlined />} key="/import-export">
                        <Link to="/import-export">{t('Transfer')}</Link>
                    </Menu.Item>
                    <Menu.Item icon={<ImportOutlined />} key="/rs-import">
                        <Link to="/rs-import">{t('Import')}</Link>
                    </Menu.Item>
                    {devMode && (
                        <Menu.Item key="/settings" icon={<SettingOutlined rotate={90} />}>
                            <Link to="/settings">{t('Configuration')}</Link>
                        </Menu.Item>
                    )}
                </StyledMenu>
                {devMode && (
                    <Space direction="vertical" style={{ margin: '2em' }}>
                        <ScheduleSseConnectionIndicator />
                        <AutoSchedulerSseConnectionIndicator />
                    </Space>
                )}
                <Flex gap={5} vertical style={{ marginTop: '3em', padding: '0 1.2em' }}>
                    <ScheduleChecklistProgressBar />
                    <Button type="link" onClick={() => setIsScheduleChecklistModalOpen(true)}>
                        {t('OpenChecklist')}
                    </Button>
                    <NoPaddingModal
                        maskClosable
                        open={isScheduleChecklistModalOpen}
                        closable
                        onCancel={() => setIsScheduleChecklistModalOpen(false)}
                        keyboard
                        width="90%"
                        footer={null}
                    >
                        <ScheduleChecklistPanel onNavigateAway={() => setIsScheduleChecklistModalOpen(false)} />
                    </NoPaddingModal>
                </Flex>
            </div>
        </Sider>
    )
}

const StyledMenu = styled(Menu)`
    border-right: none;
`

const StyledSubMenu = styled(Menu.SubMenu)<{ selected: boolean }>`
    & > div {
        background-color: ${(props) => (props.selected ? '#e6f4ff' : 'transparent')};
    }
`

const NoPaddingModal = styled(Modal)`
    max-width: 100em;
    & .ant-modal-content {
        padding: 0;
    }
`

export default MenuSider
