import { put, select, takeEvery } from 'redux-saga/effects'
import { ScheduleAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { applyAllTransforms } from '../../utils/scheduleTransforms'
import { ApplicationState } from '../index'
import { setWeekSelection } from './actions'
import { initialState } from './reducer'

// TODO: Default to 'Läsår' schedule and not just the first
const getDefaultWeekSelectionForSchedule = (schedule: ScheduleAccessor) => ({
    weekSelectionPresetId: schedule.getWeekSelectionPresets()[0].weekSelectionPresetId,
    includes: [],
    excludes: []
})

function* maybeAdjustWeekSelection(): any {
    const scheduleState = yield select((state: ApplicationState) => state.schedule)
    const weekSelection = yield select((state: ApplicationState) => state.weekSelection)

    const scheduleExists = scheduleState.schedule !== undefined // always true
    const realWeekSelection = weekSelection.weekSelectionPresetId !== '' // always true

    //                          no schedule           some schedule
    //                      .----------------------+------------------------------.
    //   no week selection  | (A) do nothing       | (B) set to läsår             |
    //                      +----------------------+------------------------------+
    // some week selection  | (C) set to dummy     | (D) valid => do nothing      |
    //                      |                      | (E) invalid => set to läsår  |
    //                      '----------------------+------------------------------'

    // Case A:
    if (!scheduleExists && !realWeekSelection) {
        return
    }

    // Case B:
    if (scheduleExists && !realWeekSelection) {
        console.log('[week selection saga] Case B => adjusting week selection')
        yield put(setWeekSelection(getDefaultWeekSelectionForSchedule(scheduleState.schedule)))

        return
    }

    // Case C:
    if (!scheduleExists && realWeekSelection) {
        console.log('[week selection saga] Case C => set to dummy')
        yield put(setWeekSelection(initialState))

        return
    }

    // Case D or E
    if (scheduleExists && realWeekSelection) {
        const localSchedule = applyAllTransforms(scheduleState.schedule, scheduleState.pendingTransforms)
        if (localSchedule.doesWeekSelectionPresetIdExist(weekSelection.weekSelectionPresetId)) {
            // Case D
        } else {
            // Case E
            console.log('[week selection saga] Case E => adjusting week selection')
            yield put(setWeekSelection(getDefaultWeekSelectionForSchedule(scheduleState.schedule)))
        }
    }
}

function* weekSelectionSaga() {
    yield takeEvery((action: any) => action.type.startsWith('schedule/'), maybeAdjustWeekSelection)
}

export default weekSelectionSaga
