import { PlusOutlined } from '@ant-design/icons'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import type { RoomId } from '../../../commonTypes'
import Button from '../../../components/Button'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { generateFreshName } from '../../../utils/miscUtil'

type NewRoomButtonProps = {
    onNewRoom: (newRoomId: RoomId) => void
}

export const NewRoomButton = ({ onNewRoom }: NewRoomButtonProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const freshRoomName = () =>
        generateFreshName(
            'Ny Sal',
            schedule.getRooms().map((r) => r.getName())
        )

    const handleNewRoom = () => {
        const newRoomId = uuid()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.roomTransform({
                    newRoom: {
                        roomId: newRoomId,
                        name: freshRoomName(),
                        attributes: []
                    }
                })
            )
        )
        onNewRoom(newRoomId)
    }

    return (
        <Button variant="primary" size="sm" startIcon={<PlusOutlined />} onClick={handleNewRoom}>
            {t('AddRoom')}
        </Button>
    )
}
