import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'
import type { FilterableColumn } from '../../../components/table-support/types'
import {
    deriveRenderPropertyFromFilterTexts,
    deriveSorterPropertyFromFilterTexts,
    hideNonSelectedColumns
} from '../../../components/table-support/util'
import type { FilteringState } from '../../schedule/components/FilterInput/types'
import type { SubjectRow } from './SubjectsTable/types'
import { toTranslate } from '../../../utils/miscUtil'
import { comparing } from '../../../utils/compareUtil'
import Color from 'colorjs.io'
import { Tooltip } from 'antd'

export enum SubjectsColumnKey {
    Name = 0,
    Code = 1,
    Color = 2
}

export const useSubjectColumns = (filtering: FilteringState) => {
    const [visibleColumns, setVisibleColumns] = useLocalStorage('visibleSubjectColumns', [
        SubjectsColumnKey.Name,
        SubjectsColumnKey.Code
    ])
    const { t } = useTranslation()
    const columns: FilterableColumn<SubjectRow>[] = [
        {
            title: t('Name'),
            key: SubjectsColumnKey.Name,
            filterTexts: ({ subject }) => [subject.getName()]
        },
        {
            title: t('Code'),
            key: SubjectsColumnKey.Code,
            filterTexts: ({ subject }) => [subject.getCode()]
        },
        {
            title: toTranslate('Färg'),
            key: SubjectsColumnKey.Color,
            sorter: comparing(({subject}) => new Color(`#${subject.getColor()}`).to('hsl').h),
            render: (_, { subject }) => ({
                props: {
                    style: {
                        backgroundColor: `#${subject.getColor()}`,
                        borderColor: `#${subject.getColor()}`,
                        padding: 0,
                        height: '100%'
                    }
                },
                children: <Tooltip title={`#${subject.getColor()}`}><div style={{ height: '100%'}}>&nbsp;</div></Tooltip>
            })
        }
    ]

    hideNonSelectedColumns(columns, visibleColumns)
    deriveRenderPropertyFromFilterTexts(filtering, columns)
    deriveSorterPropertyFromFilterTexts(columns)

    return { columns, setVisibleColumns }
}
