import type { IEventGroup, ILecture, IWeekSelectionPreset } from 'common-api'
import { Terms } from 'common-api'
import { v4 as uuid } from 'uuid'
import { MINUTES_PER_SLOT } from '../../pages/schedule/components/util'
import type { CourseRoundAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { hoursToSlots } from '../../utils/DayAndTimeUtil'
import { AppError } from '../../utils/errorutil'
import { nullToUndefined } from '../../utils/miscUtil'

import SPRING = Terms.SPRING
import FALL = Terms.FALL

// Distribute slots in a way such that
//   1. The sum of all parts add up to numLectureSlotsPerWeek
//   2. The parts are as evenly distributed as possible (two parts differ with at most 1)
//   3. No part exceeds maxSlotsPerLecture
function distributeUnitsEvenly(unitsToDistribute: number, maxUnitsPerPart: number): number[] {
    if (unitsToDistribute === 0) {
        return []
    }

    // Example input:
    //   - unitsToDistribute: 53
    //   - maxUnitsPerPart: 11
    //
    // Steps:
    //   - 53/11 gives us ~4.8 units per part...
    //   - ...so we'll need 5 parts.
    //   - Each part should have at least floor(53 / 5) = 10 units.
    //   - With 5 parts × 10 units, we'll have 3 units left do distribute.
    //   - Distribute these among the 3 first parts
    //
    // Illustration:
    //
    //       5 parts in total
    //       ________________
    //     /                 \
    //    [11, 11, 11, 10, 10]
    //      ^   ^   ^
    //      |   |   |
    //   the 3 remainder slots

    const estimatedNumParts = unitsToDistribute / maxUnitsPerPart
    const numParts = Math.ceil(estimatedNumParts)
    const baseUnitsPerPart = Math.floor(unitsToDistribute / numParts)
    const remainderUnitsToDistribute = unitsToDistribute % numParts

    return [
        ...Array(remainderUnitsToDistribute).fill(baseUnitsPerPart + 1),
        ...Array(numParts - remainderUnitsToDistribute).fill(baseUnitsPerPart)
    ]
}

function wrapLectureInEventGroup(lecture: ILecture): IEventGroup {
    return {
        eventGroupId: uuid(),
        timeslotPinned: false,
        displayName: 'event group',
        lectures: [lecture]
    }
}

export function generateLectureEventGroups(
    cr: CourseRoundAccessor,
    wspYear: IWeekSelectionPreset,
    wspSpring: IWeekSelectionPreset,
    wspFall: IWeekSelectionPreset
): IEventGroup[] {
    // How many hours do we need to cover?
    // In how many weeks should these hours be distributed?
    // How many hours per week is that?
    // With the given max lecture length, how many and how long lectures do we need?

    // Num slots to cover
    const totalSlots = hoursToSlots(cr.getTotalHours())

    // Num weeks to spread out over
    const terms = cr.getTerms()
    const wsp = terms === SPRING ? wspSpring : terms === FALL ? wspFall : wspYear
    const numWeeks = wsp.weeks.length

    // Max slots per lecture
    const lectureDurationThresholds = cr.getLectureDurationThresholds(true)
    if (lectureDurationThresholds === undefined) {
        throw new AppError('Expected to find lecture duration thresholds.')
    }

    const softMaxMinutes = nullToUndefined(lectureDurationThresholds.maxDuration.softMinutes)
    if (softMaxMinutes === undefined) {
        throw new AppError('Expected to find a soft max duration.')
    }

    const maxSlotsPerLecture = softMaxMinutes / MINUTES_PER_SLOT

    // Slots per week
    const slotsPerWeek = Math.round(totalSlots / numWeeks)

    // Array of lecture lengths in slots
    const lectureSlots = distributeUnitsEvenly(slotsPerWeek, maxSlotsPerLecture)

    return lectureSlots
        .map((slotsForLecture) => slotsForLecture * MINUTES_PER_SLOT)
        .map(
            (lectureDurationInMinutes) =>
                ({
                    lectureId: uuid(),
                    courseRoundId: cr.getCourseRoundId(),
                    weekSelection: {
                        weekSelectionPresetId: wsp.weekSelectionPresetId,
                        includes: [],
                        excludes: []
                    },
                    relStartTimeInMinutes: 0,
                    durationInMinutes: lectureDurationInMinutes,
                    roomAssignments: [
                        {
                            pinned: false,
                            requiredAttributes: []
                        }
                    ],
                    labels: []
                }) as ILecture
        )
        .map(wrapLectureInEventGroup)
}
