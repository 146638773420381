import { useState } from 'react'

export const useRapidCounterState = (countExpiryMs: number): [() => number, () => void] => {
    const [incrementTimestamps, setIncrementTimestamps] = useState<number[]>([])

    const increment = () => {
        const now = Date.now()
        const newTimestamps = [...incrementTimestamps, now].filter((ts) => ts > now - countExpiryMs)
        setIncrementTimestamps(newTimestamps)

        return newTimestamps.length
    }

    const reset = () => {
        setIncrementTimestamps([])
    }

    return [increment, reset]
}
