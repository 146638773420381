import { Space, message } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { SubjectId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import type { SubjectAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'

type SubjectActionsPanelProps = {
    subjects: SubjectAccessor[]
    onDelete: () => void
}

export const SubjectActionsPanel = (props: SubjectActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const subjectIds = props.subjects.map((s) => s.getSubjectId())

    const getCourseRoundsReferencingSubject = (sId: SubjectId) =>
        schedule.getCourseRounds().filter((c) => c.getSubject().getSubjectId() === sId)

    const getCoursesReferencingSubject = (sId: SubjectId) =>
        schedule.getCourses().filter((c) => c.getSubject().getSubjectId() === sId)

    const handleDeleteCourse = () => {
        // Look for courses referencing subjects
        const problematicCourses = subjectIds.flatMap(getCoursesReferencingSubject)
        if (problematicCourses.length > 0) {
            message.error(t('Errors.LecturesReferencing', { count: problematicCourses.length }))

            return
        }

        // Look for course rounds referencing subjects
        const problematicCourseRounds = subjectIds.flatMap(getCourseRoundsReferencingSubject)
        if (problematicCourseRounds.length > 0) {
            message.error(t('Errors.LecturesReferencing', { count: problematicCourseRounds.length }))

            return
        }

        props.onDelete()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform(
                    props.subjects.map((s) =>
                        IScheduleTransform.subjectDeleteTransform({
                            subjectId: s.getSubjectId()
                        })
                    )
                )
            )
        )
        message.success(t(subjectIds.length === 1 ? 'Success.SubjectRemoved' : 'Success.SubjectsRemoved'))
    }

    return (
        <Space direction="vertical">
            <Button variant="secondary" onClick={handleDeleteCourse}>
                {t(props.subjects.length === 1 ? 'RemoveSubject' : 'RemoveSubjects')}
            </Button>
        </Space>
    )
}
