import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'
import { useSchedule } from '../../../store/schedule/hooks'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const CourseRoundFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const { t } = useTranslation()
    const allCourseRoundFilters = schedule.getCourseRounds().map((cr) => ({
        value: cr.getCourseRoundId(),
        text: cr.getDisplayName()
    }))

    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allCourseRoundFilters}
            noKeysAvailableDescription={t('NoCourseRounds')}
        />
    )
}
