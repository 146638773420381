import { useTranslation } from 'react-i18next'
import { EditModeDropdown } from '../../../../EditModeDropdown'
import type { OverrideToggleButtonProps } from './types'

export const OverrideToggleButton = ({ onChange, value = false }: OverrideToggleButtonProps) => {
    const { t } = useTranslation()

    const items = [
        {
            label: t('InheritFromCourseRound'),
            isSelected: !value,
            onClick: () => onChange(false)
        },
        {
            label: t('SetSpecificForLesson'),
            isSelected: value,
            onClick: () => onChange(true)
        }
    ]

    return <EditModeDropdown isEditActive={value} items={items} />
}
