import type { IScheduleSettings } from 'common-api'
import { DayOfWeek } from 'common-api'
import { range } from 'lodash'
import moment from 'moment'
import { twoDigits } from '../../utils/DayAndTimeUtil'
import { FIRST_SCHOOL_YEAR_WEEK } from './constants'

// Example: (year 23/24) week 5 => "2023-02-01>2023-02-05"
const calendarStringForWeek = (isoWeek: number, settings: IScheduleSettings) => {
    const year = isoWeek >= FIRST_SCHOOL_YEAR_WEEK ? settings.schoolYear : settings.schoolYear + 1
    const m = moment().year(year).isoWeek(isoWeek).startOf('isoWeek')
    const from = m.format('YYYY-MM-DD')
    const to = m.add(4, 'day').format('YYYY-MM-DD')

    return `${from}>${to}`
}

const schoolYearOptions = range(2023, 2028).map((year) => ({
    value: year,
    label: `${twoDigits(year % 100)}\u2009/\u2009${twoDigits((year + 1) % 100)}`
}))

const getSchoolDayFromMoment = (d: moment.Moment) => ({
    isoWeek: d.isoWeek(),
    dayOfWeek: [
        DayOfWeek.SUNDAY,
        DayOfWeek.MONDAY,
        DayOfWeek.TUESDAY,
        DayOfWeek.WEDNESDAY,
        DayOfWeek.THURSDAY,
        DayOfWeek.FRIDAY,
        DayOfWeek.SATURDAY
    ][d.day()]
})

export { calendarStringForWeek, getSchoolDayFromMoment, schoolYearOptions }
