import { Space, message } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Button from '../../components/Button'
import type { StudentGroupAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'

type StudentGroupActionsPanelProps = {
    studentGroups: StudentGroupAccessor[]
    onDelete: () => void
}

export const StudentGroupActionsPanel = (props: StudentGroupActionsPanelProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const getCourseRoundsReferencingStudentGroup = (sg: StudentGroupAccessor) =>
        schedule.getCourseRounds().filter((cr) => cr.getStudentGroup().getStudentGroupId() === sg.getStudentGroupId())

    const handleDeleteStudentGroup = () => {
        const problematicCrs = props.studentGroups.flatMap(getCourseRoundsReferencingStudentGroup)
        if (problematicCrs.length > 0) {
            message.error(
                t('Errors.StudentGroupReferencedInDistribution', { name: problematicCrs[0].getDisplayName() })
            )
            return
        }
        props.onDelete()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.bulkTransform(
                    props.studentGroups.map((sg) =>
                        IScheduleTransform.studentGroupDeleteTransform({
                            studentGroupId: sg.getStudentGroupId()
                        })
                    )
                )
            )
        )
    }

    return (
        <Space direction="vertical">
            <Button variant="secondary" onClick={handleDeleteStudentGroup}>
                {t(props.studentGroups.length === 1 ? 'RemoveStudentGroup' : 'RemoveStudentGroups')}
            </Button>
        </Space>
    )
}
