import { useSelector } from 'react-redux'
import { ApplicationState } from '../../../store'
import { SseConnectionState } from '../../../store/devmode/types'
import React from 'react'
import { SseConnectionIndicator } from '../index'

export const AutoSchedulerSseConnectionIndicator = () => {
    const subscribersExist = useSelector<ApplicationState, boolean>((s) => s.autoSchedulerState.subscriptionCount > 0)
    const connectionState = useSelector<ApplicationState, SseConnectionState>(
        (s) => s.devInfoState.autoSchedulerSseConnectionState
    )

    return (
        <SseConnectionIndicator
            label="Auto Scheduler"
            subscribersExist={subscribersExist}
            connectionState={connectionState}
        />
    )
}
