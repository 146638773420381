import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '../../../../components/IconButton'
import { ApplicationState } from '../../../../store'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { userRemovedScheduleSelector } from '../../../../store/scheduleselector/actions'
import { ScheduleSelector } from '../../../../store/scheduleselector/types'
import TypeScale from '../../../../styles/TypeScale'
import { toTranslate } from '../../../../utils/miscUtil'
import { useScheduleStore } from '../../store'
import { getHeaderTitle } from '../Schedule/components/ColumnHeader'
import { Schedule } from '../Schedule/Schedule'
import { ScheduleSearchSelector } from '../ScheduleSearchSelector'
import classes from './style.module.css'

const MultipleSchedules = () => {
    const selectedSchedules = useSelector<ApplicationState, ScheduleSelector[]>(
        (state) => state.scheduleSelection.selectedSchedules
    )
    const dispatch = useDispatch()

    const { columns } = useScheduleStore()
    const scheduleToRender = useLocalSchedule()

    const onScheduleRemove = (scheduleSelector: ScheduleSelector) => {
        dispatch(userRemovedScheduleSelector(scheduleSelector))
    }

    return (
        <div
            className={classes.grid}
            style={
                {
                    '--columns': columns
                } as React.CSSProperties
            }
        >
            {selectedSchedules.map((schedule, index) => {
                const { title, tooltipTitle } = getHeaderTitle({
                    schedule,
                    scheduleToRender
                })

                return (
                    <div key={index}>
                        <div className={classes.header}>
                            <div>
                                <Tooltip title={tooltipTitle}>
                                    <h2 className={classNames(TypeScale.Heading_LG, classes['header--title'])}>
                                        {title}
                                    </h2>
                                </Tooltip>
                            </div>
                            {selectedSchedules.length > 1 ? (
                                <IconButton
                                    aria-label="close"
                                    onClick={() => onScheduleRemove(schedule)}
                                    pill
                                    variant="tertiary"
                                >
                                    <CloseOutlined />
                                </IconButton>
                            ) : null}
                        </div>
                        <Schedule scheduleSelector={schedule} renderMultipleMode="per-schedule" />
                    </div>
                )
            })}
            {selectedSchedules.length > 0 ? (
                <div className={classes.addScheduleCard}>
                    <div className={classes.addScheduleTitle}>
                        <PlusCircleOutlined
                            style={{
                                fontSize: 25
                            }}
                        />
                        <h1 className={TypeScale.Heading_XL}>{toTranslate('Lägg till schema')}</h1>
                    </div>
                    <ScheduleSearchSelector key={selectedSchedules.length} isUberSearch={false} />
                </div>
            ) : null}
        </div>
    )
}

export default MultipleSchedules
