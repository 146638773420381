import * as React from 'react'
import type { SVGProps } from 'react'

const SvgAa = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" className="teacher_svg__icon" {...props}>
        <path d="M360.79 116.45q30.276 85.675 59.29 173.64 30.276 86.818 58.029 182.77 29.014 95.956 56.768 203.34 29.014 107.38 58.029 231.89h-113.53q-10.092-51.405-21.445-101.67-10.092-50.263-21.445-100.53h-275.01q-12.615 50.263-22.707 100.53t-20.184 101.67H8.835Q36.588 783.58 65.602 676.2t56.768-203.34q29.014-95.956 58.029-182.77 30.276-87.96 60.552-173.64zm-61.813 116.52q-31.537 94.814-60.552 190.77-29.014 95.956-55.506 196.48h230.85q-25.23-100.53-54.244-196.48-29.014-95.956-60.552-190.77zM665.4 320.93q33.125-12.566 70.072-19.42 36.947-7.996 80.265-7.996 89.183 0 145.24 50.263 56.058 49.12 56.058 154.22v397.53q-45.866 11.423-94.279 18.277-47.14 6.854-93.005 6.854-45.866 0-86.635-10.281-39.495-10.281-70.072-33.128-29.303-22.847-47.14-57.117-17.837-35.413-17.837-85.675 0-46.836 15.289-81.106 16.563-35.412 44.592-58.26 28.029-23.988 66.25-35.411 38.221-12.566 82.813-12.566 42.043 0 93.005 9.138V501.42q0-66.256-29.303-92.529-28.029-26.274-81.539-26.274-29.303 0-56.058 5.712-25.481 4.57-54.784 15.993zm52.236 412.38q0 52.548 30.577 77.679t86.635 25.131q34.399 0 75.169-7.996v-190.77q-38.221-9.14-75.169-9.14-24.207 0-45.866 5.713-21.659 4.569-38.221 17.135-15.289 11.423-24.207 31.986-8.918 19.42-8.918 50.263z" />
    </svg>
)

export default SvgAa
