import { useTranslation } from 'react-i18next'
import { EntityListTooltip } from '../../MultiRoomAttributeSelector/RoomsListTooltip/EntityListTooltip'
import { ReservedTimesListTooltipProps } from './types'

export const ReservedTimesListTooltip = ({ title, reservedTimes }: ReservedTimesListTooltipProps) => {
    const { t } = useTranslation()

    return (
        <EntityListTooltip
            title={title}
            entities={reservedTimes}
            entityLabelFn={(rt) => rt.getTitle()}
            entityKeyFn={(rt) => rt.getReservedTimeId()}
            singularEntityLabel={t('ReservedTime')}
            pluralEntityLabel={t('ReservedTimes')}
        />
    )
}
