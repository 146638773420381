import { Input, Space, Table } from 'antd'
import type { TableRowSelection } from 'antd/es/table/interface'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Endpoints } from '../../services/Endpoints'

type PersistedSchedulesTableProps = {
    onSelectDstScheduleId: (persistedScheduleId: string | undefined, newScheduleTitle: string | undefined) => void
    selectedDstScheduleId?: string
    onNewScheduleTitle: (newTitle: string) => void
    newScheduleTitle: string
    exportInProgress: boolean
}

type ScheduleIdentifier = {
    id: string
    title?: string
}

export const PersistedSchedulesTable = ({
    onSelectDstScheduleId,
    selectedDstScheduleId,
    onNewScheduleTitle,
    newScheduleTitle,
    exportInProgress
}: PersistedSchedulesTableProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const [persistedSchedules, setPersistedSchedules] = useState<ScheduleIdentifier[]>([])
    const { t } = useTranslation()

    useEffect(() => {
        Endpoints.meitnerApi.scheduleServiceList({ pagination: { page: 1, size: 100 } }).then((response) => {
            setIsLoading(false)
            setPersistedSchedules(response.schedules.map((s) => ({ id: s.id!, title: s.title })))
        })
    }, [])

    const columns = [
        {
            title: 'Titel',
            dataIndex: 'title',
            key: 'title',
            render: (text: string, row: ScheduleIdentifier) =>
                row.id !== '' ? (
                    row.title
                ) : (
                    <Space>
                        <div>{t('NewSchedule')}:</div>
                        <Input
                            placeholder={t('Title')}
                            value={newScheduleTitle}
                            onChange={(e) => onNewScheduleTitle(e.target.value)}
                            disabled={selectedDstScheduleId !== '' || exportInProgress}
                        />
                    </Space>
                )
        }
    ]

    const onSelectWrapper = (selected: ScheduleIdentifier) => {
        const selectedId = selected.id
        if (selectedId === '') {
            onSelectDstScheduleId('', newScheduleTitle)
        } else {
            onSelectDstScheduleId(selectedId, undefined)
        }
    }

    const rowSelection: TableRowSelection<ScheduleIdentifier> = {
        selectedRowKeys: selectedDstScheduleId === undefined ? [] : [selectedDstScheduleId],
        onChange: (_, selectedRow) => onSelectWrapper(selectedRow[0])
    }

    return (
        <Table<ScheduleIdentifier>
            dataSource={[...persistedSchedules, { id: '' }]}
            columns={columns}
            bordered
            loading={isLoading}
            showHeader={false}
            style={{ width: '100%', maxWidth: '40em' }}
            rowSelection={{
                type: 'radio',
                ...rowSelection
            }}
            size="large"
            rowKey={(row) => row.id}
            sticky
            onRow={(selectedRow) => ({
                onClick: () => onSelectWrapper(selectedRow)
            })}
            pagination={false}
        />
    )
}
