import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { StudentGroupId } from '../../commonTypes'
import TablePage from '../../components/PageContent'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { StudentGroupActionsPanel } from './StudentGroupActionsPanel'
import StudentGroupDetails from './components/StudentGroupDetails'
import StudentGroupsTable from './components/StudentGroupTable'

const StudentGroupsIndexPage = () => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const [selectedRowKeys, setSelectedRowKeys] = useState<StudentGroupId[]>([])

    // Some selected student groups may no longer exists (for example if they were deleted in another browser tab).
    const validSelectedRowKeys = selectedRowKeys.filter((sgId) => schedule.doesStudentGroupIdExist(sgId))

    const selectedStudentGroups = validSelectedRowKeys.map((studentGroupId) =>
        schedule.findStudentGroup(studentGroupId)
    )

    const detailsPanel = (
        <StudentGroupDetails
            key={schedule.getVersion() + validSelectedRowKeys.join(',')}
            studentGroups={selectedStudentGroups}
        />
    )
    const actionsPanel = (
        <StudentGroupActionsPanel
            key={schedule.getVersion() + validSelectedRowKeys.join(',')}
            studentGroups={selectedStudentGroups}
            onDelete={() => setSelectedRowKeys([])}
        />
    )

    return (
        <TablePage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => setSelectedRowKeys([])}
            sidePanels={[
                { key: 'edit', header: t('Edit'), panel: detailsPanel },
                {
                    key: 'actions',
                    header: t('Actions'),
                    panel: actionsPanel
                }
            ]}
            table={
                <StudentGroupsTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />
            }
        />
    )
}

export default StudentGroupsIndexPage
