import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { ScheduleAccessor } from '../../../../../../schedule-access/scheduleAccessWrappers'
import { ApplicationState } from '../../../../../../store'
import { useLocalSchedule } from '../../../../../../store/schedule/hooks'
import {
    isCourseRoundScheduleSelector,
    isRoomScheduleSelector,
    isStudentGroupScheduleSelector,
    isTeacherScheduleSelector,
    ScheduleSelector
} from '../../../../../../store/scheduleselector/types'
import classes from './style.module.css'

type GetHeaderTitleOptions = {
    schedule: ScheduleSelector
    scheduleToRender: ScheduleAccessor
}

export const getHeaderTitle = ({ schedule, scheduleToRender }: GetHeaderTitleOptions) => {
    let title = ''
    let id = ''
    let tooltipTitle = undefined
    const teacherOptions = scheduleToRender.getTeachers()
    const roomOptions = scheduleToRender.getRooms()
    const studentGroupOptions = scheduleToRender.getStudentGroups()
    const courseRoundOptions = scheduleToRender.getCourseRounds()

    const teacherId = isTeacherScheduleSelector(schedule) ? schedule?.teacherId : undefined
    const roomId = isRoomScheduleSelector(schedule) ? schedule?.roomId : undefined
    const studentGroupId = isStudentGroupScheduleSelector(schedule) ? schedule?.studentGroupId : undefined
    const courseRoundId = isCourseRoundScheduleSelector(schedule) ? schedule?.courseRoundId : undefined

    const teacher = teacherOptions.find((t) => t.getTeacherId() === teacherId)

    if (teacher !== undefined) {
        title = teacher.getTeacherSchoolId()
        id = teacher.getTeacherId()
        tooltipTitle = `${teacher.getFirstName()} ${teacher.getLastName()}`
    }

    const room = roomOptions.find((r) => r.getRoomId() === roomId)

    if (room !== undefined) {
        title = room.getName()
        id = room.getRoomId()
    }

    const studentGroup = studentGroupOptions.find((sg) => sg.getStudentGroupId() === studentGroupId)

    if (studentGroup !== undefined) {
        title = studentGroup.getDisplayName()
        id = studentGroup.getStudentGroupId()
    }

    const courseRound = courseRoundOptions.find((cr) => cr.getCourseRoundId() === courseRoundId)

    if (courseRound !== undefined) {
        title = courseRound.getDisplayName()
        id = courseRound.getCourseRoundId()
    }

    return { title, id, tooltipTitle }
}

const ColumnHeader = () => {
    const scheduleToRender = useLocalSchedule()
    const selectedSchedules = useSelector<ApplicationState, ScheduleSelector[]>(
        (state) => state.scheduleSelection.selectedSchedules
    )

    return (
        <div className={classes.scheduleHeader}>
            <div className={classes.scheduleHeader__container}>
                {selectedSchedules.map((schedule) => {
                    const { title, id, tooltipTitle } = getHeaderTitle({
                        schedule,
                        scheduleToRender
                    })

                    return (
                        <Tooltip title={tooltipTitle ?? title} key={id}>
                            <div className={classes.scheduleHeaderColumn}>
                                <div className={classes.scheduleHeaderTitle}>
                                    <span className={classes.scheduleHeaderTitleText}>{title}</span>
                                </div>
                            </div>
                        </Tooltip>
                    )
                })}
            </div>
        </div>
    )
}

export default ColumnHeader
