export function isSubsequence(subseq: string, str: string) {
    let subseqIndex = 0
    let stringIndex = 0
    while (subseqIndex < subseq.length && stringIndex < str.length) {
        if (subseq[subseqIndex] === str[stringIndex]) {
            subseqIndex++
        }

        stringIndex++
    }

    return subseqIndex === subseq.length
}

export function isSubsequenceIgnoreCase(subseq: string, str: string) {
    return isSubsequence(subseq.toLocaleLowerCase(), str.toLocaleLowerCase())
}

export const isSubsequenceCase = (subseq: string, str: string, caseSensitive: boolean) =>
    isSubsequence(caseSensitive ? subseq : subseq.toLocaleLowerCase(), caseSensitive ? str : str.toLocaleLowerCase())

export const isSubstringCase = (substring: string, str: string, caseSensitive: boolean) =>
    (caseSensitive ? str : str.toLocaleLowerCase()).includes(caseSensitive ? substring : substring.toLocaleLowerCase())
