import { orThrow } from '../../../utils/collections'
import { AppError } from '../../../utils/errorutil'

export class SrcToDstIdMap {
    public idMap: Map<string, string> = new Map<string, string>()
    addMapping(srcId: string, dstId: string) {
        if (this.idMap.has(srcId)) {
            throw new AppError('Duplicate source id mapping', { srcId, dstId })
        }

        this.idMap.set(srcId, dstId)
    }
    getDstId(srcId: string): string {
        return orThrow(this.idMap.get(srcId))
    }
}
