import { Button, Space } from 'antd'
import { useDispatch } from 'react-redux'
import { handleLocallyTriggeredAutoSchedulerStateUpdate } from '../../store/autoscheduler/actions'
import { useAutoSchedulerRunningState } from '../../store/autoscheduler/hooks'
import { AutoSchedulerRunningState } from '../../store/autoscheduler/types'
import { useTranslation } from 'react-i18next'
import Icon from '@ant-design/icons'
import { SvgSquareFilled } from '../../icons/SvgSquareFilled'
import { SvgPlayFilled } from '../../icons/SvgPlayFilled'

export const AutoSchedulerControls = () => {
    const dispatch = useDispatch()
    const autoSchedulerState = useAutoSchedulerRunningState()
    const { t } = useTranslation()

    return (
        <Space>
            <Button
                disabled={autoSchedulerState !== AutoSchedulerRunningState.PAUSED}
                onClick={() => dispatch(handleLocallyTriggeredAutoSchedulerStateUpdate(true, false))}
                icon={<Icon component={SvgPlayFilled} />}
            >
                {t('start')}
            </Button>
            <Button
                disabled={autoSchedulerState !== AutoSchedulerRunningState.RUNNING}
                onClick={() => dispatch(handleLocallyTriggeredAutoSchedulerStateUpdate(false, false))}
                icon={<Icon component={SvgSquareFilled} />}
            >
                {t('stop')}
            </Button>
        </Space>
    )
}
