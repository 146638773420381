import classNames from 'classnames'
import { HTMLAttributes, useEffect, useRef, useState } from 'react'
import classes from './style.module.css'
import { opacityAtScrollPosition } from './util'

type ScrollGradientMaskWrapperProps = HTMLAttributes<HTMLDivElement>

export const ScrollGradientMaskWrapper = ({ className, children, onPaste }: ScrollGradientMaskWrapperProps) => {
    const [topOpacity, setTopOpacity] = useState(0)
    const [bottomOpacity, setBottomOpacity] = useState(1)

    const scrollableDivRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableDivRef.current) {
                // Compute opacity of top gradient
                const scrollTop = scrollableDivRef.current.scrollTop
                setTopOpacity(opacityAtScrollPosition(scrollTop, 0))

                const scrollHeight = scrollableDivRef.current.scrollHeight - scrollableDivRef.current.clientHeight
                setBottomOpacity(opacityAtScrollPosition(scrollTop, scrollHeight))
            }
        }

        // Initialize values based on initial scroll position and component height
        handleScroll()

        const div = scrollableDivRef.current
        div?.addEventListener('scroll', handleScroll)

        return () => {
            div?.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <div className={classNames(classes.wrapper, className)} ref={scrollableDivRef} onPaste={onPaste}>
            <div className={classNames(classes.gradientOverlayWrapper, classes.topGradientOverlayWrapper)}>
                <div className={classes.topGradientOverlay} style={{ opacity: topOpacity }} />
            </div>
            {children}
            <div className={classNames(classes.gradientOverlayWrapper, classes.bottomGradientOverlayWrapper)}>
                <div className={classes.bottomGradientOverlay} style={{ opacity: bottomOpacity }} />
            </div>
        </div>
    )
}
