import { Flex, Slider, Space } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useDispatch } from 'react-redux'
import {
    CourseRoundAccessor,
    EventGroupAccessor,
    ReservedTimeAccessor
} from '../../schedule-access/scheduleAccessWrappers'
import { Endpoints } from '../../services/Endpoints'
import { useActiveOrgId } from '../../store/auth/hooks'
import { clearLectureSelection } from '../../store/lectureselector/actions'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useIsDummySchedule, useLocalSchedule } from '../../store/schedule/hooks'
import TypeScale from '../../styles/TypeScale'
import Button from '../Button'
import classes from './style.module.css'
import { sample, uniq } from 'lodash'
import { useState } from 'react'
import { getPalette, MAX_LUM, MAX_SAT, MIN_LUM, MIN_SAT } from '../../utils/colors'

export const DevUtilPanel = () => {
    const activeOrgId = useActiveOrgId()
    const isDummySchedule = useIsDummySchedule()
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()

    const [lumRange, setLumRange] = useState([MIN_LUM, MAX_LUM])
    const [satRange, setSatRange] = useState([MIN_SAT, MAX_SAT])

    const resetDayAndTime = (eventGroup: EventGroupAccessor) =>
        IScheduleTransform.eventGroupTransform({
            newEventGroup: {
                ...eventGroup.getConjureObject(),
                dayAndTime: null
            }
        })

    const deleteEventGroup = (eventGroup: EventGroupAccessor) =>
        IScheduleTransform.eventGroupDeleteTransform({
            eventGroupId: eventGroup.getEventGroupId()
        })

    const clearTeachingAssignmentTransform = (cr: CourseRoundAccessor) =>
        IScheduleTransform.courseRoundTransform({
            newCourseRound: {
                ...cr.getConjureObject(),
                teacherIds: []
            }
        })

    const deleteReservedTime = (rt: ReservedTimeAccessor) =>
        IScheduleTransform.reservedTimeDeleteTransform({
            reservedTimeId: rt.getReservedTimeId()
        })

    const onResetDayAndTime = () => {
        const eventGroupResets = schedule.getEventGroups().map(resetDayAndTime)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(eventGroupResets)))
    }

    const unpinEventGroup = (eventGroup: EventGroupAccessor) =>
        IScheduleTransform.eventGroupTransform({
            newEventGroup: {
                ...eventGroup.getConjureObject(),
                timeslotPinned: false
            }
        })

    const onResetDayAndTimeNonPinned = () => {
        const lectureResets = schedule
            .getEventGroups()
            .filter((l) => !l.isTimeslotPinned())
            .map(resetDayAndTime)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(lectureResets)))
    }

    const unpinTimeForAllLectures = () => {
        const bulkUnpinTransform = schedule.getEventGroups().map(unpinEventGroup)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(bulkUnpinTransform)))
    }

    const deleteUnscheduledLectures = () => {
        const deleteTransforms = schedule
            .getEventGroups()
            .filter((eg) => !eg.isScheduled())
            .map(deleteEventGroup)
        dispatch(clearLectureSelection())
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(deleteTransforms)))
    }

    const clearTeachingAssignments = () => {
        const clearTeachingAssignmentsTransforms = schedule
            .getCourseRounds()
            .filter((cr) => cr.getTeachers().length > 0)
            .map(clearTeachingAssignmentTransform)
        dispatch(
            locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(clearTeachingAssignmentsTransforms))
        )
    }

    const loadDemoData = () => {
        Endpoints.service.loadDemoState(activeOrgId)
    }

    const getRandomColor = () => {
        // For randomizing from palette
        const paletteColors = getPalette().map(pc => pc.paletteColor)
        const rndCol = sample(paletteColors)!

        // For exploring new lum/sat limits
        // const lumSatF = Math.random()
        // const s = mapLinearly(lumSatF, 0.5, 1, satRange[1], satRange[0])
        // const l = mapLinearly(lumSatF, 0, 0.5, lumRange[1], lumRange[0])
        // const s = mapLinearly(lumSatF, 0, 1, satRange[1], satRange[0])
        // const l = mapLinearly(lumSatF, 0, 1, lumRange[1], lumRange[0])
        // const h = Math.random() * 360
        // const rndCol = colorToHex(new Color('HSL', [h, s, l]))
        
        return rndCol
    }

    const randomizeSubjectColors = () => {
        const setRandomColors = schedule.getSubjects().map((s) =>
            IScheduleTransform.subjectTransform({
                newSubject: {
                    ...s.getConjureObject(),
                    color: getRandomColor()
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(setRandomColors)))
    }

    const deleteAllReservedTimes = () => {
        const deleteTransforms = schedule.getReservedTimes().map(deleteReservedTime)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(deleteTransforms)))
    }

    const completeTeacherQualifications = () => {
        const teacherTransforms = schedule.getTeachers().map(t => {
            const subjectsForTeacher = schedule.getCourseRounds()
                .filter(cr => cr.getTeachers().map(t => t.getTeacherId()).includes(t.getTeacherId()))
                .map(cr => cr.getSubject().getSubjectId())

            return IScheduleTransform.teacherTransform({
                newTeacher: {
                    ...t.getConjureObject(),
                    qualifications: uniq([...t.getConjureObject().qualifications, ...subjectsForTeacher])
                }})
        })
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(teacherTransforms)))
    }
    
    const dumpScheduleJson = () => {
        console.log('Schedule: ', schedule.getConjureSchedule())
    }

    const infoTableRow = (key: string, value: string | number) => (
        <div key={key} className={classes.devInfoRow}>
            <p className={TypeScale.Label_MD_Semibold}>{key}</p>
            <p style={{ fontFamily: 'monospace' }}>{value}</p>
        </div>
    )

    return (
        <Space direction="vertical">
            <Flex style={{ width: '100%' }} align="center">
                <div style={{ flex: 0 }}>lum:</div>
                <div style={{ flex: 1 }}>
                    <Slider range value={lumRange} onChange={setLumRange} />
                </div>
            </Flex>
            <Flex style={{ width: '100%' }} align="center">
                <div style={{ flex: 0 }}>sat:</div>
                <div style={{ flex: 1 }}>
                    <Slider range value={satRange} onChange={setSatRange} />
                </div>
            </Flex>
            <Button variant="primary" size="sm" destructive onClick={randomizeSubjectColors}>
                Slumpa ämnesfärger
            </Button>
            <Button variant="primary" disabled={isDummySchedule} destructive size="sm" onClick={onResetDayAndTime}>
                Ta bort dag/tid från alla lektioner
            </Button>
            <Button
                variant="primary"
                disabled={isDummySchedule}
                destructive
                size="sm"
                onClick={onResetDayAndTimeNonPinned}
            >
                Ta bort dag/tid från alla icke-pinnade lektioner
            </Button>
            <Button
                variant="primary"
                disabled={isDummySchedule}
                destructive
                size="sm"
                onClick={unpinTimeForAllLectures}
            >
                Ta bort alla pins för dag / tid
            </Button>
            <Button
                variant="primary"
                disabled={isDummySchedule}
                destructive
                size="sm"
                onClick={deleteUnscheduledLectures}
            >
                Ta bort icke schemalagda lektioner
            </Button>
            <Button variant="primary" disabled={isDummySchedule} destructive size="sm" onClick={dumpScheduleJson}>
                Skriv ut aktuellt schema i konsolen.
            </Button>
            <Button
                variant="primary"
                disabled={isDummySchedule}
                destructive
                size="sm"
                onClick={clearTeachingAssignments}
            >
                Nollställ tjänstefördelningen
            </Button>
            <Button variant="primary" disabled={isDummySchedule} destructive size="sm" onClick={deleteAllReservedTimes}>
                Ta bort alla reserverade tider
            </Button>
            <Button variant="primary" disabled={isDummySchedule} destructive size="sm" onClick={completeTeacherQualifications}>
                Sätt nödvändiga behörigheter
            </Button>
            <Button variant="primary" disabled={isDummySchedule} destructive size="sm" onClick={loadDemoData}>
                Ladda demodata
            </Button>

            <div className={classes.devInfo}>
                {infoTableRow('Active org id', activeOrgId)}
                {infoTableRow('Current schedule version', isDummySchedule ? 'n/a' : schedule.getVersion())}
                {[
                    'REACT_APP_API_URL',
                    'REACT_APP_VERSION',
                    'REACT_APP_MEITNER_SSO_URL',
                    'REACT_APP_MEITNER_API_VERSION',
                    'REACT_APP_MEITNER_ADMIN_URL'
                ].map((varName) => infoTableRow(varName, process.env[varName] || 'n/a'))}
            </div>
        </Space>
    )
}
