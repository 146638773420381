import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { optionCmp } from '../../../../utils/compareUtil'
import { RoomSelectorProps } from './types'

export const RoomSelector = ({ value, onChange }: RoomSelectorProps) => {
    const { t } = useTranslation()
    const schedule = useLocalSchedule()
    const roomOptions = schedule
        .getRooms()
        .map((r) => ({ label: r.getName(), value: r.getRoomId() }))
        .sort(optionCmp)

    return (
        <Select
            options={roomOptions}
            value={value}
            allowClear
            showSearch
            optionFilterProp="label"
            onChange={onChange}
            placeholder={t('NoRoomSelected')}
        />
    )
}
