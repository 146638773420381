import { MultiTeacherQualificationSelectorProps } from './types'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { ManyToManySelector } from '../../many-to-many-selector/ManyToManySelector'
import { TeacherId } from '../../../commonTypes'
import { TeacherListTooltip } from './TeacherListTooltip'
import { toTranslate } from '../../../utils/miscUtil'

export const MultiTeacherQualificationSelector = ({ value, onChange }: MultiTeacherQualificationSelectorProps) => {
    const schedule = useLocalSchedule()

    return (
        <ManyToManySelector<TeacherId, string>
            value={value}
            onChange={onChange}
            mode="multiple"
            preexistingOptionValues={schedule.getSubjects().map((s) => s.getSubjectId())}
            optionLabelFn={(subjectId) => schedule.findSubject(subjectId).getName()}
            partialCoverSuffix={(teacherIds) => (
                <TeacherListTooltip
                    title={toTranslate('Detta ämne gäller endast följande lärare')}
                    teachers={teacherIds.map((teacherId) => schedule.findTeacher(teacherId))}
                />
            )}
        />
    )
}
