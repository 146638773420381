import { keyBy, partition } from 'lodash'
import type { ReactNode } from 'react'

export const emptyToDefault = (str: string, deflt: string) => (str === '' ? deflt : str)

// Note: All non-null keys must be unique.
export function fullJoin<L, R>(
    left: L[],
    leftKeyFn: (l: L) => string | null,
    right: R[],
    rightKeyFn: (r: R) => string | null
): [L | undefined, R | undefined][] {
    const [lWithNonNullKeys, lWithNullKeys] = partition(left, (l) => leftKeyFn(l) !== null)
    const [rWithNonNullKeys, rWithNullKeys] = partition(right, (r) => rightKeyFn(r) !== null)

    const lByKey = keyBy(lWithNonNullKeys, (l) => leftKeyFn(l))
    const rByKey = keyBy(rWithNonNullKeys, (r) => rightKeyFn(r))

    return [
        ...lWithNullKeys.map((l) => [l, undefined] as [L, undefined]),
        ...lWithNonNullKeys.map((l) => [l, rByKey[leftKeyFn(l)!]] as [L, undefined]),
        // eslint-disable-next-line @typescript-eslint/no-confusing-non-null-assertion
        ...rWithNonNullKeys.filter((r) => !(rightKeyFn(r)! in lByKey)).map((r) => [undefined, r] as [undefined, R]),
        ...rWithNullKeys.map((r) => [undefined, r] as [undefined, R])
    ]
}

export class ExportError extends Error {
    constructor(readonly content: ReactNode) {
        super('Error during schedule export')
    }
}
