import { Select } from 'antd'
import type { DefaultOptionType } from 'antd/es/select'
import { useTranslation } from 'react-i18next'
import { allEqual } from '../../pages/schedule/components/ScheduleSearchSelector/utils'

type BulkSingleSelectProps<T> = {
    value?: T[]
    onChange?: (newValue: T[]) => void
    options: DefaultOptionType[]
    originalValue: T[]
}

export const BulkSingleSelect = <T,>(props: BulkSingleSelectProps<T>) => {
    const { t } = useTranslation()

    const onChangeWrapper = (newValue: T | 'unchanged') => {
        if (typeof props.onChange === 'function') {
            if (newValue === 'unchanged') {
                props.onChange(props.originalValue)
            } else {
                props.onChange(new Array(props.originalValue.length).fill(newValue))
            }
        }
    }

    let value: T | 'unchanged' | undefined
    let options = props.options
    if (props.value === undefined || props.value.length === 0) {
        value = undefined
    } else if (props.value.every((v) => v === props.value![0])) {
        value = props.value[0]
    } else {
        value = 'unchanged'
    }

    // Should we add 'unchanged' option? - Yes, if original values differ
    if (!allEqual(props.originalValue)) {
        options = [
            {
                value: 'unchanged',
                label: <span style={{ fontStyle: 'italic' }}>{t('LeaveUnchanged')}</span>
            },
            ...options
        ]
    }

    return (
        <Select<T | 'unchanged'>
            showSearch
            optionFilterProp="label"
            options={options}
            value={value}
            onChange={onChangeWrapper}
        />
    )
}
