import { CopyOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { duplicateLecture } from '../utils'
import type { DuplicateLectureButtonProps } from './types'

export const DuplicateLectureButton = ({ lectureId, disabled = false }: DuplicateLectureButtonProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    const lecture = schedule.findLecture(lectureId)

    const handleDuplicate = () => {
        const splitTransforms = duplicateLecture(lecture)
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(splitTransforms)))
    }

    return (
        <Button disabled={disabled} icon={<CopyOutlined />} onClick={handleDuplicate}>
            {t('DuplicateLecture')}
        </Button>
    )
}
