import useSize from '@react-hook/size'
import { RefObject, useState } from 'react'
import { ALL_CONTENT_COLUMNS } from '../pages/teachers/components/TeachersTable/TeacherEditTable/TeacherGrid/types'
import { IndexedLocation } from '@silevis/reactgrid/dist/types/InternalModel'

export const useColumnWidth = (target: RefObject<HTMLDivElement> | null) => {
    const [width] = useSize(target)
    const ROW_INDEX_COLUMN_WIDTH = 40
    const CONTENT_COLUMN_WIDTH = (width - ROW_INDEX_COLUMN_WIDTH) / ALL_CONTENT_COLUMNS.length - 1

    return CONTENT_COLUMN_WIDTH
}

export const useFocusedCell = () => {
    // For the "manual" paste implementation. Hopefully this wil be part of ReactGrid at some point.
    const [focusedCell, setFocusedCell] = useState<IndexedLocation>({ rowIndex: 1, colIndex: 1 })

    return [focusedCell, setFocusedCell] as const
}
