import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import styled from '../../utils/styled'

const SettingsIndexPage = () => {
    const { t, i18n } = useTranslation()

    const lngs: Record<string, { nativeName: string }> = {
        sv: { nativeName: 'Svenska' },
        en: { nativeName: 'English' },
        de: { nativeName: 'Deutsch' }
    }

    return (
        <PageContent>
            <div style={{ padding: '2em', display: 'flex', flexDirection: 'column' }}>
                <h1>{t('MainMenu.Settings')}</h1>
                <p>{t('ChooseLanguage')}</p>
                <div style={{ display: 'flex', columnGap: '10px' }}>
                    {Object.keys(lngs).map((lng) => (
                        <Button
                            variant="primary"
                            key={lng}
                            onClick={() => {
                                i18n.changeLanguage(lng).catch((error) => {
                                    console.error('Language change failed:', error)
                                })
                            }}
                            disabled={i18n.resolvedLanguage === lng}
                        >
                            {lngs[lng].nativeName}
                        </Button>
                    ))}
                </div>
            </div>
        </PageContent>
    )
}

export default SettingsIndexPage

const PageContent = styled('article')`
    display: flex;
`
