import type { CourseRoundId, RoomId, ScheduleId, StudentGroupId, TeacherId } from '../../commonTypes'
import { AppError } from '../../utils/errorutil'

export enum ScheduleSelectionActionTypes {
    USER_ADDED_SCHEDULE_SELECTOR = 'scheduleSelection/USER_ADDED_SCHEDULE_SELECTOR',
    USER_REMOVED_SCHEDULE_SELECTOR = 'scheduleSelection/USER_REMOVED_SCHEDULE_SELECTOR',
    NEW_SCHEDULE_SELECTORS_LOADED_DUE_TO_SCHEDULE_UPDATE = 'scheduleSelection/NEW_SCHEDULE_SELECTORS_LOADED_DUE_TO_SCHEDULE_UPDATE'
}

export type AllScheduleSelector = {
    markerField: boolean
}

export type TeacherScheduleSelector = {
    teacherId: TeacherId
}

export type StudentGroupScheduleSelector = {
    studentGroupId: StudentGroupId
}

export type CourseRoundScheduleSelector = {
    courseRoundId: CourseRoundId
}

export type RoomScheduleSelector = {
    roomId: RoomId
}

export type ScheduleSelector =
    | TeacherScheduleSelector
    | StudentGroupScheduleSelector
    | CourseRoundScheduleSelector
    | RoomScheduleSelector
    | AllScheduleSelector

export function isTeacherScheduleSelector(selector: ScheduleSelector): selector is TeacherScheduleSelector {
    return (selector as TeacherScheduleSelector).teacherId !== undefined
}

export function isRoomScheduleSelector(selector: ScheduleSelector): selector is RoomScheduleSelector {
    return (selector as RoomScheduleSelector).roomId !== undefined
}

export function isAllScheduleSelector(selector: ScheduleSelector): selector is AllScheduleSelector {
    return (selector as AllScheduleSelector).markerField !== undefined
}

export function isStudentGroupScheduleSelector(selector: ScheduleSelector): selector is StudentGroupScheduleSelector {
    return (selector as StudentGroupScheduleSelector).studentGroupId !== undefined
}

export function isCourseRoundScheduleSelector(selector: ScheduleSelector): selector is CourseRoundScheduleSelector {
    return (selector as CourseRoundScheduleSelector).courseRoundId !== undefined
}

export function visitScheduleSelector<T>(
    selector: ScheduleSelector,
    visitor: {
        teacherSelector: (s: TeacherScheduleSelector) => T
        studentGroupSelector: (s: StudentGroupScheduleSelector) => T
        courseRoundSelector: (s: CourseRoundScheduleSelector) => T
        roomSelector: (s: RoomScheduleSelector) => T
        allSelector: (s: AllScheduleSelector) => T
    }
): T {
    if (isTeacherScheduleSelector(selector)) {
        return visitor.teacherSelector(selector)
    } else if (isStudentGroupScheduleSelector(selector)) {
        return visitor.studentGroupSelector(selector)
    } else if (isAllScheduleSelector(selector)) {
        return visitor.allSelector(selector)
    } else if (isCourseRoundScheduleSelector(selector)) {
        return visitor.courseRoundSelector(selector)
    } else if (isRoomScheduleSelector(selector)) {
        return visitor.roomSelector(selector)
    }

    throw new AppError('Unknown schedule selector type', { selector })
}

export interface ScheduleSelectionState {
    // This field specifies which schedule the selectors refer to. It was introduced to allow us to detect change of
    // schedule, for example during initial load when the dummy schedule is replaced with the actual schedule.
    readonly scheduleId: ScheduleId
    readonly selectedSchedules: ScheduleSelector[]
}
