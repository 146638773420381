import { LoadingOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import styled from '../../utils/styled'
import { PersistedSchedulesTable } from './PersistedSchedulesTable'
import { TripleDotsMessage } from './TripleDotsMessage'
import { useExportSchedule } from './hooks/useExportSchedule'

const ImportExportIndexPage = () => {
    const { t } = useTranslation()
    const {
        selectedDstScheduleId,
        setSelectedDstScheduleId,
        newScheduleTitle,
        setNewScheduleTitle,
        exportInProgress,
        exportMessage,
        exportScheduleWithProgressWrapper
    } = useExportSchedule()

    return (
        <PageContent style={{ height: '100vh', padding: '20px' }}>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <h1>{t('TransferToAdmin')}</h1>
                <p>{t('SelectScheduleToTransferTo')}</p>
                <PersistedSchedulesTable
                    onSelectDstScheduleId={setSelectedDstScheduleId}
                    onNewScheduleTitle={setNewScheduleTitle}
                    newScheduleTitle={newScheduleTitle}
                    selectedDstScheduleId={selectedDstScheduleId}
                    exportInProgress={exportInProgress}
                />
                <Space>
                    <Button
                        startIcon={exportInProgress ? <LoadingOutlined /> : undefined}
                        disabled={selectedDstScheduleId === undefined || exportInProgress}
                        variant="primary"
                        onClick={exportScheduleWithProgressWrapper}
                    >
                        {t('TransferToSelectedSchedule')}
                    </Button>
                    {exportMessage && <TripleDotsMessage>{exportMessage}</TripleDotsMessage>}
                </Space>
            </Space>
        </PageContent>
    )
}

const PageContent = styled('article')`
    display: flex;
    column-gap: 20px;
`

export default ImportExportIndexPage
