import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const CourseFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const allCourseFilters = schedule.getCourses().map((c) => ({
        value: c.getCourseId(),
        text: c.getCode()
    }))

    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allCourseFilters}
            noKeysAvailableDescription={t('NoCourses')}
        />
    )
}
