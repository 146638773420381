import { SimpleProblemListProps } from './types'

export const SimpleProblemList = ({ problems }: SimpleProblemListProps) => (
    <ul>
        {problems.map((p, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{p.messageText}</li>
        ))}
    </ul>
)
