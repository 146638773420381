import { useCallback, useState } from 'react'

export const useSet = <T>(): [Set<T>, (e: T) => void, (e: T) => void] => {
    const [internalSet, setInternalSet] = useState<Set<T>>(new Set())
    const addToSet = useCallback(
        (e: T) =>
            setInternalSet((prev) => {
                if (prev.has(e)) {
                    return prev
                }
                return new Set(prev).add(e)
            }),
        [setInternalSet]
    )
    const removeFromSet = useCallback(
        (e: T) =>
            setInternalSet((prev) => {
                if (!prev.has(e)) {
                    return prev
                }
                const newSet = new Set(prev)
                newSet.delete(e)
                return newSet
            }),
        [setInternalSet]
    )
    return [internalSet, addToSet, removeFromSet]
}
