import type * as api from 'common-api'
import type { IDayAndTime, ITimeOfDay } from 'common-api'
import { DayOfWeek } from 'common-api'
import { groupBy } from 'lodash'
import type { XYCoord } from 'react-dnd'
import { findOrThrow } from '../../../utils/collections'
import { combinedCmpFn, comparing } from '../../../utils/compareUtil'
import type { ScheduleEvent, UnAllocatedEvent } from './ScheduleEvent'
import type { EventAndLayoutSpec } from './types'

export interface AllocatedLayoutSpec {
    type: 'allocated'
    dayAndTime: IDayAndTime
    column: number
    columns: number
    scheduleSelectorIndex: number
    numSelectedSchedules: number
}

export interface UnallocatedLayoutSpec {
    type: 'unallocated'
    row: number
    column: number
    columns: number
    scheduleSelectorIndex?: number
    numSelectedSchedules?: number
}

export type LayoutSpec = AllocatedLayoutSpec | UnallocatedLayoutSpec

export const DAYS: DayOfWeek[] = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY
]

export const VISIBLE_DAYS: DayOfWeek[] = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY
]

export const MINUTES_PER_SLOT = 5

export const ALLOCATED_PERCENT = 80 // rest is for the unallocated

export const MIN_UNALLOCATED_COLUMNS = 5

function hashCode(s: string) {
    let h = 0

    for (let i = 0; i < s.length; i++) {
        h = (Math.imul(31, h) + s.charCodeAt(i)) | 0
    }

    return h
}

export function colorByType(subjectName: string): string {
    const lower = subjectName.toLowerCase()
    const hash = hashCode(lower) % 360

    return `hsl(${hash}, 33%, 76%)`
}

export function toMinuteOfDay(time: { hour: number; minute: number }) {
    return 60.0 * time.hour + time.minute
}

export function minuteToDayAndTime(day: api.DayOfWeek, minutesOfDay: number): api.IDayAndTime {
    const hour = Math.floor(minutesOfDay / 60)
    const minute = (minutesOfDay - hour * 60) % 60

    return { day, hour, minute }
}

export function positioningDayLeft(day: api.DayOfWeek, selectedWeekDays: DayOfWeek[]) {
    return (100.0 * selectedWeekDays.indexOf(day)) / selectedWeekDays.length
}

export function positioningLeft(layoutSpec: LayoutSpec, selectedWeekDays: DayOfWeek[]) {
    if (layoutSpec.type === 'allocated') {
        const dayIndex = selectedWeekDays.indexOf(layoutSpec.dayAndTime.day)
        const dayWidthPercent = 100 / selectedWeekDays.length

        const lessonWidth = dayWidthPercent / (layoutSpec.numSelectedSchedules || 1)

        return (
            (100.0 * dayIndex) / selectedWeekDays.length +
            (lessonWidth * layoutSpec.column) / layoutSpec.columns +
            lessonWidth * (layoutSpec.scheduleSelectorIndex || 0)
        )
    }

    if (layoutSpec.type === 'unallocated') {
        return (100.0 * layoutSpec.column) / Math.max(layoutSpec.columns, MIN_UNALLOCATED_COLUMNS)
    }

    return -1
}

export function positioningWidth(layoutSpec: LayoutSpec, selectedWeekDays: number) {
    if (layoutSpec.type === 'allocated') {
        const dayWidthPercent = 100 / selectedWeekDays

        return dayWidthPercent / layoutSpec.columns / (layoutSpec.numSelectedSchedules || 1)
    }

    if (layoutSpec.type === 'unallocated') {
        return 100.0 / Math.max(layoutSpec.columns, MIN_UNALLOCATED_COLUMNS)
    }

    return -1
}

export function positioningTopRaw(startOfDay: ITimeOfDay, endOfDay: ITimeOfDay, hour: number, minutes: number) {
    const startMinuteOfDay = startOfDay.hour * 60 + startOfDay.minute
    const givenMinuteOfDay = hour * 60 + minutes
    const endMinuteOfDay = endOfDay.hour * 60 + endOfDay.minute
    const minutesInDay = endMinuteOfDay - startMinuteOfDay

    return (ALLOCATED_PERCENT * (givenMinuteOfDay - startMinuteOfDay)) / minutesInDay
}

export function positioningZIndex(layoutSpec: LayoutSpec): string | number {
    if (layoutSpec.type === 'allocated') {
        return 'auto'
    }

    if (layoutSpec.type === 'unallocated') {
        return layoutSpec.row
    }

    return -1
}

export function positioningTop(startOfDay: ITimeOfDay, endOfDay: ITimeOfDay, layoutSpec: LayoutSpec) {
    if (layoutSpec.type === 'allocated') {
        return positioningTopRaw(startOfDay, endOfDay, layoutSpec.dayAndTime.hour, layoutSpec.dayAndTime.minute)
    }

    if (layoutSpec.type === 'unallocated') {
        return ALLOCATED_PERCENT + 2 + 2 * layoutSpec.row
    }

    return -1
}

export function positioningHeightRaw(startOfDay: ITimeOfDay, endOfDay: ITimeOfDay, minutes: number) {
    const startMinuteOfDay = startOfDay.hour * 60 + startOfDay.minute
    const endMinuteOfDay = endOfDay.hour * 60 + endOfDay.minute
    const minutesInDay = endMinuteOfDay - startMinuteOfDay

    return (ALLOCATED_PERCENT * minutes) / minutesInDay
}

type LayoutSpecOptions = {
    startOfDay: ITimeOfDay
    endOfDay: ITimeOfDay
    currentOffset: XYCoord
    dropBounds: DOMRect
    initialOffset?: XYCoord | undefined
    initialLayoutSpec?: LayoutSpec | undefined
    eventDurationInMinutes?: number
    selectedWeekDays: DayOfWeek[]
}

export function positionToLayoutSpec({
    startOfDay,
    endOfDay,
    currentOffset,
    dropBounds,
    initialOffset = undefined,
    initialLayoutSpec = undefined,
    eventDurationInMinutes,
    selectedWeekDays
}: LayoutSpecOptions): AllocatedLayoutSpec | null {
    const offsetTop =
        initialLayoutSpec && initialOffset
            ? (initialOffset.y - dropBounds.top) / dropBounds.height -
              positioningTop(startOfDay, endOfDay, initialLayoutSpec) / 100
            : 0

    const left = currentOffset.x - dropBounds.left
    const top = currentOffset.y - dropBounds.top

    const leftProcent = left / dropBounds.width
    let topProcent = top / dropBounds.height - offsetTop

    if (topProcent < 0 && topProcent > -0.11) {
        topProcent = 0
    }

    let percentOfAllocated = topProcent / (ALLOCATED_PERCENT / 100)

    const startMinuteOfDay = startOfDay.hour * 60 + startOfDay.minute
    const endMinuteOfDay = endOfDay.hour * 60 + endOfDay.minute
    const minutesOfAllocated = endMinuteOfDay - startMinuteOfDay

    let decimalMinutes = toMinuteOfDay(startOfDay) + percentOfAllocated * minutesOfAllocated
    let minutesOfDay = 5 * Math.round(decimalMinutes / 5)
    if (eventDurationInMinutes !== undefined) {
        const percentPerMinute = ALLOCATED_PERCENT / minutesOfAllocated
        const durationInPercent = (eventDurationInMinutes * percentPerMinute) / 100
        const bottomPercent = topProcent + durationInPercent

        if (bottomPercent > ALLOCATED_PERCENT / 100) {
            if (bottomPercent < ALLOCATED_PERCENT / 100 + 0.08) {
                topProcent = ALLOCATED_PERCENT / 100 - durationInPercent
                percentOfAllocated = topProcent / (ALLOCATED_PERCENT / 100)
                decimalMinutes = toMinuteOfDay(startOfDay) + percentOfAllocated * minutesOfAllocated
                minutesOfDay = 5 * Math.round(decimalMinutes / 5)
            } else {
                return null
            }
        }
    }

    if (leftProcent >= 0 && leftProcent <= 1 && topProcent >= 0 && topProcent < ALLOCATED_PERCENT / 100) {
        const day = selectedWeekDays[Math.floor(leftProcent * selectedWeekDays.length)]

        return {
            type: 'allocated',
            column: 0,
            columns: 1,
            dayAndTime: minuteToDayAndTime(day, minutesOfDay),
            scheduleSelectorIndex: initialLayoutSpec?.scheduleSelectorIndex || 0,
            numSelectedSchedules: initialLayoutSpec?.numSelectedSchedules || 1
        }
    }

    return null
}

// layout allocated events

interface BeginOrEndEvent {
    readonly type: 'BEGIN' | 'END'
    readonly event: ScheduleEvent
    readonly time: number
}

const getBeginAndEndEventForLecture = (event: ScheduleEvent): BeginOrEndEvent[] => {
    const start = toMinuteOfDay(event.getDayAndTime()!)

    return [
        { type: 'BEGIN', event, time: start },
        { type: 'END', event, time: start + event.getDurationInMinutes() }
    ]
}

function getBeginAndEndEventsForLectures(events: ScheduleEvent[]) {
    return events.flatMap(getBeginAndEndEventForLecture).sort(
        combinedCmpFn(
            comparing((event) => event.time),
            comparing((event) => (event.type === 'BEGIN' ? 1 : 0))
        )
    )
}

export function layoutAllocatedEvents(
    events: ScheduleEvent[],
    scheduleSelectorIndex: number,
    numSelectedSchedules: number
): EventAndLayoutSpec[] {
    const beginOrEndEvents = getBeginAndEndEventsForLectures(events)

    const columnAssignments = new Map<string, number>()
    const occupiedColumns: boolean[] = []

    let ret: EventAndLayoutSpec[] = []

    beginOrEndEvents.forEach(({ type, event }) => {
        if (type === 'BEGIN') {
            let firstUnoccupiedColumn = occupiedColumns.indexOf(false)
            if (firstUnoccupiedColumn === -1) {
                firstUnoccupiedColumn = occupiedColumns.length
                occupiedColumns.push(true)
            } else {
                occupiedColumns[firstUnoccupiedColumn] = true
            }

            columnAssignments.set(event.getUniqueId(), firstUnoccupiedColumn)
        } else {
            occupiedColumns[columnAssignments.get(event.getUniqueId())!] = false
        }

        // No occupied columns?
        const noColumnsOccupied = occupiedColumns.indexOf(true) === -1

        if (noColumnsOccupied) {
            // Lecture cluster complete. "Commit" values.
            // Cluster width is the "high water mark" of occupied columns
            const clusterWidth = occupiedColumns.length

            // Merge in column assignments (plus cluster width) into result map.
            columnAssignments.forEach((startColumn, eventId) => {
                const event = findOrThrow(events, eventId, (event) => event.getUniqueId())
                const lectureAndLayout: EventAndLayoutSpec = {
                    event,
                    layoutSpec: {
                        type: 'allocated',
                        dayAndTime: event.getDayAndTime()!,
                        column: startColumn,
                        columns: clusterWidth,
                        scheduleSelectorIndex,
                        numSelectedSchedules
                    }
                }

                ret = [lectureAndLayout, ...ret]
            })

            // Clear for next cluster
            columnAssignments.clear()

            // Clear high-water mark
            occupiedColumns.splice(0, occupiedColumns.length)
        }
    })

    return ret
}

type UnallocatedLayoutSpecObject = { lectureID: string; event: EventAndLayoutSpec }

export function layoutUnallocatedEvents(
    unallocatedLectures: UnAllocatedEvent[],
    numSelectedSchedules: number
): EventAndLayoutSpec[] {
    const courseRoundGroups = groupBy(unallocatedLectures, (l) =>
        l.lecture.getLecture().getCourseRound().getCourseRoundId()
    )
    const courseRoundIds = Object.keys(courseRoundGroups)
    const layoutSpecs: UnallocatedLayoutSpecObject[] = []

    return courseRoundIds
        .sort((a, b) => a.localeCompare(b))
        .flatMap((courseRoundId, index) =>
            courseRoundGroups[courseRoundId]
                .sort(comparing<UnAllocatedEvent>((l) => l.lecture.getDurationInMinutes()))
                .map((event, lectureIndex) => {
                    const isDuplicatedLesson =
                        unallocatedLectures.filter(
                            ({ lecture }) =>
                                lecture.getLecture().getLectureId() === event.lecture.getLecture().getLectureId()
                        ).length > 1

                    let layoutSpec: EventAndLayoutSpec = {
                        event: event.lecture,
                        layoutSpec: {
                            type: 'unallocated',
                            column: index,
                            columns: courseRoundIds.length,
                            row: lectureIndex,
                            scheduleSelectorIndex: event.scheduleSelectorIndex,
                            numSelectedSchedules
                        }
                    }

                    const existingLayoutSpec = layoutSpecs.find(
                        (value) => value.lectureID === event.lecture.getLecture().getLectureId()
                    )

                    if (isDuplicatedLesson && existingLayoutSpec) {
                        layoutSpec = {
                            ...existingLayoutSpec.event,
                            layoutSpec: {
                                ...existingLayoutSpec.event.layoutSpec,
                                scheduleSelectorIndex: event.scheduleSelectorIndex
                            }
                        }
                    } else if (isDuplicatedLesson) {
                        layoutSpecs.push({ lectureID: event.lecture.getLecture().getLectureId(), event: layoutSpec })
                    }

                    return layoutSpec
                })
        )
}
