import { Table } from 'antd'
import classNames from 'classnames'
import { max } from 'lodash'
import { combinedCmpFn, comparing, reverseCmp } from '../../../../utils/compareUtil'
import { ProblemSeverity, problemSeverityComparator } from '../../../../utils/problems'
import { BarSummary, ErrorIcon, PercentageBar, WarningIcon } from './styled'
import type { ProblemListProblem, ProblemListProps } from './types'
import { useDevModeState } from '../../../../store/devmode/hooks'
import { addToMultimap } from '../../../../utils/collections'
import { numBlockers, numWarnings } from './utils'
import { ColumnsType } from 'antd/es/table'

export const ProblemsList = ({
    border = false,
    initialGroupExpandedState,
    problemGrouping,
    problems
}: ProblemListProps) => {
    const devMode = useDevModeState()

    const problemItems = problems.filter((p) => devMode || !p.isVirtual).sort(problemSeverityComparator)
    const columns: ColumnsType<ProblemListProblem> = [
        {
            title: 'W/E',
            dataIndex: 'severity',
            render: (_, p) => (p.severity === ProblemSeverity.BLOCKER ? <ErrorIcon /> : <WarningIcon />),
            width: '1.5em'
        },
        {
            title: 'Description',
            dataIndex: 'messageText',
            ellipsis: true
        }
    ]

    if (problemGrouping === undefined) {
        return (
            <Table<ProblemListProblem>
                className={classNames('problem-list', border || 'without-border')}
                size="small"
                rowKey={(p) => p.problemTypes.join(',')}
                dataSource={problemItems}
                columns={columns}
                showHeader={false}
                pagination={false}
            />
        )
    }

    // const problemGrouper = (p: ProblemListProblem) => (problemGrouping === 'NONE' ? (p) => p.problemTypeId : '')
    const problemGrouper = {
        KLASSES: (p: ProblemListProblem) => p.klassIds,
        TEACHERS: (p: ProblemListProblem) => p.teacherIds,
        PROBLEM_TYPE: (p: ProblemListProblem) => p.problemTypes
    }[problemGrouping]

    const problemsByGroupingKey = new Map<string, ProblemListProblem[]>()
    for (const p of problemItems) {
        for (const groupingKey of problemGrouper(p)) {
            addToMultimap(problemsByGroupingKey, groupingKey, p)
        }
    }

    const maxProblemCount = max([...problemsByGroupingKey.values()].map((problemList) => problemList.length)) || 1

    const groupKeysSorted = [...problemsByGroupingKey.keys()].sort(
        combinedCmpFn(
            reverseCmp(comparing((gk) => numBlockers(problemsByGroupingKey.get(gk)!))),
            reverseCmp(comparing((gk) => numWarnings(problemsByGroupingKey.get(gk)!)))
        )
    )

    return (
        <>
            {groupKeysSorted.map((groupKey) => {
                const problemsInThisGroup = problemsByGroupingKey.get(groupKey)

                return (
                    <details key={groupKey} {...(initialGroupExpandedState === 'EXPANDED' ? { open: true } : {})}>
                        <BarSummary>
                            <PercentageBar
                                fillFactor={numBlockers(problemsInThisGroup!) / maxProblemCount}
                                color="#febabf"
                                zIndex={-1}
                            />
                            <PercentageBar
                                fillFactor={problemsInThisGroup!.length / maxProblemCount}
                                color="#feecba"
                                zIndex={-2}
                            />
                            <div style={{ position: 'absolute', right: '.5em', top: '0.4em' }}>
                                {problemsInThisGroup!.length}
                            </div>
                            {groupKey}
                        </BarSummary>
                        <Table
                            className={classNames('problem-list', border || 'without-border')}
                            size="small"
                            dataSource={problemsInThisGroup}
                            columns={columns}
                            showHeader={false}
                            pagination={false}
                            // This code provides a hidden feature that helps a lot when debugging group overlap issues
                            // in prod. It is now temporarily disabled since it broke in a refactoring. We should either
                            // fix and reenable this code, or provide a better way. Leaving it in a comment for future
                            // reference
                            /*
                            onRow={(record, rowIndex) => ({
                                onClick: (event) => {
                                    if (record.problem.type === 'studentGroupConflictProblem') {
                                        const sgcp = record.problem
                                            .studentGroupConflictProblem as IStudentGroupConflictProblem
                                        console.log(
                                            'Overlap path: ',
                                            (
                                                overlapPath(
                                                    schedule
                                                        .findLecture(sgcp.lectureId1)
                                                        .getCourseRound()
                                                        .getStudentGroup(),
                                                    schedule.findLecture(sgcp.lectureId2).getCourseRound().getStudentGroup()
                                                ) || []
                                            ).map((sg) => sg.getDisplayName())
                                        )
                                    }
                                }
                            })}*/
                        />
                    </details>
                )
            })}
        </>
    )
}
