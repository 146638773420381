import { useDispatch, useSelector } from 'react-redux'
import { AutoSchedulerRunningState } from './types'
import { ApplicationState } from '../index'
import { useActiveOrgId } from '../auth/hooks'
import { ScheduleId } from '../../commonTypes'
import { useEffect } from 'react'
import { subscribe, unsubscribe } from './actions'

export const useAutoSchedulerStateSubscription = (scheduleId: ScheduleId) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(subscribe(scheduleId))

        return () => {
            dispatch(unsubscribe(scheduleId))
        }
    }, [dispatch, scheduleId])
}

export const useAutoSchedulerRunningState = (): AutoSchedulerRunningState => {
    const scheduleId = useActiveOrgId()
    useAutoSchedulerStateSubscription(scheduleId)

    return useSelector<ApplicationState, AutoSchedulerRunningState>((state) => state.autoSchedulerState.runState)
}

export const useAutoSchedulerTaRunningState = (): AutoSchedulerRunningState => {
    const scheduleId = useActiveOrgId()
    useAutoSchedulerStateSubscription(scheduleId)

    return useSelector<ApplicationState, AutoSchedulerRunningState>((state) => state.autoSchedulerState.taRunState)
}
