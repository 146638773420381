import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'
import { EnumFilterDropdown } from '../../YesNoFilterDropdown'

export const TimeslotPinnedFilterDropdown = (props: FilterDropdownProps) => {
    const { t } = useTranslation()

    return (
        <EnumFilterDropdown
            {...props}
            allFilters={[
                { value: 'true', text: t('Yes') },
                { value: 'false', text: t('No') }
            ]}
        />
    )
}
