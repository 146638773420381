import { PlusOutlined } from '@ant-design/icons'
import { ICourseOrSubjectId, IScheduleTransform, Terms } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import type { CourseRoundId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { generateFreshName } from '../../../../utils/miscUtil'

type NewCourseRoundButtonProps = {
    onNewCourseRound: (newCourseRoundId: CourseRoundId) => void
}

export const NewCourseRoundButton = (props: NewCourseRoundButtonProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const freshCourseRoundDisplayName = () =>
        generateFreshName(
            'Ny kursomgång',
            schedule.getCourseRounds().map((cr) => cr.getDisplayName())
        )

    const handleNewCourseRound = () => {
        const newCourseRoundId = uuid()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.courseRoundTransform({
                    newCourseRound: {
                        courseRoundId: newCourseRoundId,
                        displayName: freshCourseRoundDisplayName(),
                        courseOrSubjectId: ICourseOrSubjectId.subjectId(schedule.getSubjects()[0].getSubjectId()),
                        teacherIds: [],
                        studentGroupId: schedule.getStudentGroups()[0].getStudentGroupId(),
                        terms: Terms.YEAR,
                        totalHours: 0
                    }
                })
            )
        )
        props.onNewCourseRound(newCourseRoundId)
    }

    return (
        <Button variant="primary" size="sm" startIcon={<PlusOutlined />} onClick={handleNewCourseRound}>
            {t('AddCourseOffering')}
        </Button>
    )
}
