import { Flex, Table } from 'antd'
import type { RoomId } from '../../../commonTypes'
import TableCard from '../../../components/TableCard'
import { VisibleColumnsSelector } from '../../../components/VisibleColumnsSelector'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { comparing } from '../../../utils/compareUtil'
import { rowFilter } from '../../../utils/filtering'
import { FilterInput } from '../../schedule/components/FilterInput'
import { useFiltering } from '../../schedule/components/FilterInput/hooks'
import { NewRoomButton } from '../NewRoomButton'
import { useRoomColumns } from './columns'
import type { RoomRow, RoomsTableProps } from './types'

const RoomsTable = ({ selectedRowKeys, setSelectedRowKeys }: RoomsTableProps) => {
    const filtering = useFiltering()
    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useRoomColumns(filtering)

    const dataSourceUnfiltered = schedule.getRooms()
    const dataSource = dataSourceUnfiltered
        .map((room) => ({ room }))
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ room }) => room.getName()))

    return (
        <TableCard
            title={
                <Flex gap={8}>
                    <FilterInput {...filtering} />
                    <VisibleColumnsSelector columns={columns} setVisibleColumns={setVisibleColumns} />
                    <NewRoomButton
                        onNewRoom={(newRoomId) => {
                            setSelectedRowKeys([newRoomId])
                        }}
                    />
                </Flex>
            }
        >
            <Table<RoomRow>
                rowSelection={{
                    selectedRowKeys,
                    onChange: (newSelectedRowKeys) => {
                        setSelectedRowKeys(newSelectedRowKeys as RoomId[])
                    }
                }}
                dataSource={dataSource}
                columns={columns}
                size="small"
                rowKey={({ room }) => room.getRoomId()}
                onRow={({ room }) => ({
                    onClick: () => {
                        setSelectedRowKeys([room.getRoomId()])
                    }
                })}
                pagination={false}
                bordered
                sticky
            />
        </TableCard>
    )
}

export default RoomsTable
