import { Alert, Flex } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocalSchedule } from '../../../../../../store/schedule/hooks'
import { diff, teacherCmp } from '../TeacherGrid/diff'
import { conjureTeacherFromTeachers, sortedTeachersFromSchedule } from '../TeacherGrid/hooks'
import { parseTeacherRows } from '../TeacherGrid/teacherData'
import { SimpleProblemList } from './SimpleProblemList'
import { TeacherListDiff } from './TeacherListDiff'
import { TeacherBulkUpdatePreviewProps } from './types'

export const TeacherBulkUpdatePreview = ({ teacherGridData, validationErrors }: TeacherBulkUpdatePreviewProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const sortedTeachers = sortedTeachersFromSchedule(schedule)
    const currentTeachers = conjureTeacherFromTeachers(sortedTeachers)
    const currentSubjects = schedule.getSubjects().map((s) => s.getConjureObject())

    const newTeachers = parseTeacherRows(currentTeachers, currentSubjects, teacherGridData)
    const diffToPreview = diff(currentTeachers, newTeachers, (t) => t.teacherId, teacherCmp)

    return (
        <Flex vertical gap={8}>
            <TeacherListDiff diff={diffToPreview} />
            {validationErrors.length > 0 && (
                <Alert
                    showIcon
                    type="error"
                    message={t('ErrorsMustBeFixed')}
                    description={<SimpleProblemList problems={validationErrors} />}
                />
            )}
        </Flex>
    )
}
