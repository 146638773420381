import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import IconButton from '../../../../components/IconButton'
import { ApplicationState } from '../../../../store'
import { ScheduleSelector } from '../../../../store/scheduleselector/types'
import TypeScale from '../../../../styles/TypeScale'
import { useScheduleStore } from '../../store'
import WeekDaySelector from '../WeekDaySelector'
import classes from './style.module.css'

const ViewModeOptions = () => {
    const selectedScheduleSelectors = useSelector<ApplicationState, ScheduleSelector[]>(
        (state) => state.scheduleSelection.selectedSchedules
    )
    const maxColumns = selectedScheduleSelectors.length
    const { t } = useTranslation()

    const { columns, setColumns, viewingMode, setViewingMode } = useScheduleStore()

    return (
        <div className={classes.viewModeOptions}>
            <p className={classNames(TypeScale.Heading_MD, classes.viewModeOptions__text)}>
                {t('ColumnsView.SelectDaysToShow')}.
            </p>
            <WeekDaySelector />
            <p className={classNames(TypeScale.Heading_MD, classes.viewModeOptions__text)}>
                {t('ColumnsView.SelectViewMode')}.
            </p>
            <button
                type="button"
                className={classNames(classes.viewModeOptions__button, {
                    [classes['viewModeOptions__button--active']]: viewingMode === 'columns'
                })}
                onClick={() => {
                    setViewingMode('columns')
                }}
            >
                <p>{t('Columns')}</p>
            </button>
            <button
                type="button"
                className={classNames(classes.viewModeOptions__button, {
                    [classes['viewModeOptions__button--active']]: viewingMode === 'per-schedule'
                })}
                onClick={() => {
                    setViewingMode('per-schedule')
                }}
            >
                <p>{t('ColumnsView.PerSchedule')}</p>
            </button>
            {viewingMode === 'per-schedule' ? (
                <div className={classes.columnSelector}>
                    <p>
                        {columns} {columns > 1 ? t('ColumnsView.Columns') : t('ColumnsView.Column')}
                    </p>
                    <div className={classes.columnSelector__buttons}>
                        <IconButton
                            onClick={() => {
                                setColumns(columns > 1 ? columns - 1 : columns)
                            }}
                            variant="tertiary"
                            aria-label="minus"
                            disabled={columns <= 1}
                            size="sm"
                        >
                            <MinusOutlined />
                        </IconButton>
                        <IconButton
                            size="sm"
                            disabled={columns >= maxColumns}
                            onClick={() => {
                                setColumns(columns + 1)
                            }}
                            variant="tertiary"
                            aria-label="minus"
                        >
                            <PlusOutlined />
                        </IconButton>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default ViewModeOptions
