import { Button } from 'antd'
import Holidays from 'date-holidays'
import { memo, useMemo } from 'react'

type SchoolDayToggleButtonProps = {
    date: moment.Moment
    isSchoolDay: boolean
    onClick: () => void
}

const hd = new Holidays('SE')

const SchoolDayToggleButton = ({ date, isSchoolDay, onClick }: SchoolDayToggleButtonProps) => {
    const isHoliday = useMemo(() => (hd.isHoliday(date.toDate()) || []).some((h) => h.type === 'public'), [date])

    return (
        <div
            style={{
                background: date.month() % 2 === 0 ? '#f5f5f5' : '#fff',
                borderRight: date.day() === 0 ? '1px solid #f0f0f0' : 'none'
            }}
        >
            <Button
                type={isSchoolDay ? 'primary' : 'text'}
                danger={date.day() === 0 || isHoliday}
                shape="circle"
                onClick={onClick}
            >
                {date.date()}
            </Button>
        </div>
    )
}

export default memo(SchoolDayToggleButton)
