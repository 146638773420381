import { ColorInputProps } from './types'
import { Button, Popover } from 'antd'
import { PaletteColorPicker } from './Palette'
import { PropsWithChildren, useState } from 'react'

export const ColorInput = ({
    onChange = () => {},
    value = 'ffffff',
    assignedColors,
    children
}: PropsWithChildren<ColorInputProps>) => {
    const [popoverOpen, setPopoverOpen] = useState(false)
    const onColorPickerChange = (newCol: string) => {
        setPopoverOpen(false)
        onChange(newCol)
    }

    const colorButton = <Button style={{ background: `#${value}` }}>{children}</Button>

    return (
        <Popover
            content={
                <PaletteColorPicker value={value} onChange={onColorPickerChange} assignedColors={assignedColors} />
            }
            open={popoverOpen}
            onOpenChange={setPopoverOpen}
            trigger="click"
            placement="bottom"
        >
            {colorButton}
        </Popover>
    )
}
