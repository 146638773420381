import { Select, Tag, Tooltip } from 'antd'
import { FilteringState } from '../../pages/schedule/components/FilterInput/types'
import { toTranslate } from '../../utils/miscUtil'
import SearchPopupList from '../SearchPopupList'
import classes from './style.module.css'

type SearchPopupProps = {
    onSearch: (searchText: string) => void
    filtering: FilteringState
    value: string[]
    onChangeValue?: (value: string[]) => void
    options: {
        label: string
        options: {
            value: string
            label: string
            displayLabel?: string
        }[]
    }[]
    onSelect?: (value: string) => void
    onDeselect?: (value: string) => void
}

function SearchPopup({ onSearch, value, options, onChangeValue, filtering, onDeselect, onSelect }: SearchPopupProps) {
    return (
        <Select
            showSearch
            style={{ width: 'auto', flex: 1 }}
            placeholder={toTranslate('Sök')}
            value={value}
            options={options}
            maxTagCount={5}
            mode="multiple"
            onSearch={onSearch}
            onSelect={onSelect}
            onDeselect={onDeselect}
            searchValue={filtering.filterText}
            onDropdownVisibleChange={() => onSearch('')}
            onChange={onChangeValue}
            tagRender={(props) => {
                const newOptions = options.find((optionGroup) =>
                    optionGroup.options.find((o2) => o2.value === props.value)
                )

                const option = newOptions?.options.find((optionGroup) => optionGroup.value === props.value)

                const tooltipLabel = option?.displayLabel

                return (
                    <Tooltip title={tooltipLabel}>
                        <Tag {...props} bordered={false} className={classes.tag}>
                            {props.label}
                        </Tag>
                    </Tooltip>
                )
            }}
            dropdownRender={() => (
                <div className={classes.searchPopup}>
                    {options.map((option) => (
                        <SearchPopupList
                            options={option.options}
                            currentValue={value}
                            onChangeValue={(value) => onChangeValue?.(value)}
                            title={option.label}
                            onDeselect={onDeselect}
                            onSelect={onSelect}
                            filtering={filtering}
                            key={option.label}
                        />
                    ))}
                </div>
            )}
        />
    )
}

export default SearchPopup
