import { Form, Input, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import type { ILectureDurationThresholds, IMinBreakThresholds } from 'common-api'
import { IScheduleTransform } from 'common-api'
import { range } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { CourseId, SubjectId } from '../../commonTypes'
import type { CourseAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { comparing } from '../../utils/compareUtil'
import BulkGeneralInput from '../BulkGeneralInput'
import { BulkSingleSelect } from '../BulkSingleSelect'
import Button from '../Button'
import { LectureDurationThresholdsInput } from '../LectureDurationThresholdsInput'
import { MinBreakThresholdsInput } from '../course-rounds/MinBreakThresholdsInput'

type CourseDetailsProps = {
    courses: CourseAccessor[]
}

type CourseFormValues = {
    courseIds: CourseId[]
    codes: string[]
    subjectIds: SubjectId[]
    names: string[]
    minBreakThresholds: (IMinBreakThresholds | undefined)[]
    lectureDurationThresholds: (ILectureDurationThresholds | undefined)[]
}

export const CourseDetails = ({ courses }: CourseDetailsProps) => {
    const dispatch = useDispatch()
    const [form] = useForm()
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    const formValuesFromAccessors = (courses: CourseAccessor[]): CourseFormValues => ({
        courseIds: courses.map((c) => c.getCourseId()),
        codes: courses.map((c) => c.getCode()),
        subjectIds: courses.map((c) => c.getSubjectId()),
        names: courses.map((c) => c.getName()),
        minBreakThresholds: courses.map((c) => c.getMinBreakThresholds()),
        lectureDurationThresholds: courses.map((c) => c.getLectureDurationThresholds())
    })

    courses.sort(comparing((c) => c.getName()))

    const saveCourses = (formValues: CourseFormValues) => {
        const crTransforms = range(0, formValues.courseIds.length).map((index) =>
            IScheduleTransform.courseTransform({
                newCourse: {
                    courseId: formValues.courseIds[index],
                    subjectId: formValues.subjectIds[index],
                    code: formValues.codes[index],
                    name: formValues.names[index],
                    minBreakThresholds: formValues.minBreakThresholds[index],
                    lectureDurationThresholds: formValues.lectureDurationThresholds[index]
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(crTransforms)))
        message.success(t(courses.length === 1 ? 'CourseSaved' : 'CoursesSaved'))
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Form
                form={form}
                name="courseForm"
                initialValues={formValuesFromAccessors(courses)}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: '700px', width: '100%' }}
                onFinish={saveCourses}
                labelWrap
                colon={false}
            >
                <Form.List name="courseIds">
                    {(fields) =>
                        fields.map((field) => (
                            <Form.Item hidden {...field}>
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.List name="subjectIds">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item hidden {...field}>
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.List name="codes">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item
                                label={index === 0 ? t('CourseCode') : ' '}
                                style={{
                                    marginBottom: index === courses.length - 1 ? undefined : '5px'
                                }}
                                {...field}
                            >
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.List name="names">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item
                                label={index === 0 ? t('Names') : ' '}
                                style={{
                                    marginBottom: index === courses.length - 1 ? undefined : '5px'
                                }}
                                {...field}
                            >
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.Item name="subjectIds" label={t('Subject')}>
                    <BulkSingleSelect
                        originalValue={courses.map((c) => c.getSubjectId())}
                        options={schedule
                            .getSubjects()
                            .map((s) => ({
                                value: s.getSubjectId(),
                                label: s.getName()
                            }))
                            .toSorted(comparing((opt) => opt.label))}
                    />
                </Form.Item>
                <Form.Item name="minBreakThresholds" label={t('BreakSettings')}>
                    <BulkGeneralInput<IMinBreakThresholds | undefined>
                        renderInput={(value, onChange) => <MinBreakThresholdsInput value={value} onChange={onChange} />}
                        multiplicityMessage={t('SelectSingleSubject.BreakRules')}
                    />
                </Form.Item>
                <Form.Item name="lectureDurationThresholds" label={t('LectureDuration')}>
                    <BulkGeneralInput<ILectureDurationThresholds | undefined>
                        renderInput={(value, onChange) => (
                            <LectureDurationThresholdsInput
                                value={value}
                                onChange={onChange}
                                inheritLabels={{
                                    inheritEntityTypeName: t('Subject').toLowerCase(),
                                    currentEntityTypeName: t('Course').toLowerCase()
                                }}
                                inheritedValues={courses[0].getLectureDurationThresholds(true)}
                            />
                        )}
                        multiplicityMessage={t('SelectSingleSubject.LectureDurations')}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8 }}>
                    <Button variant="primary" type="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
