import type { SVGProps } from 'react'

const SvgSkip = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" className="door_svg__icon" viewBox="0 0 1024 1024" {...props}>
        <title>Hoppa över tecken</title>
        <path d="M525.88 10.908c-121.62-1.729-242.46 51.933-322.57 155.79-58.877 76.334-85.882 167.15-83.131 256.48h80.027c-1.242-31.402 2.092-62.628 10.08-93.166 10.845-41.491 29.733-79.824 56.07-114.07 26.338-34.243 58.541-62.334 95.799-83.383 35.99-20.385 75.151-33.411 116.38-38.791s82.422-2.84 122.44 7.627c41.412 10.784 79.746 29.67 113.99 56.008s62.334 58.542 83.383 95.801a318.6 318.6 0 0 1 38.789 116.38c1.702 13.042 2.624-21.886 2.746-8.924l-66.11.498c-6.703.02-10.412 7.778-6.218 13.006l104.43 130.28c3.232 4 9.432 3.974 12.541-.068l102.45-131.89c4.085-5.31.298-13.016-6.384-12.857l-60.71.363c-7.222-85.835-54.617-188.04-156.08-266.08-71.132-54.712-154.71-81.827-237.92-83.01z" />
        <g
            aria-label="a b c"
            style={{
                whiteSpace: 'pre'
            }}
        >
            <g aria-label="abc" style={{}} transform="translate(-43.2 -158.077)">
                <path d="M201.64 949.99q-29.167 0-44.01 9.896-14.583 9.896-14.583 29.167 0 17.708 11.719 27.865 11.979 9.896 33.073 9.896 26.302 0 44.271-18.75 17.969-19.01 17.969-47.396V949.99zm142.45-35.156v166.41h-94.01v-43.229q-18.75 26.562-42.187 38.802-23.437 11.979-57.031 11.979-45.312 0-73.698-26.302-28.125-26.562-28.125-68.75 0-51.302 35.156-75.26 35.417-23.958 110.94-23.958h54.948v-7.292q0-22.135-17.448-32.292-17.448-10.417-54.427-10.417-29.948 0-55.729 5.99t-47.917 17.969V797.39q29.948-7.292 60.156-10.937 30.208-3.906 60.417-3.906 78.906 0 113.8 31.25 35.156 30.99 35.156 101.04zM586.01 1021.1q29.948 0 45.573-21.875 15.885-21.875 15.885-63.542t-15.885-63.542q-15.625-21.875-45.573-21.875t-46.094 22.135q-15.885 21.875-15.885 63.281t15.885 63.542q16.146 21.875 46.094 21.875zm-61.979-188.8q19.271-25.521 42.708-37.5 23.437-12.24 53.906-12.24 53.906 0 88.542 42.969 34.635 42.708 34.635 110.16 0 67.448-34.635 110.42-34.635 42.708-88.542 42.708-30.469 0-53.906-11.979-23.437-12.24-42.708-37.76v42.188h-93.229v-405.21h93.229zM1048.3 798.69v76.042q-19.01-13.021-38.281-19.271-19.01-6.25-39.583-6.25-39.062 0-60.937 22.917-21.615 22.656-21.615 63.542 0 40.885 21.615 63.802 21.875 22.656 60.937 22.656 21.875 0 41.406-6.51 19.792-6.51 36.458-19.271v76.302q-21.875 8.073-44.531 11.979-22.396 4.166-45.052 4.166-78.906 0-123.44-40.364-44.531-40.625-44.531-112.76t44.531-112.5q44.531-40.625 123.44-40.625 22.917 0 45.052 4.167 22.396 3.906 44.531 11.979z" />
            </g>
        </g>
        <path
            d="M119.91 418.68v35.867-7.533 45.332a13.883 13.883 0 0 0 13.914 13.912h52.111a13.88 13.88 0 0 0 13.912-13.912v-45.332 7.533-35.867z"
            style={{
                paintOrder: 'markers fill stroke'
            }}
        />
    </svg>
)

export default SvgSkip
