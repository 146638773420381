import { Alert, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { MAX_LISTING_CLAUSES, textualListing } from '../../../../../../../textUtil'
import { comparing } from '../../../../../../../utils/compareUtil'
import { shortTeacherDisplayName } from '../util'
import { SimpleTeacherListDiff } from './SimpleTeacherListDiff'
import classes from './style.module.css'
import { BackgroundChangesAlertProps } from './types'

export const BackgroundChangesAlert = ({ diff, style }: BackgroundChangesAlertProps) => {
    const changedTeachers = [...diff.updated.map(([prev]) => prev), ...diff.created, ...diff.deleted]

    const teacherDisplayNamesToShow = textualListing(
        changedTeachers.map(shortTeacherDisplayName).toSorted(comparing((dn) => dn.length)),
        'lärare'
    )

    const { t } = useTranslation()
    const changeParts = [
        ...(diff.updated.length !== 0 ? [t('BackgroundChanges.Updated')] : []),
        ...(diff.created.length !== 0 ? [t('BackgroundChanges.Added')] : []),
        ...(diff.deleted.length !== 0 ? [t('BackgroundChanges.Removed')] : [])
    ]
    const changeVerbsStr = textualListing(changeParts, '', t('BackgroundChanges.Or'))

    // We only want to show a tooltip that describes the details of the changes if there's a need for it. If the entire
    // list of teachers is spelled out, and there are only additions or only deletions, the sentence is sufficiently
    // descriptive on its own.
    const showDescriptionTooltip =
        changeParts.length >= 2 || diff.updated.length !== 0 || changedTeachers.length > MAX_LISTING_CLAUSES

    const changeVerbs = showDescriptionTooltip ? (
        <Tooltip title={<SimpleTeacherListDiff diff={diff} />} rootClassName={classes.updateDescriptionTooltip}>
            <span style={{ textDecoration: 'underline black dashed' }}>{changeVerbsStr}</span>
        </Tooltip>
    ) : (
        changeVerbsStr
    )

    const description = (
        <>
            {`${teacherDisplayNamesToShow} ${t('BackgroundChanges.Has')} `}
            {changeVerbs}
            {t('BackgroundChanges.SinceEditModeOpened')}
        </>
    )

    return (
        <Alert
            type="warning"
            showIcon
            banner
            style={style}
            message={t('BackgroundChanges.BackgroundUpdatesTitle')}
            description={description}
        />
    )
}
