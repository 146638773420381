import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export const defaultNS = 'translation'

export const resources = {
    sv: {
        translation: {
            ActualHours: 'Faktiskt antal timmar',
            Add: 'Lägg till',
            AddLecture: 'Lägg till lektion',
            AddSetting: 'Lägg till inställning',
            AddBreakSetting: 'Lägg till rastvillkor',
            AddStudentGroup: 'Lägg till elevgrupp',
            After: 'efter',
            AfterLecture: 'Efter lektion',
            All: 'Alla',
            AllowSkipInMatching: 'Tillåt överhopp i matchning',
            And: 'och',
            At: 'vid',
            AT: 'HT',
            Atleast: 'minst',
            Attribute: 'Attribut',
            Automation: 'Automation',
            AWeeks: 'A-veckor',
            Before: 'före',
            BeforeLecture: 'Före lektion',
            Between: 'mellan',
            BlockedTime: 'Blockerad tid',
            BreakHasToBe: 'Rast måste vara minst',
            BreakSettings: 'Rastvillkor',
            BreakShouldPreferablyBe: '...men helst',
            BWeeks: 'B-veckor',
            Cancel: 'Avbryt',
            CanteenLoadDuringLunch: 'Matsalsbelastning under lunch',
            CaseSensitive: 'Skilj på stora / små bokstäver',
            ChooseLanguage: 'Välj språk: ',
            Class: 'Klass',
            Classes: 'Klasser',
            CourseOfferings: 'Kursomgångar',
            Filter: 'Filtrera',
            HighlightSearchText: 'Markera söktext',
            Rooms: 'Salar',
            ClassHasTooShortLunch: 'Klass {{Class}} har ingen lunch på {{Day}}',
            Code: 'Kod',
            CollapseAll: 'Kollapsa alla',
            Condition: 'Villkor',
            Course: 'Kurs',
            Courses: 'Kurser',
            CourseCode: 'Kurskod',
            CourseOffering: 'kursomgång',
            CourseOfferingHaveToo: 'Kursomgång har för',
            CourseOfferingHaveTooFewHours: 'Kursomgång har för få timmar: ',
            Create: 'Skapa',
            DayAndTime: 'Dag och tid',
            Description: 'Beskrivning',
            Designation: 'Titel',
            Development: 'Utveckling',
            DevelopmentButton: {
                DumpDaysTimes: 'Dumpa dagar och tider',
                RemoveAllPins: 'Ta bort alla pins för dag/tid',
                RemoveNonScheduledLectures: 'Ta bort icke schemalagda lektioner',
                RemoveTimeDay: 'Ta bort dag / tid från alla lektioner',
                RemoveTimeDayNonPinned: 'Ta bort dag / tid från alla icke-pinnade lektioner',
                TakeDayTimeOnLecturesOutsideBlock: 'Ta dag / tid på lektioner utanför block'
            },
            Duration: 'Längd',
            Employment: 'Tjänstegrad',
            EvenWeeks: 'Jämna veckor',
            Except: '...utom',
            ExpandAll: 'Expandera alla',
            Export: 'Exportera',
            ExportSemester: 'Exportera perioder',
            Few: 'få',
            FirstName: 'Förnamn',
            GroupAfterType: 'Gruppera efter typ',
            GroupAfterTeacher: 'Gruppera efter lärare',
            GroupAfterClass: 'Gruppera efter klass',
            HaveToBe: 'måste vara',
            Hours: 'Timmar',
            HoursSmall: ' timmar',
            HoursInPercentOfGoal: 'Timmar i procent av mål',
            InRelationToLecture: 'Förhållande till lektion',
            Labels: 'Etiketter',
            Languages: {
                Swedish: 'Svenska',
                English: 'Engelska',
                German: 'Tyska'
            },
            LastName: 'Efternamn',
            Lecture: 'Lektion',
            LectureSmall: 'lektion',
            LectureIsToo: 'Lektionen är för ',
            LectureDuration: 'Lektions\u00ADlängder',
            LectureEndsTooLate: 'Lektion slutar för sent:',
            Lectures: 'Lektioner',
            LessonDetails: 'Lektionsdetaljer',
            LessonToo: 'Lektion för ',
            Long: 'lång',
            MainMenu: {
                CanteenLoad: 'Matsalsbelastning',
                Courses: 'Kurser',
                Education: 'Utbildning',
                Rooms: 'Salar',
                Schedule: 'Schema',
                ServiceDistribution: 'Tjänstefördelning',
                StudentGroups: 'Elevgrupper',
                Teachers: 'Lärare',
                TeachingGroups: 'Kurs\u00ADomgångar',
                TeachingHours: 'Undervisningstimmar',
                Parameters: 'Parametrar',
                Periods: 'Perioder',
                Lectures: 'Lektioner',
                Reports: 'Rapporter',
                Settings: 'Inställningar'
            },
            Many: 'många',
            MarkedBlockedTime: 'Markera blockerad tid',
            Minutes: 'minuter',
            MinutesLongBut: 'men',
            Name: 'Namn',
            Names: 'Namn',
            NoBreakSettings: 'Inga rastvillkor',
            NoClass: 'Ingen klass',
            NoCondition: 'Inget villkor',
            NoLectures: 'Inga lektioner',
            NoLecturesMatchFilter: 'Inga lektioner matchar valda filter',
            NoRoom: 'Ingen sal',
            NoRoomSelected: 'Sal saknas för ',
            NoLessonSelected: 'Ingen lektion vald',
            Non: 'icke ',
            NonHypen: 'icke-',
            NonOverlapingStudentGroups: 'Icke-överlappande elevgrupper',
            NotPartOfBlock: 'Ej del av block',
            NotScheduled: 'Ej schemalagd: ',
            Number: 'Nr',
            OddWeeks: 'Udda veckor',
            Other: 'Övriga',
            OverlapingGroup: 'överlappande grupp',
            OverlapingStudentGroups: 'Överlappande elevgrupper',
            OverlapWith: 'överlapp med',
            Remove: 'ta bort',
            ReservedTime: 'Reserverad tid',
            RequiredRoomAttributes: 'Nödvändiga salssattribut',
            Room: 'Sal',
            RoomChange: 'Salsbyte',
            RoomChangeSmall: 'salsbyte',
            RoomDoubleBooked: 'Sal dubbelbokad: {{roomName}} på {{day}} kl {{fromHHMM}}-{{toHHMM}}',
            RoomMissingAttributes: 'Sal {{roomName}} saknar attribut {{missingAttributes}}',
            RoomScheduledOnBlockedTime:
                'Sal schemalagd på blockerad tid: {{roomName}} på {{day}} kl {{fromHHMM}}-{{toHHMM}}',
            SameCourseMultipleTimesOnSameDay: 'Samma kurs flera gånger på en dag: ',
            SameSubjectMultipleTimesOnSameDay: 'Samma ämne flera gånger på en dag: {{subjectName}} med {{sgName}}',
            Save: 'Spara',
            SchoolYear: 'Läsår',
            SearchCourse: 'Sök kurs',
            SearchCourseOffering: 'Sök kursomgång',
            SearchRoom: 'Sök sal',
            SearchPlaceholders: {
                SearchSchedule: 'Sök schema',
                SearchStudentGroup: 'Sök elevgrupp',
                SearchTeacher: 'Sök lärare'
            },
            Short: 'kort',
            ShouldPreferablyBe: 'ska helst vara',
            ShowInheritedSettings: 'Visa ärvda inställningar',
            Signature: 'Signatur',
            Square: 'Fyrkant',
            ST: 'VT',
            start: 'Starta',
            stop: 'Stoppa',
            StudentGroup: 'Elevgrupp',
            StudentGroupsDoubleBooked: 'Elevgrupper dubbelbokade: {{sgNames}} på {{day}} kl {{fromHHMM}}-{{toHHMM}}',
            Students: 'Elever',
            StudentsHasTooShortBreakOnDay: 'Elever i {{sgName}} har för kort rast mellan lektioner på {{day}}',
            Subject: 'Ämne',
            SubjectChange: 'Ämnesbyte',
            SubjectChangeSmall: 'ämnesbyte',
            Teacher: 'Lärare',
            Teachers: 'Lärare',
            TeacherDoubleBooked: 'Lärare dubbelbokad: {{teacherName}} på {{day}} kl {{fromHHMM}}-{{toHHMM}}',
            TeacherFrameTimeExceeded: 'Lärares ramtid överskriden: {{teacherSchoolId}} har {{frameTime}}',
            TeachingGroup: 'Kurs\u00ADomgångar',
            TeachingGroups: 'Kurs\u00ADomgångar',
            TeacherHasNoLunchBreak: 'Lärare har ingen lunchrast: {{teacherSchoolId}} på {{dayName}}',
            TeacherHasTooShortBreak: '{{teacherId}} har för kort rast mellan lektioner',
            TeacherHasTooShortBreakOnDay: '{{teacherId}} har för kort rast mellan lektioner på {{day}}',
            TeacherScheduledOnBlockedTime:
                'Lärare schemalagd på blockerad tid: {{teacherSchoolId}} på {{day}} kl {{fromHHMM}}-{{toHHMM}}',
            TheCourseOffering: 'kursomgången',
            TheGroup: 'gruppen',
            TheGroups: 'grupperna',
            ThisAttributeOnlyApplysToTheFollowingRooms: 'Detta attribut gäller endast följande salar: ',
            ThisAttributeOnlyApplysToTheFollowingLectures: 'Detta attribut gäller endast följande lektioner: ',
            ThisBlockOnlyApplysToTheFollowingLessons: 'Detta block gäller endast följande lektioner: ',
            ThisGroupOnlyOverlapsWithTheFollowingGroups: 'Denna grupp överlappar endast med följande grupper: ',
            ThisLabelOnlyApplysToTheFollowingGroups: 'Denna etikett gäller endast följande grupper: ',
            TimePinned: 'Tid fäst',
            WeekDay: 'Veckodag',
            Yes: 'Ja',
            No: 'Nej',
            Preferably: 'helst',
            Problems: 'Problem',
            ProblemTypeLabel: {
                EndTimeProblem: 'Lektion slutar för sent',
                TeacherConflictProblem: 'Lärare dubbelbokad',
                StudentGroupLunchProblem: 'Klass har för kort lunchrast',
                TeacherBreakProblem: 'Lärare har för kort rast mellan lektioner',
                StudentGroupBreakProblem: 'Elever har för kort rast mellan lektioner',
                RoomConflictProblem: 'Sal dubbelbokad',
                RoomReservedTimeConflictProblem: 'Sal schemalagd på blockerad tid',
                RequiredRoomAttributeProblem: 'Sal saknar attribut',
                NoRoomSetProblem: 'Lektion saknar sal',
                UnscheduledLectureProblem: 'Lektion ej schemalagd',
                ScheduleOverrunProblem: 'Lektion slutar för sent',
                SameCourseMultipleTimesOnSameDayProblem: 'Samma kurs flera gånger på samma dag',
                SameSubjectMultipleTimesOnSameDayProblem: 'Samma ämne flera gånger på samma dag',
                StudentGroupConflictProblem: 'Elevgrupp dubbelbokade',
                TeacherFrameTimeProblem: 'Lärares ramtid överskriden',
                TeacherLunchProblem: 'Lärare har för kort lunchrast',
                TeacherReservedTimeConflictProblem: 'Lärare schemalagd på blockerad tid'
            },
            Qualifications: 'Behörigheter',
            TeacherSaved: 'Lärare sparad',
            TeachersSaved: 'Lärare sparade',
            StudentGroupSaved: 'Elevgrupp sparad',
            StudentGroupsSaved: 'Elevgrupper sparade',
            SelectSingleStudentGroupToEditName: 'Välj en enskild elevgrupp för att redigera namnet.',
            SelectSingleStudentGroupToEditDescription: 'Välj en enskild elevgrupp för att redigera beskrivningen.',
            LecturesReferencingSelectedRooms: '{{count}} lektion(er) refererar till valda salar',
            RoomRemoved: 'Sal borttagen',
            RemoveRoom: 'Ta bort sal',
            RemoveRooms: 'Ta bort salar',
            AddRoom: 'Lägg till sal',
            NoRoomAttributes: 'Inga salsattribut',
            ValidFor: 'Gäller för:',
            WeekDays: {
                Monday: 'Måndag',
                Tuesday: 'Tisdag',
                Wednesday: 'Onsdag',
                Thursday: 'Torsdag',
                Friday: 'Fredag',
                Saturday: 'Lördag',
                Sunday: 'Söndag'
            },
            WeekDaysShort: {
                Monday: 'Må',
                Tuesday: 'Ti',
                Wednesday: 'On',
                Thursday: 'To',
                Friday: 'Fr',
                Saturday: 'Lö',
                Sunday: 'Sö'
            },
            WholeSchoolYear: 'Hela läsåret',
            Weeks: 'Veckor',
            With: '...med',
            W: 'v.',
            Actions: 'Åtgärder',
            AddPeriod: 'Lägg till period',
            AddSubject: 'Lägg till ämne',
            AddCourse: 'Lägg till kurs',
            AddCourseOffering: 'Lägg till kursomgång',
            AddTeacher: 'Lägg till lärare',
            ClearContent: 'Töm innehåll',
            Edit: 'Redigera',
            NewSchedule: 'Nytt schema',
            NewRows: 'Nya rader',
            NoSubjects: 'Inga ämnen',
            PeriodName: 'Namn för perioden',
            RemoveCourse: 'Ta bort kurs',
            RemoveCourses: 'Ta bort kurser',
            RemoveSubject: 'Ta bort ämne',
            RemoveSubjects: 'Ta bort ämnen',
            RemoveStudentGroup: 'Ta bort elevgrupp',
            RemoveStudentGroups: 'Ta bort elevgrupper',
            RemoveTeacher: 'Ta bort lärare',
            ResetContent: 'Återställ innehåll',
            Search: 'Sök',
            SubjectCode: 'Ämneskod',
            TableEditing: 'Tabellredigering',
            Title: 'Titel',
            TransferToAdmin: 'Överför till Admin',
            TransferToSelectedSchedule: 'Exportera till valt schema',
            TransferComplete: 'Överföring klar',
            TransferStatus: {
                Rooms: 'Överför salar',
                Teachers: 'Överför lärare',
                Periods: 'Överför perioder',
                StudentGroups: 'Överför elevgrupper',
                Courses: 'Överför kurser',
                Lectures: 'Överför lektioner'
            },
            TeacherChanges: {
                NewTeachers: 'Nya lärare',
                UpdatedTeachers: 'Uppdaterade lärare',
                RemovedTeachers: 'Borttagna lärare',
                NoNewTeachers: 'Inga nya lärare',
                NoUpdatedTeachers: 'Inga uppdaterade lärare',
                NoRemovedTeachers: 'Inga borttagna lärare',
                RemovedQualifications: 'Borttagna behörigheter',
                AddedQualifications: 'Tillagda behörigheter'
            },
            Errors: {
                BackgroundChanges: 'Uppdateringar har gjorts i bakgrunden',
                DuplicateSignature: 'Signatur förekommer även på rad',
                ErrorsMustBeFixed: 'Följande fel måste åtgärdas',
                ImportFailed: 'Schemaimport misslyckades. Vänligen kontakta Meitner Support.',
                InvalidPercentage: 'Felaktigt formatterad procentsats',
                LecturesReferencing: 'lektion(er) refererar till',
                NoSuitableEventType: 'Kunde inte hitta lämplig händelsetyp',
                PredefinedPeriodNotFound: {
                    SchoolYear: 'Kunde inte hitta fördefinerad period för läsåret.',
                    Spring: 'Kunde inte hitta fördefinerad period för vårterminen.',
                    Fall: 'Kunde inte hitta fördefinerad period för höstterminen.'
                },
                ReservedTimesReferencing: 'reserverade tider refererar till perioden',
                StudentGroupReferencedInDistribution: 'Elevgrupp refereras till i tjänstefördelningen ({{name}})',
                TeacherReferencedInDistribution: 'Lärare refereras till i tjänstefördelningen ({{name}})',
                UnknownSubject: 'Okänt ämne:'
            },
            Success: {
                Created: 'skapade',
                ImportComplete: 'Schema importerad.',
                SubjectSaved: 'Ämne sparad',
                SubjectsSaved: 'Ämnen sparade',
                SubjectRemoved: 'Ämne borttaget',
                SubjectsRemoved: 'Ämnen borttagna'
            },
            SelectSingleSubject: {
                BreakRules: 'Välj ett enskilt ämne för att ändra rastvillkor.',
                LectureDurations: 'Välj ett enskilt ämne för att ändra lektionslängder.'
            },
            EntityTypes: {
                Subject: 'ämne',
                Schedule: 'schemat'
            },
            DuplicateNamesInSubjectsCourses: 'Namndubbletter bland ämnen/kurser:',
            SelectScheduleToTransferTo: 'Välj vilket schema det aktuella schemat ska överföras till:',
            ImportFromRoyalSchedule: 'Import från RoyalSchedule',
            Semesters: 'Terminer',
            'Success.CourseRemoved': 'Kurs borttagen',
            'Errors.CourseRoundsReferencing': '{{count}} kursomgång(ar) refererar till valda kurser'
        }
    } as const,
    en: {
        translation: {
            ActualHours: 'Actual number of hours',
            Add: 'Add',
            AddLecture: 'Add lecture',
            AddSetting: 'Add setting',
            AddBreakSetting: 'Add break setting',
            AddStudentGroup: 'Add student group',
            After: 'after',
            AfterLecture: 'After lecture',
            All: 'All',
            AllowSkipInMatching: 'Allow skip in matching',
            And: 'and',
            At: 'at',
            AT: 'HT',
            Atleast: 'at least',
            Attribute: 'Attribute',
            Automation: 'Automation',
            AWeeks: 'A-weeks',
            Before: 'before',
            BeforeLecture: 'Before lecture',
            Between: 'between',
            BlockedTime: 'Blocked time',
            BreakHasToBe: 'Break has to be at least',
            BreakSettings: 'Break settings',
            BreakShouldPreferablyBe: '...but preferably',
            BWeeks: 'B-weeks',
            Cancel: 'Cancel',
            CanteenLoadDuringLunch: 'Canteen load during lunch',
            CaseSensitive: 'Case sensitive',
            ChooseLanguage: 'Choose language: ',
            Class: 'Class',
            Classes: 'Classes',
            CourseOfferings: 'Course Offerings',
            Filter: 'Filter',
            HighlightSearchText: 'Highlight search text',
            Rooms: 'Rooms',
            ClassHasTooShortLunch: 'Class {{Class}} has no lunch on {{Day}}',
            Code: 'Code',
            CollapseAll: 'Collapse all',
            Condition: 'Condition',
            Course: 'Course',
            Courses: 'Courses',
            CourseCode: 'Course code',
            CourseOffering: 'course offering',
            CourseOfferingHaveToo: 'Course offering have too',
            CourseOfferingHaveTooFewHours: 'Course offering have too few hours: ',
            Create: 'Create',
            DayAndTime: 'Day and time',
            Description: 'Description',
            Designation: 'Designation',
            Development: 'Development',
            DevelopmentButton: {
                DumpDaysTimes: 'Dump days and times',
                RemoveAllPins: 'Remove all pins for day/time',
                RemoveNonScheduledLectures: 'Remove non-scheduled lectures',
                RemoveTimeDay: 'Remove day / time from all lectures',
                RemoveTimeDayNonPinned: 'Remove day / time from all non-pinned lectures',
                TakeDayTimeOnLecturesOutsideBlock: 'Take day / time on lectures outside block'
            },
            Duration: 'Duration',
            Employment: 'Employment',
            EvenWeeks: 'Even weeks',
            Except: '...except',
            ExpandAll: 'Expand all',
            Export: 'Export',
            ExportSemester: 'Export periods',
            Few: 'few',
            FirstName: 'First name',
            GroupAfterType: 'Group after type',
            GroupAfterTeacher: 'Group after teacher',
            GroupAfterClass: 'Group after class',
            HaveToBe: 'has to be',
            Hours: 'Hours',
            HoursSmall: ' hours',
            HoursInPercentOfGoal: 'Hours in percent of goal',
            InRelationToLecture: 'In relation to lecture',
            Labels: 'Labels',
            Languages: {
                Swedish: 'Swedish',
                English: 'English',
                German: 'German'
            },
            LastName: 'Last name',
            Lecture: 'Lecture',
            LectureSmall: 'lecture',
            LectureIsToo: 'Lecture is too',
            LectureDuration: 'Lecture durations',
            LectureEndsTooLate: 'Lecture ends too late:',
            Lectures: 'Lectures',
            LessonDetails: 'Lesson details',
            LessonToo: 'Lesson too',
            Long: 'long',
            MainMenu: {
                CanteenLoad: 'Canteen load',
                Courses: 'Courses',
                Education: 'Education',
                Rooms: 'Rooms',
                Schedule: 'Schedule',
                ServiceDistribution: 'Service distribution',
                StudentGroups: 'Student groups',
                Teachers: 'Teachers',
                TeachingGroups: 'Course Offerings',
                TeachingHours: 'Teaching hours',
                Parameters: 'Parameters',
                Periods: 'Periods',
                Lectures: 'Lectures',
                Reports: 'Reports',
                Settings: 'Settings'
            },
            Many: 'many',
            MarkedBlockedTime: 'Mark blocked time',
            Minutes: 'minutes',
            MinutesLongBut: 'but',
            Name: 'Name',
            Names: 'Names',
            NoBreakSettings: 'No break settings',
            NoClass: 'No class',
            NoCondition: 'No condition',
            NoLectures: 'No lectures',
            NoLecturesMatchFilter: 'No lectures match selected filters',
            NoRoom: 'No room',
            NoRoomSelected: 'No room selected for ',
            NoLessonSelected: 'No lesson selected',
            Non: 'non ',
            NonHypen: 'non-',
            NonOverlapingStudentGroups: 'Non-overlaping student groups',
            NotPartOfBlock: 'Not part of block',
            NotScheduled: 'Not scheduled: ',
            Number: 'No.',
            OddWeeks: 'Odd weeks',
            Other: 'Other',
            OverlapingGroup: 'overlaping group',
            OverlapingStudentGroups: 'Overlaping student groups',
            OverlapWith: 'overlap with',
            Remove: 'remove',
            ReservedTime: 'Reserved time',
            Room: 'Room',
            RoomChange: 'Room change',
            RoomChangeSmall: 'room change',
            RoomDoubleBooked: 'Room double booked: {{roomName}} on {{day}} at {{fromHHMM}}-{{toHHMM}}',
            RoomMissingAttributes: 'Room missing attribute {{missingAttributes}}',
            RoomScheduledOnBlockedTime:
                'Room scheduled on blocked time: {{roomName}} on {{day}} at {{fromHHMM}}-{{toHHMM}}',
            SameCourseMultipleTimesOnSameDay: 'Same course multiple times on same day: ',
            SameSubjectMultipleTimesOnSameDay:
                'Same subject multiple times on same day: {{subjectName}} with {{sgName}}',
            Save: 'Save',
            SchoolYear: 'School year',
            SearchCourse: 'Search course',
            SearchCourseOffering: 'Search course offering',
            SearchRoom: 'Search room',
            SearchPlaceholders: {
                SearchSchedule: 'Search schedule',
                SearchStudentGroup: 'Search student group',
                SearchTeacher: 'Search teacher'
            },
            Short: 'short',
            ShouldPreferablyBe: 'should preferably be',
            ShowInheritedSettings: 'Show inherited settings',
            Signature: 'Signature',
            Square: 'Square',
            ST: 'VT',
            start: 'Start',
            stop: 'Stop',
            StudentGroup: 'Student group',
            StudentGroupsDoubleBooked:
                'Student groups double booked: {{sgNames}} on {{day}} at {{fromHHMM}}-{{toHHMM}}',
            Students: 'Students',
            StudentsHasTooShortBreakOnDay: 'Students in {{sgName}} have too short break between lectures on {{day}}',
            Subject: 'Subject',
            SubjectChange: 'Subject change',
            SubjectChangeSmall: 'subject change',
            Teacher: 'Teacher',
            Teachers: 'Teachers',
            TeacherDoubleBooked: 'Teacher double booked: {{teacherName}} on {{day}} at {{fromHHMM}}-{{toHHMM}}',
            TeacherFrameTimeExceeded: 'Teacher frame time exceeded: {{teacherSchoolId}} has {{frameTime}}',
            TeachingGroup: 'Course offering',
            TeachingGroups: 'Course offerings',
            TeacherHasNoLunchBreak: 'Teacher has no lunch break: {{teacherSchoolId}} on {{dayName}}',
            TeacherHasTooShortBreak: '{{teacherId}} has too short break between lectures',
            TeacherHasTooShortBreakOnDay: '{{teacherId}} has too short break between lectures on {{day}}',
            TeacherScheduledOnBlockedTime:
                'Teacher scheduled on blocked time: {{teacherSchoolId}} on {{day}} at {{fromHHMM}}-{{toHHMM}}',
            TheCourseOffering: 'the course offering',
            TheGroup: 'the group',
            TheGroups: 'the groups',
            ThisAttributeOnlyApplysToTheFollowingRooms: 'This attribute only applys to the following rooms: ',
            ThisAttributeOnlyApplysToTheFollowingLectures: 'This attribute only applys to the following lessons: ',
            ThisBlockOnlyApplysToTheFollowingLessons: 'This block only applys to the following lessons: ',
            ThisGroupOnlyOverlapsWithTheFollowingGroups: 'This group only overlaps with the following groups: ',
            ThisLabelOnlyApplysToTheFollowingGroups: 'This label only applys to the following groups: ',
            TimePinned: 'Time pinned',
            WeekDay: 'Weekday',
            Yes: 'Yes',
            No: 'No',
            Preferably: 'preferably',
            Problems: 'Problems',
            ProblemTypeLabel: {
                EndTimeProblem: 'Lecture ends too late',
                TeacherConflictProblem: 'Teacher double booked',
                StudentGroupLunchProblem: 'Class has too short lunch break',
                TeacherBreakProblem: 'Teacher has too short break between lectures',
                StudentGroupBreakProblem: 'Students have too short break between lectures',
                RoomConflictProblem: 'Room double booked',
                RoomReservedTimeConflictProblem: 'Room scheduled on blocked time',
                RequiredRoomAttributeProblem: 'Room missing attribute',
                NoRoomSetProblem: 'Lecture missing room',
                UnscheduledLectureProblem: 'Lecture unscheduled',
                ScheduleOverrunProblem: 'Lecture ends too late',
                SameCourseMultipleTimesOnSameDayProblem: 'Same course multiple times on same day',
                SameSubjectMultipleTimesOnSameDayProblem: 'Same subject multiple times on same day',
                StudentGroupConflictProblem: 'Student group double booked',
                TeacherFrameTimeProblem: 'Teacher frame time exceeded',
                TeacherLunchProblem: 'Teacher has too short lunch break',
                TeacherReservedTimeConflictProblem: 'Teacher scheduled on blocked time'
            },
            Qualifications: 'Qualifications',
            TeacherSaved: 'Teacher saved',
            TeachersSaved: 'Teachers saved',
            StudentGroupSaved: 'Student group saved',
            StudentGroupsSaved: 'Student groups saved',
            SelectSingleStudentGroupToEditName: 'Select a single student group to edit the name.',
            SelectSingleStudentGroupToEditDescription: 'Select a single student group to edit the description.',
            LecturesReferencingSelectedRooms: '{{count}} lecture(s) reference the selected rooms',
            RoomRemoved: 'Room removed',
            RemoveRoom: 'Remove room',
            RemoveRooms: 'Remove rooms',
            AddRoom: 'Add room',
            NoRoomAttributes: 'No room attributes',
            ValidFor: 'Valid for:',
            WeekDays: {
                Monday: 'Monday',
                Tuesday: 'Tuesday',
                Wednesday: 'Wednesday',
                Thursday: 'Thursday',
                Friday: 'Friday',
                Saturday: 'Saturday',
                Sunday: 'Sunday'
            },
            WeekDaysShort: {
                Monday: 'Mo',
                Tuesday: 'Tu',
                Wednesday: 'We',
                Thursday: 'Th',
                Friday: 'Fr',
                Saturday: 'Sa',
                Sunday: 'Su'
            },
            WholeSchoolYear: 'Whole school year',
            Weeks: 'Weeks',
            With: '...with',
            W: 'w.',
            Actions: 'Actions',
            AddPeriod: 'Add period',
            AddSubject: 'Add subject',
            AddCourse: 'Add course',
            AddCourseOffering: 'Add course offering',
            AddTeacher: 'Add teacher',
            ClearContent: 'Clear content',
            Edit: 'Edit',
            NewSchedule: 'New schedule',
            NewRows: 'New rows',
            NoSubjects: 'No subjects',
            PeriodName: 'Period name',
            RemoveCourse: 'Remove course',
            RemoveCourses: 'Remove courses',
            RemoveSubject: 'Remove subject',
            RemoveSubjects: 'Remove subjects',
            RemoveStudentGroup: 'Remove student group',
            RemoveStudentGroups: 'Remove student groups',
            RemoveTeacher: 'Remove teacher',
            ResetContent: 'Reset content',
            Search: 'Search',
            SubjectCode: 'Subject code',
            TableEditing: 'Table editing',
            Title: 'Title',
            TransferToAdmin: 'Transfer to Admin',
            TransferToSelectedSchedule: 'Export to selected schedule',
            TransferComplete: 'Transfer complete',
            TransferStatus: {
                Rooms: 'Transfer rooms',
                Teachers: 'Transfer teachers',
                Periods: 'Transfer periods',
                StudentGroups: 'Transfer student groups',
                Courses: 'Transfer courses',
                Lectures: 'Transfer lectures'
            },
            TeacherChanges: {
                NewTeachers: 'New teachers',
                UpdatedTeachers: 'Updated teachers',
                RemovedTeachers: 'Removed teachers',
                NoNewTeachers: 'No new teachers',
                NoUpdatedTeachers: 'No updated teachers',
                NoRemovedTeachers: 'No removed teachers',
                RemovedQualifications: 'Removed qualifications',
                AddedQualifications: 'Added qualifications'
            },
            Errors: {
                BackgroundChanges: 'Background changes',
                DuplicateSignature: 'Duplicate signature',
                ErrorsMustBeFixed: 'Following errors must be fixed',
                ImportFailed: 'Schedule import failed. Please contact Meitner Support.',
                InvalidPercentage: 'Invalid percentage format',
                LecturesReferencing: 'lecture(s) referencing',
                NoSuitableEventType: 'No suitable event type',
                PredefinedPeriodNotFound: {
                    SchoolYear: 'Predefined period not found for school year.',
                    Spring: 'Predefined period not found for spring.',
                    Fall: 'Predefined period not found for fall.'
                },
                ReservedTimesReferencing: 'reserved times referencing period',
                StudentGroupReferencedInDistribution: 'Student group referenced in distribution ({{name}})',
                TeacherReferencedInDistribution: 'Teacher referenced in distribution ({{name}})',
                UnknownSubject: 'Unknown subject:'
            },
            Success: {
                Created: 'created',
                ImportComplete: 'Schema imported.',
                SubjectSaved: 'Subject saved',
                SubjectsSaved: 'Subjects saved',
                SubjectRemoved: 'Subject removed',
                SubjectsRemoved: 'Subjects removed'
            },
            SelectSingleSubject: {
                BreakRules: 'Select a single subject to edit break rules.',
                LectureDurations: 'Select a single subject to edit lecture durations.'
            },
            EntityTypes: {
                Subject: 'subject',
                Schedule: 'schedule'
            },
            DuplicateNamesInSubjectsCourses: 'Duplicate names in subjects/courses:',
            SelectScheduleToTransferTo: 'Select which schedule to transfer the current schedule to:',
            ImportFromRoyalSchedule: 'Import from RoyalSchedule',
            Semesters: 'Semesters',
            'Success.CourseRemoved': 'Course removed',
            'Errors.CourseRoundsReferencing': '{{count}} course round(s) reference the selected courses'
        }
    } as const,
    de: {
        translation: {
            ActualHours: 'Tatsächliche Stunden',
            Add: 'Hinzufügen',
            AddLecture: 'Unterrichtsstunde hinzufügen',
            AddSetting: 'Einstellung hinzufügen',
            AddBreakSetting: 'Pauseneinstellung hinzufügen',
            AddStudentGroup: 'Schülergruppe hinzufügen',
            After: 'nach',
            AfterLecture: 'Nach dem Unterricht',
            All: 'Alle',
            AllowSkipInMatching: 'Übersprünge in der Suche erlauben',
            And: 'und',
            At: 'bei',
            AT: '1. HJ',
            Atleast: 'mindestens',
            Attribute: 'Attribut',
            Automation: 'Automatisierung',
            AWeeks: 'A-Wochen',
            Before: 'vor',
            BeforeLecture: 'Vor der Vorlesung',
            Between: 'zwischen',
            BlockedTime: 'Blockierte Zeit',
            BreakHasToBe: 'Pause ist obligatorisch',
            BreakSettings: 'Pauseneinstellungen',
            BreakShouldPreferablyBe: '...aber vorzugsweise',
            BWeeks: 'B-Wochen',
            Cancel: 'Abbrechen',
            CanteenLoadDuringLunch: 'Auslastung der Schulcafeteria während des Mittagessens',
            CaseSensitive: 'Groß-/Kleinschreibung beachten',
            ChooseLanguage: 'Sprache wählen: ',
            Class: 'Klasse',
            Classes: 'Klassen',
            CourseOfferings: 'Kursangebote',
            Filter: 'Filter',
            HighlightSearchText: 'Suchtext hervorheben',
            Rooms: 'Räume',
            ClassHasTooShortLunch: 'Die Klasse {{Class}} hat keine Mittagspause am {{Day}}',
            Code: 'Code',
            CollapseAll: 'Alle minimieren',
            Condition: 'Bedingung',
            Course: 'Fach',
            Courses: 'Fächer',
            CourseCode: 'Kurskennziffer',
            CourseOffering: 'Unterrichtsangebot',
            CourseOfferingHaveToo: 'Unterrichtsangebot hat zu',
            CourseOfferingHaveTooFewHours: 'Unterrichtsangebot hat zu wenige Stunden: ',
            Create: 'Erstellen',
            DayAndTime: 'Tag und Zeit',
            Description: 'Beschreibung',
            Designation: 'Bezeichnung',
            Development: 'Entwicklung',
            DevelopmentButton: {
                DumpDaysTimes: 'Tage und Zeiten verwerfen',
                RemoveAllPins: 'Alle Markierungen für Tag/Zeit löschen',
                RemoveNonScheduledLectures: 'Nicht geplante Unterrichtsstunden löschen',
                RemoveTimeDay: 'Entfernen Sie Tag/Zeit von allen Unterrichtsstunden',
                RemoveTimeDayNonPinned: 'Entfernen Sie Tag / Zeit von allen nicht markierten Unterrichtsstunden',
                TakeDayTimeOnLecturesOutsideBlock: 'Bitte Tag / Zeit außerhalb eines Unterrichtsblocks wählen'
            },
            Duration: 'Dauer',
            Employment: 'Beschäftigung',
            EvenWeeks: 'Gerade Wochen',
            Except: '...außer',
            ExpandAll: 'Alle erweitern',
            Export: 'Exportieren',
            ExportSemester: 'Zeitperiode exportieren',
            Few: 'wenige',
            FirstName: 'Vorname',
            GroupAfterType: 'Gruppieren nach Typ',
            GroupAfterTeacher: 'Gruppieren nach Lehrer',
            GroupAfterClass: 'Gruppieren nach Klasse',
            HaveToBe: 'auf jeden Fall',
            Hours: 'Stunden',
            HoursSmall: '  stunden',
            HoursInPercentOfGoal: 'Stunden in prozentualer Zielerreichung',
            InRelationToLecture: 'In Bezug auf den Unterricht',
            Labels: 'Markierungen',
            LastName: 'Nachname',
            Lecture: 'Unterrichtsstunde',
            LectureSmall: 'unterrichtsstunde',
            LectureIsToo: 'Unterrichtsstunde ist zu',
            LectureDuration: 'Unterrichtsdauer',
            LectureEndsTooLate: 'Unterrichtsstunde endet zu spät:',
            Lectures: 'Unterrichtsstunden',
            LessonDetails: 'Näheres zur Unterrichtsstunde',
            LessonToo: 'Unterricht zu',
            Long: 'lang',
            MainMenu: {
                CanteenLoad: 'Auslastung der Cafeteria',
                Courses: 'Fächer',
                Education: 'Bildung',
                Rooms: 'Räume',
                Schedule: 'Stundenplan',
                ServiceDistribution: 'Serviceverteilung',
                StudentGroups: 'Schülergruppen',
                Teachers: 'Lehrer',
                TeachingGroups: 'Klassen',
                TeachingHours: 'Unterrichtsstunden',
                Parameters: 'Parameter',
                Periods: 'Perioden',
                Lectures: 'Unterricht',
                Reports: 'Berichte',
                Settings: 'Einstellungen'
            },
            Many: 'viele',
            MarkedBlockedTime: 'Geblockte Zeit markieren',
            Minutes: 'Minuten',
            MinutesLongBut: 'aber',
            Name: 'Name',
            Names: 'Namen',
            NoBreakSettings: 'Keine Pauseneinstellungen',
            NoClass: 'Keine Klasse',
            NoCondition: 'Keine Bedingung',
            NoLectures: 'Keine Unterrichtsstunden',
            NoLecturesMatchFilter: 'Keine Unterrichtsstunden entsprechen den ausgewählten Filtern',
            NoRoom: 'Kein Raum',
            NoRoomSelected: 'Kein Raum ausgewählt für ',
            NoLessonSelected: 'Keine Unterrichtsstunde ausgewählt',
            Non: 'nicht ',
            NonHypen: 'nicht-',
            NonOverlapingStudentGroups: 'Nicht überlappende Schülergruppen',
            NotPartOfBlock: 'Nicht Teil des Blocks',
            NotScheduled: 'Nicht geplant: ',
            Number: 'Nr.',
            OddWeeks: 'Ungerade Wochen',
            Other: 'Andere',
            OverlapingGroup: 'überlappende Gruppe',
            OverlapingStudentGroups: 'Überlappend Schülergruppen',
            OverlapWith: 'überlappen mit',
            Remove: 'entfernen',
            ReservedTime: 'Reservierte Zeit',
            Room: 'Räume',
            RoomChange: 'Raumwechsel',
            RoomChangeSmall: 'raumwechsel',
            RoomDoubleBooked: 'Raum doppelt gebucht: {{roomName}} am {{day}} um {{fromHHMM}}-{{toHHMM}}',
            RoomMissingAttributes: 'Raum fehlt {{missingAttributes}}',
            RoomScheduledOnBlockedTime:
                'Raum geplant zu blockierter Zeit: {{roomName}} am {{day}} um {{fromHHMM}}-{{toHHMM}}',
            SameCourseMultipleTimesOnSameDay: 'Derselbe Kurs mehrmals an einem Tag: ',
            SameSubjectMultipleTimesOnSameDay: 'Derselbe Kurs mehrmals an einem Tag: {{subjectName}} mit {{sgName}}',
            Save: 'Speichern',
            SchoolYear: 'Schuljahr',
            SearchCourse: 'Kurs suchen',
            SearchCourseOffering: 'Nach Unterrichtsstunden suchen',
            SearchRoom: 'Nach Räumen suchen',
            SearchPlaceholders: {
                SearchSchedule: 'Nach Stundenplan suchen',
                SearchStudentGroup: 'Nach Schülergruppen suchen',
                SearchTeacher: 'Nach Lehrer suchen'
            },
            Short: 'kurz',
            ShouldPreferablyBe: 'sollte vorzugsweise sein',
            ShowInheritedSettings: 'Vorab festgelegte Einstellungen anzeigen',
            Signature: 'Kürzel',
            Square: 'Quadrat',
            ST: '2. HJ',
            start: 'Start',
            stop: 'Stopp',
            StudentGroup: 'Schülergruppe',
            StudentGroupsDoubleBooked:
                'Schülergruppen doppelt gebucht: {{sgNames}} am {{day}} um {{fromHHMM}}-{{toHHMM}}',
            Students: 'Schüler',
            StudentsHasTooShortBreakOnDay: 'Schüler in {{sgName}} haben zu kurze Pause zwischen Vorlesungen am {{day}}',
            Subject: 'Fach',
            SubjectChange: 'Fachwechsel',
            SubjectChangeSmall: 'fachwechsel',
            Teacher: 'Lehrer',
            Teachers: 'Lehrer',
            TeacherDoubleBooked: 'Lehrer doppelt gebucht: {{teacherName}} am {{day}} um {{fromHHMM}}-{{toHHMM}}',
            TeacherFrameTimeExceeded: 'Lehrer Rahmenzeit überschritten: {{teacherSchoolId}} hat {{frameTime}}',
            TeachingGroup: 'Klasse',
            TeachingGroups: 'Klassen',
            TeacherHasNoLunchBreak: 'Lehrer hat keine Mittagspause: {{teacherSchoolId}} am {{dayName}}',
            TeacherHasTooShortBreak: '{{teacherId}} hat zu kurze Pause zwischen Unterrichtsstunden',
            TeacherHasTooShortBreakOnDay: '{{teacherId}} hat zu kurze Pause zwischen Unterrichtsstunden am {{day}}',
            TeacherScheduledOnBlockedTime:
                'Lehrer geplant zu blockierter Zeit: {{teacherSchoolId}} am {{day}} um {{fromHHMM}}-{{toHHMM}}',
            TheCourseOffering: 'das Unterrichtsangebot',
            TheGroup: 'die Gruppe',
            TheGroups: 'die Gruppen',
            ThisAttributeOnlyApplysToTheFollowingRooms: 'Dieses Attribut gilt nur für die folgenden Räume: ',
            ThisAttributeOnlyApplysToTheFollowingLectures:
                'Dieses Attribut gilt nur für die folgenden Unterrichtsstunden: ',
            ThisBlockOnlyApplysToTheFollowingLessons: 'Dieser Block gilt nur für die folgenden Unterrichtsstunden: ',
            ThisGroupOnlyOverlapsWithTheFollowingGroups: 'Diese Gruppe überlappt nur mit den folgenden Gruppen: ',
            ThisLabelOnlyApplysToTheFollowingGroups: 'Dieses Label gilt nur für die folgenden Gruppen: ',
            TimePinned: 'Zeit fixiert',
            WeekDay: 'Wochentag',
            Yes: 'Ja',
            No: 'Nein',
            Preferably: 'vorzugsweise',
            Problems: 'Probleme',
            ProblemTypeLabel: {
                EndTimeProblem: 'Vorlesung endet zu spät',
                TeacherConflictProblem: 'Lehrer doppelt gebucht',
                StudentGroupLunchProblem: 'Klasse hat zu kurze Mittagspause',
                TeacherBreakProblem: 'Lehrer hat zu kurze Pause zwischen Unterrichtsstunden',
                StudentGroupBreakProblem: 'Schüler haben zu kurze Pause zwischen Unterrichtsstunden',
                RoomConflictProblem: 'Raum doppelt gebucht',
                RoomReservedTimeConflictProblem: 'Raum geplant zu blockierter Zeit',
                RequiredRoomAttributeProblem: 'Raum fehlt Attribut',
                NoRoomSetProblem: 'Unterricht ohne Raum',
                UnscheduledLectureProblem: 'Unterricht nicht geplant',
                ScheduleOverrunProblem: 'Unterricht endet zu spät',
                SameCourseMultipleTimesOnSameDayProblem: 'Gleicher Kurs mehrmals am selben Tag',
                SameSubjectMultipleTimesOnSameDayProblem: 'Gleiches Fach mehrmals am selben Tag',
                StudentGroupConflictProblem: 'Schülergruppe doppelt gebucht',
                TeacherFrameTimeProblem: 'Lehrer Rahmenzeit überschritten',
                TeacherLunchProblem: 'Lehrer hat zu kurze Mittagspause',
                TeacherReservedTimeConflictProblem: 'Lehrer geplant zu blockierter Zeit'
            },
            Qualifications: 'Qualifikationen',
            TeacherSaved: 'Lehrer gespeichert',
            TeachersSaved: 'Lehrer gespeichert',
            StudentGroupSaved: 'Schülergruppe gespeichert',
            StudentGroupsSaved: 'Schülergruppen gespeichert',
            SelectSingleStudentGroupToEditName:
                'Wählen Sie eine einzelne Schülergruppe aus, um den Namen zu bearbeiten.',
            SelectSingleStudentGroupToEditDescription:
                'Wählen Sie eine einzelne Schülergruppe aus, um die Beschreibung zu bearbeiten.',
            LecturesReferencingSelectedRooms: '{{count}} Unterricht(e) referenzieren die ausgewählten Räume',
            RoomRemoved: 'Raum entfernt',
            RemoveRoom: 'Raum entfernen',
            RemoveRooms: 'Räume entfernen',
            AddRoom: 'Raum hinzufügen',
            NoRoomAttributes: 'Keine Raumattribute',
            ValidFor: 'Gültig für:',
            WeekDays: {
                Monday: 'Montag',
                Tuesday: 'Dienstag',
                Wednesday: 'Mittwoch',
                Thursday: 'Donnerstag',
                Friday: 'Freitag',
                Saturday: 'Samstag',
                Sunday: 'Sonntag'
            },
            WeekDaysShort: {
                Monday: 'Mo',
                Tuesday: 'Di',
                Wednesday: 'Mi',
                Thursday: 'Do',
                Friday: 'Fr',
                Saturday: 'Sa',
                Sunday: 'So'
            },
            WholeSchoolYear: 'Das ganze Schuljahr',
            Weeks: 'Wochen',
            With: '...inklusive',
            W: 'w.',
            Actions: 'Aktionen',
            AddPeriod: 'Zeitraum hinzufügen',
            AddSubject: 'Fach hinzufügen',
            AddCourse: 'Kurs hinzufügen',
            AddCourseOffering: 'Kursangebot hinzufügen',
            AddTeacher: 'Lehrer hinzufügen',
            ClearContent: 'Inhalt löschen',
            Edit: 'Bearbeiten',
            NewSchedule: 'Neuer Stundenplan',
            NewRows: 'Neue Zeilen',
            NoSubjects: 'Keine Fächer',
            PeriodName: 'Name des Zeitraums',
            RemoveCourse: 'Kurs entfernen',
            RemoveCourses: 'Kurse entfernen',
            RemoveSubject: 'Fach entfernen',
            RemoveSubjects: 'Fächer entfernen',
            RemoveStudentGroup: 'Schülergruppe entfernen',
            RemoveStudentGroups: 'Schülergruppen entfernen',
            RemoveTeacher: 'Lehrer entfernen',
            ResetContent: 'Inhalt zurücksetzen',
            Search: 'Suche',
            SubjectCode: 'Fachkennziffer',
            TableEditing: 'Tabellenbearbeitung',
            Title: 'Titel',
            TransferToAdmin: 'An Admin übertragen',
            TransferToSelectedSchedule: 'An ausgewählten Stundenplan exportieren',
            TransferComplete: 'Übertragung abgeschlossen',
            TransferStatus: {
                Rooms: 'Räume übertragen',
                Teachers: 'Lehrer übertragen',
                Periods: 'Zeiträume übertragen',
                StudentGroups: 'Schülergruppen übertragen',
                Courses: 'Kurse übertragen',
                Lectures: 'Lektionen übertragen'
            },
            TeacherChanges: {
                NewTeachers: 'Neue Lehrer',
                UpdatedTeachers: 'Aktualisierte Lehrer',
                RemovedTeachers: 'Entfernte Lehrer',
                NoNewTeachers: 'Keine neuen Lehrer',
                NoUpdatedTeachers: 'Keine aktualisierten Lehrer',
                NoRemovedTeachers: 'Keine entfernten Lehrer',
                RemovedQualifications: 'Entfernte Qualifikationen',
                AddedQualifications: 'Hinzugefügte Qualifikationen'
            },
            Errors: {
                BackgroundChanges: 'Hintergrundänderungen',
                DuplicateSignature: 'Signatur kommt auch auf der Zeile vor',
                ErrorsMustBeFixed: 'Folgende Fehler müssen behoben werden',
                ImportFailed: 'Schema-Import fehlgeschlagen. Bitte wenden Sie sich an den Meitner-Support.',
                InvalidPercentage: 'Ungültiges Prozentformat',
                LecturesReferencing: 'lektion(er) referenzieren',
                NoSuitableEventType: 'Keine passende Ereignisart gefunden',
                PredefinedPeriodNotFound: {
                    SchoolYear: 'Vordefiniertes Zeitraum für Schuljahr nicht gefunden.',
                    Spring: 'Vordefiniertes Zeitraum für Frühjahr nicht gefunden.',
                    Fall: 'Vordefiniertes Zeitraum für Herbst nicht gefunden.'
                },
                ReservedTimesReferencing: 'reservierte Zeiten referenzieren Zeitraum',
                StudentGroupReferencedInDistribution:
                    'Studentengruppe, auf die in der Verteilung verwiesen wird ({{name}})',
                TeacherReferencedInDistribution: 'Teacher referenced in distribution ({{name}})',
                UnknownSubject: 'Unbekanntes Fach:'
            },
            Success: {
                Created: 'erstellt',
                ImportComplete: 'Schema importiert.',
                SubjectSaved: 'Fach gespeichert',
                SubjectsSaved: 'Fächer gespeichert',
                SubjectRemoved: 'Fach entfernt',
                SubjectsRemoved: 'Fächer entfernt'
            },
            SelectSingleSubject: {
                BreakRules: 'Wählen Sie ein einzelnes Fach aus, um Rastregeln zu ändern.',
                LectureDurations: 'Wählen Sie ein einzelnes Fach aus, um Lektionslängder zu ändern.'
            },
            EntityTypes: {
                Subject: 'Fach',
                Schedule: 'Stundenplan'
            },
            DuplicateNamesInSubjectsCourses: 'Doppelte Namen in Fächern/Kursen:',
            SelectScheduleToTransferTo:
                'Wählen Sie aus, in welchen Stundenplan der aktuelle Stundenplan übertragen werden soll:',
            ImportFromRoyalSchedule: 'Import von RoyalSchedule',
            Semesters: 'Feiertage',
            'Success.CourseRemoved': 'Kurs entfernt',
            'Errors.CourseRoundsReferencing': '{{count}} Kursrunde(n) verweisen auf die ausgewählten Kurse'
        }
    } as const
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        lng: 'sv',
        fallbackLng: 'sv',
        defaultNS,
        ns: [defaultNS],
        interpolation: {
            escapeValue: false
        }
    })
