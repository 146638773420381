import type { AutoSchedulerAction } from './actions'
import { AutoSchedulerActionTypes, AutoSchedulerRunningState, AutoSchedulerState } from './types'

const initialState: AutoSchedulerState = {
    runState: AutoSchedulerRunningState.UNKNOWN,
    taRunState: AutoSchedulerRunningState.UNKNOWN,
    subscriptionCount: 0
}

export const autoSchedulerReducer = (state = initialState, action: AutoSchedulerAction): AutoSchedulerState => {
    switch (action.type) {
        case AutoSchedulerActionTypes.SET_AUTO_SCHEDULER_STATE: {
            return {
                ...state,
                runState: action.payload.isRunning,
                taRunState: action.payload.isTaRunning
            }
        }
        case AutoSchedulerActionTypes.SUBSCRIBE: {
            const newState = {
                ...state,
                subscriptionCount: state.subscriptionCount + 1
            }
            console.log(
                `[sse autoscheduler reducer] action = SUBSCRIBE. new subscriptionCount: ${  newState.subscriptionCount}`
            )

            return newState
        }
        case AutoSchedulerActionTypes.UNSUBSCRIBE: {
            const newState = {
                ...state,
                subscriptionCount: state.subscriptionCount - 1
            }
            console.log(
                `[sse autoscheduler reducer] action = UNSUBSCRIBE. subscriptionCount: ${  newState.subscriptionCount}`
            )

            return newState
        }
        default: {
            return state
        }
    }
}
