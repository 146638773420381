import type { IEventGroup, ILecture } from 'common-api'
import type { IScheduleServiceLesson } from 'meitner-api'
import { undefinedToNull } from '../../components/event-groups/utils'
import type { LectureAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { Endpoints } from '../../services/Endpoints'
import { formatHHMM } from '../../utils/DayAndTimeUtil'
import { courseRoundToCourseRoundWithDependencies, type CourseRoundWithDependencies, idForCourseRound } from './courses'
import { SrcToDstIdMap } from './utils/SrcToDstIdMap'

export type LectureWithDependencies = {
    lecture: ILecture
    eventGroup: IEventGroup
    courseRoundWithDependencies: CourseRoundWithDependencies
}

export const lectureToLectureWithDependencies = (lecture: LectureAccessor) => ({
    lecture: lecture.getConjureLecture(),
    eventGroup: lecture.getEventGroup().getConjureObject(),
    courseRoundWithDependencies: courseRoundToCourseRoundWithDependencies(lecture.getCourseRound())
})

export const listDstLessons = async (targetScheduleId: string) =>
    Endpoints.meitnerApi
        .scheduleServiceListLessons({
            schedule_id: targetScheduleId,
            pagination: { page: 0, size: 0 }
        })
        .then((response) => response.schedule_lessons)

const listOfRoomIds = (lecture: ILecture, roomIdsMap: SrcToDstIdMap): string[] => {
    if (lecture.roomAssignments === undefined || lecture.roomAssignments === null) {
        return []
    }

    return lecture.roomAssignments
        .map((ra) => ra.roomId)
        .filter((rid) => rid !== undefined && rid !== null)
        .map((rid) => roomIdsMap.getDstId(rid!))
}

const weekNumbersToBitsInNumber = (weeks: number[]) => {
    let result = BigInt(0)
    for (let week of weeks) {
        result |= BigInt(1) << BigInt(week - 1)
    }
    return Number(result)
}

// Translate fields common to both create and update requests
const translateCommonFields = (
    roomIdsMap: SrcToDstIdMap,
    groupIdsMap: SrcToDstIdMap,
    periodIdsMap: SrcToDstIdMap,
    courseIdsMap: SrcToDstIdMap,
    teacherIdsMap: SrcToDstIdMap,
    { lecture, eventGroup, courseRoundWithDependencies }: LectureWithDependencies
) => ({
    title:
        courseRoundWithDependencies.course !== undefined
            ? courseRoundWithDependencies.course.name
            : courseRoundWithDependencies.subject.name,
    external_id: lecture.lectureId,
    day: undefinedToNull(eventGroup.dayAndTime?.day.toLowerCase()),
    length: lecture.durationInMinutes,
    start_time: formatHHMM(eventGroup.dayAndTime || { hour: 0, minute: 0 }),
    course_id: courseIdsMap.getDstId(idForCourseRound(courseRoundWithDependencies)),
    exclude_period_ids: [],
    //period_weeks: 0,
    including_weeks: weekNumbersToBitsInNumber(lecture.weekSelection.includes),
    excluding_weeks: weekNumbersToBitsInNumber(lecture.weekSelection.excludes),
    group_ids: [groupIdsMap.getDstId(courseRoundWithDependencies.courseRound.studentGroupId)],
    room_ids: listOfRoomIds(lecture, roomIdsMap),
    period_ids: [periodIdsMap.getDstId(lecture.weekSelection.weekSelectionPresetId)],
    teacher_ids: (lecture.teacherIds ?? courseRoundWithDependencies.courseRound.teacherIds).map((tid) =>
        teacherIdsMap.getDstId(tid)
    )
})

export const createDstLesson =
    (
        roomIdsMap: SrcToDstIdMap,
        groupIdsMap: SrcToDstIdMap,
        periodIdsMap: SrcToDstIdMap,
        courseIdsMap: SrcToDstIdMap,
        teacherIdsMap: SrcToDstIdMap
    ) =>
    async (targetScheduleId: string, srcLectureWithDeps: LectureWithDependencies) =>
        Endpoints.meitnerApi
            .scheduleServiceCreateLesson({
                ...translateCommonFields(
                    roomIdsMap,
                    groupIdsMap,
                    periodIdsMap,
                    courseIdsMap,
                    teacherIdsMap,
                    srcLectureWithDeps
                ),
                schedule_id: targetScheduleId
            })
            .then((response) => response.created.id!)

export const deleteDstLesson = async (lessonId: string) =>
    Endpoints.meitnerApi.scheduleServiceDeleteLesson({
        id: lessonId
    })

export const updateDstLesson =
    (
        roomIdsMap: SrcToDstIdMap,
        groupIdsMap: SrcToDstIdMap,
        periodIdsMap: SrcToDstIdMap,
        courseIdsMap: SrcToDstIdMap,
        teacherIdsMap: SrcToDstIdMap
    ) =>
    async (srcLecture: LectureWithDependencies, dstLesson: IScheduleServiceLesson) =>
        Endpoints.meitnerApi
            .scheduleServiceUpdateLesson({
                ...dstLesson,
                ...translateCommonFields(roomIdsMap, groupIdsMap, periodIdsMap, courseIdsMap, teacherIdsMap, srcLecture)
            })
            .then((response) => response.updated.id!)
