import { ISubject, ITeacher } from 'common-api'
import { v4 as uuid } from 'uuid'
import { splitAndCleanCommaSepList } from '../../../../../../textUtil'
import { opt } from '../../../../../../utils/miscUtil'
import { TeacherGridContentColumn, TeacherGridMetaDataColumn, TeacherGridRow } from './types'
import { isNonEmptyTeacherRow, parsePercentageStringToFraction } from './util'
import { matchRowsWithExistingData } from '../../../../../../EditTable/data'

const matchScore = (row: TeacherGridRow, teacher: ITeacher) =>
    (teacher.teacherSchoolId === row[TeacherGridContentColumn.Signature] ? 1 : 0) +
    (teacher.firstName === row[TeacherGridContentColumn.FirstName] ? 1 : 0) +
    (teacher.lastName === row[TeacherGridContentColumn.LastName] ? 1 : 0)

export const parseCommaSepSubjectList = (
    existingSubjects: ISubject[],
    commaSepSubjectNames: string
): [ISubject[], string[]] => {
    const subjectNames = splitAndCleanCommaSepList(commaSepSubjectNames)
    const foundSubjects = []
    const notFoundSubjectNames = []
    for (const subjectName of subjectNames) {
        const existingSubject = existingSubjects.find((s) => s.name.toLowerCase() == subjectName.toLowerCase())
        if (existingSubject !== undefined) {
            foundSubjects.push(existingSubject)
        } else {
            notFoundSubjectNames.push(subjectName)
        }
    }

    return [foundSubjects, notFoundSubjectNames]
}

export const parseTeacherRows = (
    existingTeachers: ITeacher[],
    existingSubjects: ISubject[],
    rowsIncludingEmpty: TeacherGridRow[]
): ITeacher[] => {
    const rows = rowsIncludingEmpty.filter(isNonEmptyTeacherRow)
    const matchedTeachers = matchRowsWithExistingData<ITeacher, TeacherGridRow>({
        existingData: existingTeachers,
        rows,
        matchRow: TeacherGridMetaDataColumn.RowIndex,
        matchScore: (row, teacher) => matchScore(row, teacher)
    })

    return rows.map((row) => {
        const matchingTeacher = matchedTeachers.get(row[TeacherGridMetaDataColumn.RowIndex])

        // Id
        const teacherId = opt(matchingTeacher)
            .map((t) => t.teacherId)
            .orElseGet(uuid)

        // Teacher school id
        const teacherSchoolId = row[TeacherGridContentColumn.Signature]

        // First name
        const firstName = row[TeacherGridContentColumn.FirstName]

        // Last name
        const lastName = row[TeacherGridContentColumn.LastName]

        // Qualifications
        const [subjects] = parseCommaSepSubjectList(existingSubjects, row[TeacherGridContentColumn.Qualifications])
        const qualifications = subjects.map((s) => s.subjectId)

        // Work percentage
        let workPercentageAsFraction = parsePercentageStringToFraction(row[TeacherGridContentColumn.WorkPercentage])
        if (Number.isNaN(workPercentageAsFraction)) {
            workPercentageAsFraction = 1
        }

        const workPercentage = Math.round(workPercentageAsFraction * 100)

        return {
            teacherId,
            teacherSchoolId,
            firstName,
            lastName,
            qualifications,
            workPercentage
        }
    })
}
