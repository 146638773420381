import { Table } from 'antd'
import { TeacherId } from '../../../../../commonTypes'
import { TeacherRow } from '../types'
import classes from './style.module.css'
import { TeacherViewTableProps } from './types'

export const TeacherViewTable = (props: TeacherViewTableProps) => (
    <Table<TeacherRow>
        className={classes.cardedTable}
        rowSelection={{
            selectedRowKeys: props.selectedRowKeys,
            onChange: (newSelectedRowKeys) => props.setSelectedRowKeys(newSelectedRowKeys as TeacherId[])
        }}
        dataSource={props.dataSource}
        showSorterTooltip={false}
        columns={props.columns}
        size="small"
        rowKey={({ teacher }) => teacher.getTeacherId()}
        sticky
        onRow={({ teacher }) => ({
            onClick: () => props.setSelectedRowKeys([teacher.getTeacherId()])
        })}
        pagination={false}
    />
)
