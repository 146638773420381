import { Form, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import type { ILectureDurationThresholds, ILunchBreakSettings, IMinBreakThresholds, ScheduleType } from 'common-api'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { ScheduleAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../store/schedule/actions'
import { useLocalSchedule } from '../../store/schedule/hooks'
import Button from '../Button'
import { MinBreakThresholdsInput } from '../course-rounds/MinBreakThresholdsInput'
import { LectureDurationThresholdsInput } from '../LectureDurationThresholdsInput'
import { LunchBreakSettingsInput } from './LunchBreakSettingsInput'
import { OpeningHoursInput } from './OpeningHoursInput'
import type { OpeningHours } from './OpeningHoursInput/types'
import { ScheduleTypeInput } from './ScheduleTypeInput'

type ScheduleSettingsFormValues = {
    scheduleType: ScheduleType
    openingHours: OpeningHours
    lunchBreakSettings: ILunchBreakSettings
    minBreakThresholds: IMinBreakThresholds
    lectureDurationThresholds: ILectureDurationThresholds
}

const formValuesFromSchedule = (schedule: ScheduleAccessor): ScheduleSettingsFormValues => ({
    scheduleType: schedule.getSettings().scheduleType!,
    openingHours: {
        startTime: schedule.getSettings().startOfDay!,
        endTime: schedule.getSettings().endOfDay!
    },
    lunchBreakSettings: schedule.getSettings().lunchBreakSettings!,
    minBreakThresholds: schedule.getSettings().minBreakThresholds,
    lectureDurationThresholds: schedule.getSettings().lectureDurationThresholds
})

export const ScheduleSettingsPanel = () => {
    const [form] = useForm()
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const saveScheduleSettings = (formValues: ScheduleSettingsFormValues) => {
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.scheduleSettingsTransform({
                    newSettings: {
                        ...schedule.getSettings(),
                        scheduleType: formValues.scheduleType,
                        startOfDay: formValues.openingHours.startTime,
                        endOfDay: formValues.openingHours.endTime,
                        lunchBreakSettings: formValues.lunchBreakSettings,
                        minBreakThresholds: formValues.minBreakThresholds,
                        lectureDurationThresholds: formValues.lectureDurationThresholds
                    }
                })
            )
        )
        message.success(t('MainMenu.SettingsSaved'))
    }

    return (
        <Form
            form={form}
            name="scheduleSettingsForm"
            initialValues={formValuesFromSchedule(schedule)}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: '700px', width: '100%' }}
            onFinish={saveScheduleSettings}
            labelWrap
            colon={false}
        >
            <Form.Item name="scheduleType" label={t('MainMenu.SchoolType')}>
                <ScheduleTypeInput />
            </Form.Item>
            <Form.Item name="openingHours" label={t('MainMenu.OpeningHours')}>
                <OpeningHoursInput />
            </Form.Item>
            <Form.Item name="minBreakThresholds" label={t('BreakSettings')}>
                <MinBreakThresholdsInput collapseEmptyToUndefined={false} />
            </Form.Item>
            <Form.Item name="lunchBreakSettings" label={t('MainMenu.LunchRules')}>
                <LunchBreakSettingsInput />
            </Form.Item>
            <Form.Item name="lectureDurationThresholds" label={t('LectureDuration')}>
                <LectureDurationThresholdsInput />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6 }}>
                <Button variant="primary" type="submit">
                    {t('Save')}
                </Button>
            </Form.Item>
        </Form>
    )
}
