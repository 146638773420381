import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'
import { FilterHighlight } from '../../components/dev-util/FilterHighlight'
import { CourseFilterDropdown } from '../../components/filter-dropdowns/CourseFilterDropdown'
import { CourseRoundFilterDropdown } from '../../components/filter-dropdowns/CourseRoundFilterDropdown'
import { DayOfWeekFilterDropdown } from '../../components/filter-dropdowns/DayOfWeekFilterDropdown'
import { LectureLabelFilterDropdown } from '../../components/filter-dropdowns/LectureLabelFilterDropdown'
import { LectureNumFilterDropdown } from '../../components/filter-dropdowns/LectureNumFilterDropdown'
import { RoomFilterDropdown } from '../../components/filter-dropdowns/RoomFilterDropdown'
import { SubjectFilterDropdown } from '../../components/filter-dropdowns/SubjectFilterDropdown'
import { TimeslotPinnedFilterDropdown } from '../../components/filter-dropdowns/TimeslotPinnedFilterDropdown'
import { WeekFilterDropdown } from '../../components/filter-dropdowns/WeekFilterDropdown'
import type { FilterableColumn } from '../../components/table-support/types'
import {
    deriveRenderPropertyFromFilterTexts,
    deriveSorterPropertyFromFilterTexts,
    hideNonSelectedColumns
} from '../../components/table-support/util'
import { comparing } from '../../utils/compareUtil'
import { useNameOfDay, weekSelectionDescription } from '../../utils/weekUtil'
import type { FilteringState } from '../schedule/components/FilterInput/types'
import type { LectureRow } from './components/LecturesTable/types'

export enum LecturesColumnKey {
    CourseRound,
    LectureNum,
    Course,
    Subject,
    Room,
    Labels,
    TimeslotPinned,
    Weekday,
    Weeks
}

const useLectureColumns = (filtering: FilteringState) => {
    const [visibleColumns, setVisibleColumns] = useLocalStorage('visibleLectureColumns', [
        LecturesColumnKey.CourseRound,
        LecturesColumnKey.LectureNum,
        LecturesColumnKey.Course,
        LecturesColumnKey.Subject,
        LecturesColumnKey.Room
    ])
    const { t } = useTranslation()
    const { getDayName } = useNameOfDay()

    const columns: FilterableColumn<LectureRow>[] = [
        {
            title: t('CourseOffering'),
            key: LecturesColumnKey.CourseRound,
            filterDropdown: CourseRoundFilterDropdown,
            onFilter: (crId, { lecture }) => lecture.getCourseRound().getCourseRoundId() === crId,
            filterTexts: ({ lecture }) => [lecture.getCourseRound().getDisplayName()]
        },
        {
            title: t('Number'),
            key: LecturesColumnKey.LectureNum,
            sorter: comparing(({ lecture }) => lecture.getLectureNum()),
            filterDropdown: LectureNumFilterDropdown,
            onFilter: (ln, { lecture }) => `${lecture.getLectureNum()}` === ln,
            render: (_, { lecture }) =>
                `${lecture.getLectureNum()} av ${lecture.getCourseRound().getLectures().length}`,
            width: '7em'
        },
        {
            title: t('Course'),
            key: LecturesColumnKey.Course,
            filterDropdown: CourseFilterDropdown,
            onFilter: (cId, { lecture }) => lecture.getCourseRound().getCourse()?.getCourseId() === cId,
            filterTexts: ({ lecture }) => [lecture.getCourseRound().getCourse()?.getCode() || '']
        },
        {
            title: t('Subject'),
            key: LecturesColumnKey.Subject,
            filterDropdown: SubjectFilterDropdown,
            onFilter: (subjectId, { lecture }) => lecture.getCourseRound().getSubject().getSubjectId() === subjectId,
            filterTexts: ({ lecture }) => [lecture.getCourseRound().getSubject().getCode()]
        },
        {
            title: t('Room'),
            key: LecturesColumnKey.Room,
            filterDropdown: RoomFilterDropdown,
            onFilter: (roomId, { lecture }) => lecture.getAssignedRooms().some((r) => r.getRoomId() === roomId),
            filterTexts: ({ lecture }) => lecture.getAssignedRooms().map((r) => r.getName())
        },
        {
            title: t('Labels'),
            key: LecturesColumnKey.Labels,
            onFilter: (label, { lecture }) => lecture.getLabels().includes(label as string),
            filterDropdown: LectureLabelFilterDropdown,
            render: (_, { lecture }) =>
                lecture.getLabels().map((label) => (
                    <Tag key={label}>
                        <FilterHighlight text={label} filtering={filtering} />
                    </Tag>
                )),
            filterTexts: ({ lecture }) => lecture.getLabels()
        },
        {
            title: t('TimePinned'),
            key: LecturesColumnKey.TimeslotPinned,
            sorter: comparing(({ lecture }) => lecture.getEventGroup().isTimeslotPinned()),
            onFilter: (isTimeslotPinned, { lecture }) =>
                `${lecture.getEventGroup().isTimeslotPinned()}` === isTimeslotPinned,
            filterDropdown: TimeslotPinnedFilterDropdown,
            render: (_, { lecture }) => (lecture.getEventGroup().isTimeslotPinned() ? t('Yes') : t('No')),
            width: '8em'
        },
        {
            title: t('WeekDay'),
            key: LecturesColumnKey.Weekday,
            onFilter: (dayOfWeekOrUnscheduled, { lecture }) => {
                const dayAndTime = lecture.getEventGroup().getDayAndTime()

                return dayAndTime === undefined
                    ? dayOfWeekOrUnscheduled === 'unscheduled'
                    : dayAndTime.day === dayOfWeekOrUnscheduled
            },
            filterDropdown: DayOfWeekFilterDropdown,
            filterTexts: ({ lecture }) => {
                const dayAndTime = lecture.getEventGroup().getDayAndTime()

                return dayAndTime === undefined ? [] : [getDayName(dayAndTime.day)]
            }
        },
        {
            title: t('Weeks'),
            key: LecturesColumnKey.Weeks,
            onFilter: (weekNumStr, { lecture }) =>
                lecture
                    .getWeekSelection()
                    .getWeeks()
                    .map((w) => `${w.valueOf()}`)
                    .includes(weekNumStr as string),
            filterDropdown: WeekFilterDropdown,
            filterTexts: ({ lecture }) => [weekSelectionDescription(lecture.getWeekSelection())]
        }
    ]

    hideNonSelectedColumns(columns, visibleColumns)
    deriveRenderPropertyFromFilterTexts(filtering, columns)
    deriveSorterPropertyFromFilterTexts(columns)

    return { columns, setVisibleColumns }
}

export { useLectureColumns }
