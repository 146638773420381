import { useTranslation } from 'react-i18next'
import type { CourseRoundId, TeacherId } from '../../commonTypes'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { orThrow } from '../../utils/collections'
import { ManyToManySelector } from '../many-to-many-selector/ManyToManySelector'
import { CourseRoundListTooltip } from './CourseRoundListTooltip'

type ManyToManyTeacherSelectorProps = {
    value?: Map<CourseRoundId, TeacherId[]>
    onChange?: (newValue: Map<CourseRoundId, TeacherId[]>) => void
}

export const CourseRoundTeacherSelector = ({ value, onChange }: ManyToManyTeacherSelectorProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const labelsByTeacherId = new Map(schedule.getTeachers().map((t) => [t.getTeacherId(), t.getTeacherSchoolId()]))

    return (
        <ManyToManySelector<CourseRoundId, TeacherId>
            value={value}
            mode="multiple"
            onChange={onChange}
            preexistingOptionValues={schedule.getTeachers().map((t) => t.getTeacherId())}
            optionLabelFn={(tid) => orThrow(labelsByTeacherId.get(tid))}
            partialCoverSuffix={(courseRoundIds) => (
                <CourseRoundListTooltip
                    title={t('CourseRound.TeacherOnlyForTheseOfferings')}
                    courseRounds={courseRoundIds.map((crId) => schedule.findCourseRound(crId))}
                />
            )}
        />
    )
}
