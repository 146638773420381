import { Button, Flex, Select } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddMoreRowsPanelProps } from './types'

export const AddMoreRowsPanel = ({ onAddMoreRows }: AddMoreRowsPanelProps) => {
    const options = [10, 20, 30, 40, 50]
    const [selectedNumberOfRows, setSelectedNumberOfRows] = useState(options[0])
    const { t } = useTranslation()

    return (
        <Flex justify="center" gap={8} align="center" style={{ height: '4.5em' }}>
            <span>{t('NewRows')}</span>
            <Select
                size="small"
                value={selectedNumberOfRows}
                onChange={setSelectedNumberOfRows}
                options={options.map((numRowsOption) => ({ value: numRowsOption }))}
            />
            <Button
                size="small"
                onClick={() => {
                    onAddMoreRows(selectedNumberOfRows)
                }}
            >
                {t('Add')}
            </Button>
        </Flex>
    )
}
