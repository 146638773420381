import { useTranslation } from 'react-i18next'
import { EditModeDropdown } from '../../EditModeDropdown'
import type { IncludeExcludeMenuButtonProps } from './types'

export const IncludeExcludeMenuButton = ({ onChange, value }: IncludeExcludeMenuButtonProps) => {
    const { t } = useTranslation()

    const items = [
        {
            label: t('WithSpecificWeeks'),
            isSelected: value.includesVisible,
            onClick: () => onChange({ ...value, includesVisible: !value?.includesVisible })
        },
        {
            label: t('ExceptSpecificWeeks'),
            isSelected: value.excludesVisible,
            onClick: () => onChange({ ...value, excludesVisible: !value?.excludesVisible })
        }
    ]

    return <EditModeDropdown isEditActive={false} items={items} />
}
