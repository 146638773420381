import { ITeacher } from 'common-api'
import { TeacherGridContentColumn, TeacherGridMetaDataColumn, TeacherGridRow } from './types'
import { range } from 'lodash'

// If the input ends with '%', treat the number as a percentage
// Otherwise, if the number is in range [0, 1], treat it as a fraction
// Otherwise, treat the number as a percentage without a % symbol.
export const parsePercentageStringToFraction = (str: string): number => {
    // Treat both ',' and '.' as decimal separators.
    str = str.replace(',', '.')

    str = str.trim()

    // Explicit percentage value?
    const explicitPercentageValue = str.endsWith('%')
    if (explicitPercentageValue) {
        // Drop percentage symbol
        str = str.slice(0, -1).trim()
    }

    const parsedNumber = parseFloat(str)

    if (parsedNumber < 0) {
        return Number.NaN
    }

    const treatAsPercentage = explicitPercentageValue || parsedNumber > 1

    return treatAsPercentage ? parsedNumber / 100 : parsedNumber
}

export const formatFractionAsPercent = (fraction: number) => `${Math.round(fraction * 100)} %`

export const isWellformedPercent = (str: string) => !Number.isNaN(parsePercentageStringToFraction(str))

export const isNonEmptyTeacherRow = (teacherRow: TeacherGridRow) => !isEmptyTeacherRow(teacherRow)

export const isEmptyTeacherRow = (teacherRow: TeacherGridRow) =>
    [
        TeacherGridContentColumn.Signature,
        TeacherGridContentColumn.FirstName,
        TeacherGridContentColumn.LastName,
        TeacherGridContentColumn.Qualifications,
        TeacherGridContentColumn.WorkPercentage
    ].every((col) => teacherRow[col].trim() === '')

export const formatPercentInput = (percentInput: string) => {
    const newPercentage = parsePercentageStringToFraction(percentInput)

    return Number.isNaN(newPercentage) ? percentInput : formatFractionAsPercent(newPercentage)
}

export const generateEmptyTeacherRows = (fromRowIndex: number, toRowIndex: number) =>
    range(fromRowIndex, toRowIndex).map((rowIndex) => generateEmptyTeacherRow(rowIndex))

export function generateEmptyTeacherRow(rowIndex: number, workPercentageString = ''): TeacherGridRow {
    return {
        [TeacherGridMetaDataColumn.RowIndex]: rowIndex,
        [TeacherGridContentColumn.Signature]: '',
        [TeacherGridContentColumn.FirstName]: '',
        [TeacherGridContentColumn.LastName]: '',
        [TeacherGridContentColumn.Qualifications]: '',
        [TeacherGridContentColumn.WorkPercentage]: workPercentageString
    }
}

export const teacherDisplayName = ({ firstName, lastName, teacherSchoolId }: ITeacher) => {
    let result = `${firstName} ${lastName}`.trim()
    if (teacherSchoolId !== '') {
        result = result === '' ? teacherSchoolId : `${result} (${teacherSchoolId})`
    }

    return result
}

export const shortTeacherDisplayName = ({ firstName, lastName, teacherSchoolId }: ITeacher) =>
    teacherSchoolId !== '' ? teacherSchoolId : [firstName, lastName].join(' ')
