import styled from '../../utils/styled'
import { FullHeightCollapse } from '../FullHeightCollapse'
import { TablePageProps } from './types'
import { useCallback, useEffect } from 'react'

const TablePage = <R,>({ onEscape, selectedRowKeys, sidePanels, table }: TablePageProps<R>) => {
    const showSidePanel = selectedRowKeys.length > 0

    const escFunction = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onEscape()
            }
        },
        [onEscape]
    )

    useEffect(() => {
        document.addEventListener('keydown', escFunction)

        return () => {
            document.removeEventListener('keydown', escFunction)
        }
    }, [escFunction])

    const items = sidePanels.map(({ key, header, panel }) => ({
        key,
        label: header,
        children: panel
    }))

    return (
        <PageContentArticle>
            <LeftSide fullPage={!showSidePanel}>{table}</LeftSide>
            {showSidePanel && (
                <RightSide isExpanded={showSidePanel}>
                    <FullHeightCollapse defaultActiveKey={['edit', 'actions']} bordered={false} items={items} />
                </RightSide>
            )}
        </PageContentArticle>
    )
}

const LeftSide = styled.div<{ fullPage: boolean }>`
    transition-duration: 0.2s;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding: 20px;
    min-width: calc(100% - 600px);
    width: ${(props) => (props.fullPage ? '100%' : '50%')};
`

const RightSide = styled.div<{ isExpanded: boolean }>`
    transition-duration: 0.2s;
    display: ${(props) => (props.isExpanded ? 'block' : 'none')};
    width: ${(props) => (props.isExpanded ? '50%' : '0%')};
    max-width: 600px;
    border-left: thin solid #d9d9d9;
    background-color: #fff;
`

const PageContentArticle = styled('article')`
    display: flex;
    height: 100vh;
`

export default TablePage
