import { useTranslation } from 'react-i18next'
import { teacherDisplayName } from '../../../../utils/scheduleUtils'
import { EntityListTooltip } from '../../../MultiRoomAttributeSelector/RoomsListTooltip/EntityListTooltip'
import { TeacherListTooltipProps } from './types'

export const TeacherListTooltip = ({ title, teachers }: TeacherListTooltipProps) => {
    const { t } = useTranslation()

    return (
        <EntityListTooltip
            title={title}
            entities={teachers}
            entityLabelFn={teacherDisplayName}
            entityKeyFn={(t) => t.getTeacherId()}
            singularEntityLabel={t('Teacher').toLowerCase()}
            pluralEntityLabel={t('Teachers').toLowerCase()}
        />
    )
}
