import { v4 as uuid } from 'uuid'
import { LectureAccessor, ScheduleAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { IEventGroup, ILecture, IScheduleTransform } from 'common-api'
import { addMinutes } from '../../utils/DayAndTimeUtil'
import { Week } from '../../commonTypes'

// Helper method used for duplicating or splitting a lecture
export const duplicateLecture = (
    lecture: LectureAccessor,
    firstLectureLength: number = lecture.getDurationInMinutes(),
    secondLectureLength: number = lecture.getDurationInMinutes(),
    secondLectureOffset = 0
): IScheduleTransform[] => {
    const dayAndTime = lecture.getEventGroup().getDayAndTime()

    return splitAndProcessLecture(
        lecture,
        (origLecture) => ({ ...origLecture, durationInMinutes: firstLectureLength }),
        (newLecture) => ({ ...newLecture, durationInMinutes: secondLectureLength }),
        (newEg) => ({
            ...newEg,
            dayAndTime: dayAndTime === undefined ? undefined : addMinutes(dayAndTime, secondLectureOffset),
            timeslotPinned: false
        })
    )
}

const findNoWeeksWsp = (schedule: ScheduleAccessor) => {
    return schedule.getWeekSelectionPresets().find((wsp) => wsp.weeks.length === 0)
}

export const extractLectureFromSeries = (lecture: LectureAccessor, weekToExtract: Week) => {
    const noWeeksWsp = findNoWeeksWsp(lecture.getSchedule())
    if (noWeeksWsp === undefined) {
        return []
    }

    lecture.getSchedule().getWeekSelectionPresets()

    return splitAndProcessLecture(
        lecture,
        (origLecture) => ({
            ...origLecture,
            weekSelection: {
                ...origLecture.weekSelection,
                excludes: [...origLecture.weekSelection.excludes, weekToExtract]
            }
        }),
        (newLecture) => ({
            ...newLecture,
            weekSelection: {
                weekSelectionPresetId: noWeeksWsp.weekSelectionPresetId,
                includes: [weekToExtract],
                excludes: []
            }
        })
    )
}

const splitAndProcessLecture = (
    lecture: LectureAccessor,
    origLectureUpdater: (l: ILecture) => ILecture,
    newLectureUpdater: (l: ILecture) => ILecture,
    newEventGroupUpdater: (eg: IEventGroup) => IEventGroup = (eg: IEventGroup) => eg
) => {
    const originalLecture = lecture.getConjureLecture()

    // Inherit the same id, so this lecture will actually be modified (and stay within its event group)
    const firstLecture = origLectureUpdater(originalLecture)

    // Set new id to create a second lecture
    const secondLectureBase = {
        ...originalLecture,
        lectureId: uuid()
    }

    const secondLecture = newLectureUpdater(secondLectureBase)

    // Wrap second lecture in new event group
    const secondLectureEventGroupBase = {
        ...lecture.getEventGroup().getConjureObject(),
        eventGroupId: uuid(),
        lectures: [secondLecture]
    }
    const secondLectureEventGroup = newEventGroupUpdater(secondLectureEventGroupBase)

    return [
        IScheduleTransform.lectureTransform({ newLecture: firstLecture }),
        IScheduleTransform.eventGroupTransform({ newEventGroup: secondLectureEventGroup })
    ]
}
