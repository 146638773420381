import { useTranslation } from 'react-i18next'
import { displayNameForLecture } from '../../../pages/lectures/utils'
import { EntityListTooltip } from '../../MultiRoomAttributeSelector/RoomsListTooltip/EntityListTooltip'
import { LecturesListTooltipProps } from './types'

export const LecturesListTooltip = ({ title, lectures }: LecturesListTooltipProps) => {
    const { t } = useTranslation()

    return (
        <EntityListTooltip
            title={title}
            entities={lectures}
            entityLabelFn={displayNameForLecture}
            entityKeyFn={(l) => l.getLectureId()}
            singularEntityLabel={t('Lecture')}
            pluralEntityLabel={t('Lectures')}
        />
    )
}
