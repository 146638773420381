import { Space } from 'antd'
import classNames from 'classnames'
import type { IEventGroup, IProblemWithScore, IReservedTime, IWeekSelection } from 'common-api'
import { IScheduleTransform } from 'common-api'
import { groupBy } from 'lodash'
import { useRef } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { WeekSelectionAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import type { ApplicationState } from '../../../../store'
import { endDragLecture, locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { type ScheduleSelector } from '../../../../store/scheduleselector/types'
import { WeekDaySelectorState } from '../../../../store/weekDaySelector/types'
import TypeScale from '../../../../styles/TypeScale'
import { DndItemTypes } from '../../../../utils/DndItemTypes'
import { relatedDayAndWeeks } from '../../../../utils/problems'
import type { VersionedScheduleTransform } from '../../../../utils/scheduleTransforms'
import { transformedEventIds } from '../../../../utils/scheduleTransforms'
import { isNonLectureProblemAmongSelectedSchedules } from '../../../../utils/scheduleUtils'
import { useNameOfDay } from '../../../../utils/weekUtil'
import { useScheduleStore } from '../../store'
import { CreateBlockLayer } from '../CreateBlockLayer'
import { DragLayer } from '../DragLayer/DragLayer'
import { DragEventType } from '../DragLayer/utils'
import EventComponent from '../EventComponent'
import { ProblemCorner } from '../ProblemCorner'
import { toProblemListProblem } from '../ProblemsList/utils'
import type { ScheduleEvent } from '../ScheduleEvent'
import { isLectureEvent, LectureEvent, ReservedTimeEvent } from '../ScheduleEvent'
import { TimeLine } from '../TimeLine'
import type { EventAndLayoutSpec } from '../types'
import { DAYS, layoutAllocatedEvents, layoutUnallocatedEvents, positioningDayLeft, positionToLayoutSpec } from '../util'
import ColumnHeader from './components/ColumnHeader'
import classes from './style.module.css'
import { eventsInSameEventGroup, hasNonEmptyIntersection, isEventAmongSelectedSchedules } from './utils'

const dayBucket = (event: ScheduleEvent) => {
    const dat = event.getDayAndTime()
    return dat?.day || 'UNALLOCATED'
}

type RenderScheduleProps = {
    renderMultipleMode: 'per-schedule' | 'columns'
} & (
    | { renderMultipleMode: 'per-schedule'; scheduleSelector: ScheduleSelector }
    | { renderMultipleMode: 'columns'; scheduleSelector?: ScheduleSelector }
)

export const Schedule = ({ scheduleSelector, renderMultipleMode }: RenderScheduleProps) => {
    const { getDayName, getShortDayName } = useNameOfDay()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const blockedTimeSelectionModeActive = useSelector<ApplicationState, boolean>(
        (state) => state.blockedTimeSelection.selectionModeActive
    )

    const pendingTransforms = useSelector<ApplicationState, VersionedScheduleTransform[]>(
        (state) => state.schedule.pendingTransforms
    )
    const weekSelection = useSelector<ApplicationState, IWeekSelection>((state) => state.weekSelection)
    const selectedScheduleSelectors = useSelector<ApplicationState, ScheduleSelector[]>(
        (state) => state.scheduleSelection.selectedSchedules
    )
    const scheduleSelectorsToRender = renderMultipleMode === 'columns' ? selectedScheduleSelectors : [scheduleSelector]

    const selectedWeekDays = useSelector<ApplicationState, WeekDaySelectorState['selectedWeekDays']>(
        (state) => state.weekDaySelector.selectedWeekDays
    )

    const { columns } = useScheduleStore()
    const scheduleToRender = useLocalSchedule()

    const selectedWeeks = new WeekSelectionAccessor(scheduleToRender, weekSelection).getWeeks().map((w) => w.valueOf())

    const startOfDay = scheduleToRender.getSettings().startOfDay!
    const endOfDay = scheduleToRender.getSettings().endOfDay!

    const dropWrapper = useRef<HTMLDivElement>(null)

    const [, drop] = useDrop(
        () => ({
            accept: DndItemTypes.EVENT,
            drop: (item: DragEventType, monitor) => {
                const currentOffset = monitor.getClientOffset()
                const initialOffset = monitor.getInitialClientOffset()

                if (dropWrapper.current && currentOffset && initialOffset) {
                    const layoutSpec = positionToLayoutSpec({
                        startOfDay,
                        endOfDay,
                        currentOffset,
                        dropBounds: dropWrapper.current.getBoundingClientRect(),
                        initialOffset,
                        initialLayoutSpec: item.layoutSpec,
                        eventDurationInMinutes: item.event.getDurationInMinutes(),
                        selectedWeekDays
                    })

                    const scheduleEvents = eventsInSameEventGroup(item.event)

                    // This code is not very elegant as of now. If we start dragging a lecture, then all N lectures in
                    // the corresponding event group will be dragged. When the lectures are dropped, this code will
                    // create a bulk transform that updates the same event group N times. We will most likely want to
                    // refactor the code so that it is the event group itself that's represents the dragged item.
                    const transforms = scheduleEvents.flatMap((scheduleEvent: ScheduleEvent) =>
                        scheduleEvent.accept<IScheduleTransform[]>({
                            visitLecture: (lectureEvent: LectureEvent) => {
                                const eventGroup = lectureEvent.getLecture().getEventGroup()

                                const newEventGroup: IEventGroup = {
                                    ...eventGroup.getConjureObject(),
                                    dayAndTime: layoutSpec?.dayAndTime || null
                                }

                                return [IScheduleTransform.eventGroupTransform({ newEventGroup })]
                            },
                            visitReservedTime: (reservedTimeEvent: ReservedTimeEvent) => {
                                if (!layoutSpec) {
                                    console.log(
                                        'Reserved time dropped outside of schedule. ' +
                                            'Should we support this as a way of removing the reserved time? ' +
                                            'Doing nothing for now.'
                                    )
                                    return []
                                }
                                const newReservedTime: IReservedTime = {
                                    ...reservedTimeEvent.getReservedTime().getConjureObject(),
                                    dayAndTime: layoutSpec.dayAndTime
                                }
                                return [
                                    IScheduleTransform.reservedTimeTransform({
                                        newReservedTime
                                    })
                                ]
                            }
                        })
                    )

                    dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(transforms)))
                }
                dispatch(endDragLecture())
            }
        }),
        [startOfDay, endOfDay]
    )

    const allEvents: ScheduleEvent[] = [
        ...scheduleToRender.getLectures().map((l) => new LectureEvent(l)),
        ...scheduleToRender.getReservedTimes().map((rt) => new ReservedTimeEvent(rt))
    ]

    const eventIsAmongSelectedWeekDays = (event: ScheduleEvent) => {
        const day = event.getDayAndTime()?.day

        if (day === undefined) {
            return false
        }

        return selectedWeekDays.includes(day)
    }

    const alloctedEvents = scheduleSelectorsToRender.flatMap((scheduleSelector, index) => {
        const eventsToShow = allEvents
            .filter(eventIsAmongSelectedWeekDays)
            .filter((e) => e.getDayAndTime()?.day)
            .filter((e) => hasNonEmptyIntersection(e.getWeekSelection().getWeeks(), selectedWeeks))
            .filter((e) => isEventAmongSelectedSchedules(scheduleToRender, e, [scheduleSelector]))

        const dayGroups = groupBy(eventsToShow, dayBucket)

        return Object.keys(dayGroups).flatMap((day) => {
            const events = dayGroups[day]

            return layoutAllocatedEvents(events, index, scheduleSelectorsToRender.length)
        }) satisfies EventAndLayoutSpec[]
    })

    const allUnalloctedEvents = scheduleSelectorsToRender.flatMap((scheduleSelector, index) =>
        allEvents
            .filter(isLectureEvent)
            .filter((e) => !e.getDayAndTime()?.day)
            .filter((e) => hasNonEmptyIntersection(e.getWeekSelection().getWeeks(), selectedWeeks))
            .filter((e) => isEventAmongSelectedSchedules(scheduleToRender, e, [scheduleSelector]))
            .map((lecture) => ({
                lecture,
                scheduleSelectorIndex: index
            }))
    )

    const unAlloctedEvents = layoutUnallocatedEvents(allUnalloctedEvents, selectedScheduleSelectors.length)

    const sortedLaidOutEvents = alloctedEvents.sort(({ event: l1 }, { event: l2 }) =>
        l1.getUniqueId().localeCompare(l2.getUniqueId())
    )

    const sortedUnAlloctedEvents = unAlloctedEvents.sort(({ event: l1 }, { event: l2 }) =>
        l1.getUniqueId().localeCompare(l2.getUniqueId())
    )

    const locallyModifiedEventIds = pendingTransforms.flatMap(transformedEventIds)

    const shouldNotBeAnimatedIntoPosition = (event: ScheduleEvent) =>
        locallyModifiedEventIds.includes(
            event.accept({
                visitLecture: (lectureEvent) => lectureEvent.getLecture().getLectureId(),
                visitReservedTime: (reservedTimeEvent) => reservedTimeEvent.getReservedTime().getReservedTimeId()
            })
        )

    type DayToProblemsDict = { [day: string]: IProblemWithScore[] }
    const emptyDaysDict = DAYS.reduce((prev, currentValue) => ({ ...prev, [currentValue]: [] }), {})
    const nonLectureProblemsInSelectedSchedules = scheduleToRender
        .getSchedulingProblems()
        .filter((pws) =>
            isNonLectureProblemAmongSelectedSchedules(scheduleToRender, pws.problem, selectedScheduleSelectors)
        )

    const dayLevelProblems: DayToProblemsDict = nonLectureProblemsInSelectedSchedules
        .map((pws) => ({ pws, relatedDaws: relatedDayAndWeeks(pws.problem) }))
        .filter(({ relatedDaws }) => relatedDaws !== undefined)
        .filter(({ relatedDaws }) => hasNonEmptyIntersection(relatedDaws!.weeks, selectedWeeks))
        .map(({ pws, relatedDaws }) => ({ pws, day: relatedDaws!.day }))
        .reduce(
            (prev: DayToProblemsDict, { pws, day }) => ({
                ...prev,
                [day]: [...prev[day], pws]
            }),
            emptyDaysDict
        )

    const events = [...sortedLaidOutEvents, ...sortedUnAlloctedEvents]
    const renderPerSchedule = renderMultipleMode === 'per-schedule'

    return (
        <Space size="large" direction="vertical" style={{ width: '100%' }}>
            <div
                ref={drop}
                className={classNames(classes.wrapper, {
                    [classes['wrapper--multipleSchedules']]: renderPerSchedule && columns > 1
                })}
                style={
                    {
                        '--columns': columns
                    } as React.CSSProperties
                }
            >
                <div
                    className={classNames(
                        classes.whiteBackdrop,
                        renderPerSchedule ? classes['whiteBackdrop--perSchedule'] : classes['whiteBackdrop--default']
                    )}
                />
                <div
                    className={classNames(
                        classes.timeLineWrapper,
                        renderPerSchedule
                            ? classes['timeLineWrapper--perSchedule']
                            : classes['timeLineWrapper--default']
                    )}
                >
                    <TimeLine />
                </div>
                <div
                    className={classNames(classes.daysWrapper, {
                        [classes['daysWrapper--perSchedule']]: renderPerSchedule,
                        [classes['daysWrapper--default']]: !renderPerSchedule
                    })}
                >
                    {selectedWeekDays.map((day) => (
                        <div
                            className={classNames(classes.day, {
                                [classes['day--perSchedule']]: renderPerSchedule,
                                [classes['day--moreThanOneColumn']]:
                                    renderMultipleMode === 'columns' && selectedScheduleSelectors.length > 1
                            })}
                            key={day}
                            style={{
                                left: `${positioningDayLeft(day, selectedWeekDays)}%`,
                                width: `${100 / selectedWeekDays.length}%`
                            }}
                        >
                            <div className={classes.dayHeader}>
                                <ProblemCorner
                                    problems={dayLevelProblems[day].map((pws) =>
                                        toProblemListProblem(scheduleToRender, pws, t)
                                    )}
                                />
                                <p className={classes.dayHeaderText}>{getDayName(day)}</p>
                                <p
                                    className={classNames(
                                        classes['dayHeaderText--short'],
                                        TypeScale.Paragraph_SM_Semibold
                                    )}
                                >
                                    {getShortDayName(day)}
                                </p>
                            </div>
                            {renderMultipleMode === 'columns' ? <ColumnHeader /> : null}
                        </div>
                    ))}
                </div>
                <div
                    className={classNames(
                        classes.lectures,
                        renderPerSchedule ? classes['lectures--perSchedule'] : classes['lectures--default']
                    )}
                >
                    {events.map(({ event, layoutSpec }) => (
                        <EventComponent
                            transition={shouldNotBeAnimatedIntoPosition(event) ? 'unset' : undefined}
                            key={event.getUniqueId() + layoutSpec.scheduleSelectorIndex}
                            event={event}
                            layoutSpec={layoutSpec}
                        />
                    ))}
                </div>
                {blockedTimeSelectionModeActive && (
                    <div
                        className={classNames(
                            classes.createBlockLayerWrapper,
                            renderPerSchedule
                                ? classes['createBlockLayerWrapper--perSchedule']
                                : classes['createBlockLayerWrapper--default']
                        )}
                    >
                        <CreateBlockLayer />
                    </div>
                )}
                <div
                    className={classNames(
                        classes.dragLayerWrapper,
                        renderPerSchedule
                            ? classes['dragLayerWrapper--perSchedule']
                            : classes['dragLayerWrapper--default']
                    )}
                    ref={dropWrapper}
                >
                    <DragLayer selectedSchedule={scheduleSelector} />
                </div>
                <div className={classes.unallocatedLecturesBackground} />
            </div>
        </Space>
    )
}
