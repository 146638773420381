import { IconDropdownMenuProps } from './types'
import { Dropdown, Flex, MenuProps } from 'antd'
import { Checkmark } from '../Checkmark'
import { EditOutlined } from '@ant-design/icons'

export const IconDropdownMenu = ({ menuItems, selectedKeys = [] }: IconDropdownMenuProps) => {
    const items: MenuProps['items'] = menuItems.map(({ label, onClick, key, danger }, i) => {
        const selected = key !== undefined && selectedKeys.includes(key)

        return {
            key: `${i}`,
            label: (
                <Flex gap={20} align="center" style={selected ? { fontWeight: 600, color: 'black' } : {}}>
                    <div style={{ flex: 1 }}>{label}</div>
                    <Checkmark selected={selected} style={{ color: '#1677ff' }} />
                </Flex>
            ),
            onClick,
            danger
        }
    })

    return (
        <Dropdown trigger={['click']} menu={{ selectedKeys, items }}>
            <EditOutlined />
        </Dropdown>
    )
}
