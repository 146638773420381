import * as React from 'react'
import type { SVGProps } from 'react'

export const SvgButcherKnife = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 824 824" className="teacher_svg__icon" {...props}>
        <path
            d="m565.8 47.443-13.446 1.398a247.252 247.252 0 0 1-14.87 6.4 16177.188 16177.188 0 0 0-298.059 364.041 5463.728 5463.728 0 0 0 190.68 152.99c105.26-95.368 198.04-202.11 278.35-320.23a259.088 259.088 0 0 1 20.25 17.312c-47.318 68.788-98.722 134.66-154.21 197.6a2203.791 2203.791 0 0 1-122.98 124.45c12.308 12.064 25.64 22.003 39.998 29.82a1034.072 1034.072 0 0 0 95.541-89.555 4173.1 4173.1 0 0 0 119.26-141.54c38.93-51.136 72.475-105.11 100.63-161.93A512.98 512.98 0 0 1 878.212 304a19843.924 19843.924 0 0 0-182.1-145.14c-9.314-6.318-19.42-10.12-30.314-11.407zm1.022 65.457a26.176 26.176 0 0 1 28.744 23.326 26.176 26.176 0 0 1-23.326 28.744 26.176 26.176 0 0 1-28.744-23.326 26.176 26.176 0 0 1 23.326-28.744zm-344.85 327.67c-1.22-.075-2.39.352-3.507 1.278a140.827 140.827 0 0 1-23.076 20.848c-43.146 41.94-83.454 86.911-120.92 134.92-26.959 37.891-47.615 78.878-61.969 122.96l2.6 24.974c3.632 6.224 7.82 12.26 12.564 18.11 8.252 5.094 16.789 9.385 25.61 12.872l15.37-1.6c24.665-8.127 35.485-25.435 32.463-51.924l4.302-5.301c38.74-23.168 68.112-55.03 88.12-95.58 8.936-24.166 21.18-46.477 36.728-66.932 8.085-8.749 18.063-12.374 29.938-10.881l26.771 14.69c3.336.948 6.537.615 9.606-1a175.515 175.515 0 0 0 27.539-33.933c-3.235-2.326-6.097-4.942-8.584-7.846a5087.935 5087.935 0 0 0-91.3-74.971c-.77-.406-1.52-.633-2.253-.678z"
            clip-rule="evenodd"
            fill-rule="evenodd"
            image-rendering="optimizeQuality"
            shape-rendering="geometricPrecision"
        />
    </svg>
)
