import {
    ChecklistStep,
    ICourse,
    ICourseOrSubjectId,
    ICourseRound,
    IDayAndTime,
    IEventGroup,
    ILecture,
    ILectureDurationThresholds,
    IMinBreakThresholds,
    IProblemWithScore,
    IReservedTime,
    IRoom,
    IRoomAssignment,
    ISchedule,
    IScheduleSettings,
    IStudentGroup,
    ISubject,
    ITeacher,
    IWeekSelection,
    IWeekSelectionPreset,
    Terms
} from 'common-api'
import type { ValueObject } from 'immutable'
import { hash } from 'immutable'
import { isEqual, uniq, without } from 'lodash'
import type {
    CourseId,
    CourseRoundId,
    EventGroupId,
    LectureId,
    OptionalLectureDurationThresholds,
    OptionalMinBreakThresholds,
    ReservedTimeId,
    RoomId,
    ScheduleId,
    StudentGroupId,
    SubjectId,
    TeacherId,
    Week,
    WeekSelectionPresetId
} from '../commonTypes'
import { addMinutes } from '../utils/DayAndTimeUtil'
import { findOrThrow, orThrow } from '../utils/collections'
import { AppError } from '../utils/errorutil'
import { neitherNullNorUndefined, nullToUndefined } from '../utils/miscUtil'
import { computeImplicitAndExplicitStudentGroupOverlaps } from '../utils/studentGroupUtil'

export class ScheduleAccessor {
    private studentGroups?: Map<StudentGroupId, StudentGroupAccessor>

    constructor(private readonly schedule: ISchedule) {}

    getRooms(): RoomAccessor[] {
        return this.schedule.rooms.map((r) => new RoomAccessor(this, r))
    }

    getSubjects(): SubjectAccessor[] {
        return this.schedule.subjects.map((s) => new SubjectAccessor(this, s))
    }

    getCourseRounds(): CourseRoundAccessor[] {
        return this.schedule.courseRounds.map((cr) => new CourseRoundAccessor(this, cr))
    }

    getCourses(): CourseAccessor[] {
        return this.schedule.courses.map((c) => new CourseAccessor(this, c))
    }

    getEventGroups(): EventGroupAccessor[] {
        return this.schedule.eventGroups.map((eg) => new EventGroupAccessor(this, eg))
    }

    getLectures(): LectureAccessor[] {
        return this.getEventGroups().flatMap((eg) => eg.getLectures())
    }

    getReservedTimes(): ReservedTimeAccessor[] {
        return this.schedule.reservedTimes.map((rt) => new ReservedTimeAccessor(this, rt))
    }

    getScheduleId(): ScheduleId {
        return this.schedule.scheduleId
    }

    getSchedulingProblems(): IProblemWithScore[] {
        return this.schedule.schedulingProblems
    }

    getStudentGroups(): StudentGroupAccessor[] {
        if (this.studentGroups === undefined) {
            this.studentGroups = new Map<StudentGroupId, StudentGroupAccessor>(
                this.schedule.studentGroups.map((isg) => [isg.studentGroupId, new StudentGroupAccessor(this, isg)])
            )
        }

        return [...this.studentGroups.values()]
    }

    getTeachers(): TeacherAccessor[] {
        return this.schedule.teachers.map((t) => new TeacherAccessor(this, t))
    }

    getSettings(): IScheduleSettings {
        return this.schedule.settings
    }

    getWeekSelectionPresets(): IWeekSelectionPreset[] {
        return this.schedule.weekSelectionPresets
    }

    getStudentGroupLabels(): string[] {
        return uniq(this.schedule.studentGroups.flatMap((sg) => sg.labels)).sort()
    }

    getRoomAttributes(): string[] {
        return uniq([
            ...this.schedule.rooms.flatMap((r) => r.attributes),
            ...this.schedule.eventGroups
                .flatMap((eg) => eg.lectures)
                .flatMap((l) => l.roomAssignments!.flatMap((ra) => ra.requiredAttributes))
        ]).sort()
    }

    getVersion(): number {
        return this.schedule.version
    }

    findCourse(courseId: CourseId): CourseAccessor {
        return new CourseAccessor(
            this,
            findOrThrow(this.schedule.courses, courseId, (c) => c.courseId)
        )
    }

    findSubject(subjectId: SubjectId): SubjectAccessor {
        return new SubjectAccessor(
            this,
            findOrThrow(this.schedule.subjects, subjectId, (s) => s.subjectId)
        )
    }

    doesWeekSelectionPresetIdExist(weekSelectionPresetId: WeekSelectionPresetId) {
        return this.schedule.weekSelectionPresets.some((wsp) => wsp.weekSelectionPresetId === weekSelectionPresetId)
    }

    doesRoomIdExist(roomId: RoomId): boolean {
        return this.schedule.rooms.some((r) => r.roomId === roomId)
    }

    findRoom(roomId: RoomId): RoomAccessor {
        return new RoomAccessor(
            this,
            findOrThrow(this.schedule.rooms, roomId, (r) => r.roomId)
        )
    }

    doesCourseRoundIdExist(crId: CourseRoundId): boolean {
        return this.schedule.courseRounds.some((cr) => cr.courseRoundId === crId)
    }

    findCourseRound(courseRoundId: CourseRoundId): CourseRoundAccessor {
        return new CourseRoundAccessor(
            this,
            findOrThrow(this.schedule.courseRounds, courseRoundId, (cr) => cr.courseRoundId)
        )
    }

    doesStudentGroupIdExist(sgId: StudentGroupId): boolean {
        return this.schedule.studentGroups.some((sg) => sg.studentGroupId === sgId)
    }

    findStudentGroup(studentGroupId: StudentGroupId): StudentGroupAccessor {
        if (this.studentGroups === undefined) {
            this.studentGroups = new Map<StudentGroupId, StudentGroupAccessor>(
                this.schedule.studentGroups.map((isg) => [isg.studentGroupId, new StudentGroupAccessor(this, isg)])
            )
        }

        return orThrow(this.studentGroups.get(studentGroupId))
    }

    doesTeacherIdExist(teacherId: TeacherId): boolean {
        return this.schedule.teachers.some((t) => t.teacherId === teacherId)
    }

    doesLectureIdExist(lectureId: LectureId): boolean {
        return this.schedule.eventGroups.some((eg) => eg.lectures.some((l) => l.lectureId === lectureId))
    }

    doesCourseIdExist(courseId: CourseId): boolean {
        return this.schedule.courses.some((c) => c.courseId === courseId)
    }

    doesSubjectIdExist(subjectId: SubjectId): boolean {
        return this.schedule.subjects.some((s) => s.subjectId === subjectId)
    }

    findTeacher(teacherId: TeacherId): TeacherAccessor {
        return new TeacherAccessor(
            this,
            findOrThrow(this.schedule.teachers, teacherId, (t) => t.teacherId)
        )
    }

    findEventGroup(eventGroupId: EventGroupId): EventGroupAccessor {
        return new EventGroupAccessor(
            this,
            findOrThrow(this.schedule.eventGroups, eventGroupId, (eg) => eg.eventGroupId)
        )
    }

    findLecture(lectureId: LectureId): LectureAccessor {
        for (const eventGroup of this.schedule.eventGroups) {
            for (const lecture of eventGroup.lectures) {
                if (lecture.lectureId === lectureId) {
                    return new LectureAccessor(this, new EventGroupAccessor(this, eventGroup), lecture)
                }
            }
        }

        throw new AppError('Could not find lecture', { lectureId })
    }

    findReservedTime(reservedTimeId: ReservedTimeId): ReservedTimeAccessor {
        return new ReservedTimeAccessor(
            this,
            findOrThrow(this.schedule.reservedTimes, reservedTimeId, (rt) => rt.reservedTimeId)
        )
    }

    findWeekSelectionPreset(weekSelectionPresetId: WeekSelectionPresetId): IWeekSelectionPreset {
        return findOrThrow(
            this.schedule.weekSelectionPresets,
            weekSelectionPresetId,
            (wsp) => wsp.weekSelectionPresetId
        )
    }

    getChecklistStepsCompleted(): ChecklistStep[] {
        return this.schedule.checklistStepsCompleted!
    }

    getConjureSchedule() {
        return this.schedule
    }
}

export class RoomAccessor {
    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly room: IRoom
    ) {}

    getConjureObject(): IRoom {
        return this.room
    }

    getRoomId(): RoomId {
        return this.room.roomId
    }

    getName(): string {
        return this.room.name
    }

    getAttributes(): string[] {
        return this.room.attributes
    }
}

export class CourseRoundAccessor {
    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly courseRound: ICourseRound
    ) {}

    getConjureObject(): ICourseRound {
        return this.courseRound
    }

    getCourseOrSubject(): CourseAccessor | SubjectAccessor {
        return ICourseOrSubjectId.visit<CourseAccessor | SubjectAccessor>(this.courseRound.courseOrSubjectId!, {
            courseId: (courseId) => this.schedule.findCourse(courseId),
            subjectId: (subjectId: string) => this.schedule.findSubject(subjectId),
            unknown: () => {
                throw new AppError('Unexpected course or subject type')
            }
        })
    }

    getCourseRoundId(): CourseRoundId {
        return this.courseRound.courseRoundId
    }

    getDisplayName(): string {
        return this.courseRound.displayName
    }

    getStudentGroup(): StudentGroupAccessor {
        return this.schedule.findStudentGroup(this.courseRound.studentGroupId)
    }

    getCourse(): CourseAccessor | undefined {
        const courseOrSubject = this.getCourseOrSubject()

        return isCourseAccessor(courseOrSubject) ? courseOrSubject : undefined
    }

    getSubject(): SubjectAccessor {
        const courseOrSubject = this.getCourseOrSubject()

        return isCourseAccessor(courseOrSubject) ? courseOrSubject.getSubject() : courseOrSubject
    }

    getTeachers(): TeacherAccessor[] {
        return this.courseRound.teacherIds.map((tid) => this.schedule.findTeacher(tid))
    }

    getTotalHours(): number {
        return this.courseRound.totalHours
    }

    getMinBreakThresholds(): OptionalMinBreakThresholds {
        return nullToUndefined(this.courseRound.minBreakThresholds)
    }

    getTerms(): Terms {
        return this.courseRound.terms
    }

    getLectureDurationThresholds(inherit = false): OptionalLectureDurationThresholds {
        const courseRoundLectureDurationThreshold = this.courseRound.lectureDurationThresholds

        return !courseRoundLectureDurationThreshold && inherit
            ? this.getCourseOrSubject().getLectureDurationThresholds(inherit)
            : nullToUndefined(courseRoundLectureDurationThreshold)
    }

    getLectures(): LectureAccessor[] {
        return this.schedule
            .getLectures()
            .filter((l) => l.getConjureLecture().courseRoundId === this.courseRound.courseRoundId)
    }
}

export class CourseAccessor {
    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly course: ICourse
    ) {}

    getConjureObject(): ICourse {
        return this.course
    }

    getCourseId(): CourseId {
        return this.course.courseId
    }

    getCode(): string {
        return this.course.code
    }

    getName(): string {
        return this.course.name
    }

    getSubjectId(): SubjectId {
        return this.course.subjectId
    }

    getSubject(): SubjectAccessor {
        return this.schedule.findSubject(this.getSubjectId())
    }

    getMinBreakThresholds(): IMinBreakThresholds | undefined {
        return nullToUndefined(this.course.minBreakThresholds)
    }

    getLectureDurationThresholds(inherit = false): ILectureDurationThresholds | undefined {
        const courseLectureDurationThreshold = this.course.lectureDurationThresholds

        return !courseLectureDurationThreshold && inherit
            ? this.getSubject().getLectureDurationThresholds(inherit)
            : nullToUndefined(courseLectureDurationThreshold)
    }
}

export function isCourseAccessor(accessor: CourseAccessor | SubjectAccessor): accessor is CourseAccessor {
    return (accessor as any).course !== undefined
}

export function isSubjectAccessor(accessor: CourseAccessor | SubjectAccessor): accessor is SubjectAccessor {
    return (accessor as any).subject !== undefined
}

export class SubjectAccessor {
    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly subject: ISubject
    ) {}

    getConjureObject(): ISubject {
        return this.subject
    }

    getSubjectId(): SubjectId {
        return this.subject.subjectId
    }

    getCode(): string {
        return this.subject.code
    }

    getName(): string {
        return this.subject.name
    }

    getColor(): string {
        return this.subject.color || '0000ff'
    }

    getMinBreakThresholds(): IMinBreakThresholds | undefined {
        return nullToUndefined(this.subject.minBreakThresholds)
    }

    getLectureDurationThresholds(inherit = false): ILectureDurationThresholds | undefined {
        const subjectLectureDurationThreshold = this.subject.lectureDurationThresholds

        return !subjectLectureDurationThreshold && inherit
            ? this.schedule.getSettings().lectureDurationThresholds
            : nullToUndefined(subjectLectureDurationThreshold)
    }
}

export class ReservedTimeAccessor {
    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly reservedTime: IReservedTime
    ) {}

    getConjureObject() {
        return this.reservedTime
    }

    getReservedTimeId() {
        return this.reservedTime.reservedTimeId
    }

    getTitle() {
        return this.reservedTime.title
    }

    getDayAndTime(): IDayAndTime {
        return this.reservedTime.dayAndTime
    }

    getStudentGroups(): StudentGroupAccessor[] {
        return this.reservedTime.studentGroupIds.map((sgId) => this.schedule.findStudentGroup(sgId))
    }

    getTeachers(): TeacherAccessor[] {
        return this.reservedTime.teacherIds.map((teacherId) => this.schedule.findTeacher(teacherId))
    }

    getRooms(): RoomAccessor[] {
        return this.reservedTime.roomIds.map((roomId) => this.schedule.findRoom(roomId))
    }

    getWeekSelection(): WeekSelectionAccessor {
        return new WeekSelectionAccessor(this.schedule, this.reservedTime.weekSelection)
    }

    getDurationInMinutes(): number {
        return this.reservedTime.durationInMinutes
    }
}

export class EventGroupAccessor {
    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly eventGroup: IEventGroup
    ) {}

    getConjureObject(): IEventGroup {
        return this.eventGroup
    }

    getEventGroupId(): EventGroupId {
        return this.eventGroup.eventGroupId
    }

    getDayAndTime(): IDayAndTime | undefined {
        return this.eventGroup.dayAndTime || undefined
    }

    getDisplayName(): string {
        return this.eventGroup.displayName
    }

    isTimeslotPinned(): boolean {
        return this.eventGroup.timeslotPinned
    }

    getLectures(): LectureAccessor[] {
        return this.eventGroup.lectures.map((l) => new LectureAccessor(this.schedule, this, l))
    }

    isScheduled(): boolean {
        return this.getDayAndTime() !== undefined
    }
}

export class RoomAssignmentAccessor {
    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly roomAssignment: IRoomAssignment
    ) {}

    getConjureObject() {
        return this.roomAssignment
    }

    getRoom(): RoomAccessor | undefined {
        return this.roomAssignment.roomId ? this.schedule.findRoom(this.roomAssignment.roomId) : undefined
    }

    isPinned(): boolean {
        return this.roomAssignment.pinned
    }
}

export class LectureAccessor {
    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly eventGroup: EventGroupAccessor,
        private readonly lecture: ILecture
    ) {}

    getLectureId(): LectureId {
        return this.lecture.lectureId
    }

    getConjureLecture() {
        return this.lecture
    }

    getSchedule(): ScheduleAccessor {
        return this.schedule
    }

    getLectureNum(): number {
        return (
            this.getCourseRound()
                .getLectures()
                .findIndex((l) => l.getLectureId() === this.getLectureId()) + 1
        )
    }

    getCourseRound(): CourseRoundAccessor {
        return this.schedule.findCourseRound(this.lecture.courseRoundId)
    }

    getTeachers(): undefined | TeacherAccessor[] {
        return this.lecture.teacherIds?.map((tid) => this.schedule.findTeacher(tid))
    }

    getEffectiveTeachers(): TeacherAccessor[] {
        return this.getTeachers() ?? this.getCourseRound().getTeachers()
    }

    getAssignedRooms(): RoomAccessor[] {
        return this.lecture
            .roomAssignments!.map((ra) => ra.roomId)
            .filter(neitherNullNorUndefined)
            .map((roomId) => this.schedule.findRoom(roomId))
    }

    getRoomAssignments(): RoomAssignmentAccessor[] {
        return this.lecture.roomAssignments!.map((ra) => new RoomAssignmentAccessor(this.schedule, ra))
    }

    getDayAndTime(): IDayAndTime | undefined {
        const egDat = this.eventGroup.getDayAndTime()

        return egDat && addMinutes(egDat, this.lecture.relStartTimeInMinutes)
    }

    getWeekSelection(): WeekSelectionAccessor {
        return new WeekSelectionAccessor(this.schedule, this.lecture.weekSelection)
    }

    getDurationInMinutes(): number {
        return this.lecture.durationInMinutes
    }

    getRelativeStartTimeInMinutes(): number {
        return this.lecture.relStartTimeInMinutes
    }

    getEventGroup(): EventGroupAccessor {
        return this.eventGroup
    }

    getMinBreakThresholds(): OptionalMinBreakThresholds {
        return nullToUndefined(this.lecture.minBreakThresholds)
    }

    getLectureDurationThresholds(inherit = false): OptionalLectureDurationThresholds {
        const courseRoundLectureDurationThreshold = this.lecture.lectureDurationThresholds

        return !courseRoundLectureDurationThreshold && inherit
            ? this.getCourseRound().getLectureDurationThresholds(inherit)
            : nullToUndefined(courseRoundLectureDurationThreshold)
    }

    getLabels(): string[] {
        return this.lecture.labels
    }
}

export class WeekSelectionAccessor {
    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly weekSelection: IWeekSelection
    ) {}

    getConjureWeekSelection(): IWeekSelection {
        return this.weekSelection
    }

    getWeekSelectionPreset(): IWeekSelectionPreset {
        return this.schedule.findWeekSelectionPreset(this.weekSelection.weekSelectionPresetId)
    }

    getIncludes(): Week[] {
        return this.weekSelection.includes
    }

    getExcludes(): Week[] {
        return this.weekSelection.excludes
    }

    getWeeks(): Week[] {
        return without([...this.getWeekSelectionPreset().weeks, ...this.getIncludes()], ...this.getExcludes())
    }
}

export class TeacherAccessor {
    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly teacher: ITeacher
    ) {}

    getFirstName(): string {
        return this.teacher.firstName
    }

    getLastName(): string {
        return this.teacher.lastName
    }

    getWorkPercentage(): number {
        return this.teacher.workPercentage
    }

    getQualifications(): SubjectAccessor[] {
        return this.teacher.qualifications.map((subjectId) => this.schedule.findSubject(subjectId))
    }

    getTeacherId(): TeacherId {
        return this.teacher.teacherId
    }

    getTeacherSchoolId(): string {
        return this.teacher.teacherSchoolId
    }

    getConjureObject(): ITeacher {
        return this.teacher
    }
}

export class StudentGroupAccessor implements ValueObject {
    // Checking if two student groups overlaps is a common operation (when looking at a klass schedule for example, we
    // need to examine the student group of each lecture and see if it overlaps with the student group of the selected
    // klass). Looking at the raw overlap / non-overlap lists to determine if two student groups directly or indirect
    // overlap is a (prohibitively) expensive operation. The solution here is to compute the overlapping groups on
    // demand and save the result. Similar to memoizing the doesOverlapWith function, but we compute the entire set of
    // overlapping student groups upon the first query.
    private explicitlyOrImplicitlyOverlappingSgIds?: StudentGroupId[]

    constructor(
        private readonly schedule: ScheduleAccessor,
        private readonly studentGroup: IStudentGroup
    ) {}

    getStudentGroupId(): StudentGroupId {
        return this.studentGroup.studentGroupId
    }

    getDisplayName(): string {
        return this.studentGroup.displayName
    }

    getDescription(): string {
        return this.studentGroup.description
    }

    getOverlaps(): StudentGroupAccessor[] {
        return this.studentGroup.overlaps.map((sgId) => this.schedule.findStudentGroup(sgId))
    }

    getNonOverlaps(): StudentGroupAccessor[] {
        return this.studentGroup.nonOverlaps.map((sgId) => this.schedule.findStudentGroup(sgId))
    }

    // Helper method for checking implicit / explicit group overlap
    doesOverlapWith(otherSg: StudentGroupAccessor): boolean {
        if (this.explicitlyOrImplicitlyOverlappingSgIds !== undefined) {
            return this.explicitlyOrImplicitlyOverlappingSgIds.includes(otherSg.studentGroup.studentGroupId)
        } else if (otherSg.explicitlyOrImplicitlyOverlappingSgIds !== undefined) {
            return otherSg.explicitlyOrImplicitlyOverlappingSgIds.includes(this.studentGroup.studentGroupId)
        }

        this.explicitlyOrImplicitlyOverlappingSgIds = computeImplicitAndExplicitStudentGroupOverlaps(
            this.studentGroup.studentGroupId,
            this.schedule.getConjureSchedule().studentGroups
        )

        return this.explicitlyOrImplicitlyOverlappingSgIds.includes(otherSg.studentGroup.studentGroupId)
    }

    getLabels(): string[] {
        return this.studentGroup.labels
    }

    getConjureObject(): IStudentGroup {
        return this.studentGroup
    }

    equals(other: unknown): boolean {
        return isEqual((other as StudentGroupAccessor).studentGroup, this.studentGroup)
    }

    hashCode(): number {
        return hash(this.studentGroup)
    }
}
