import styled from '@emotion/styled'
import type { IDayAndTime } from 'common-api'
import type * as React from 'react'
import type { MouseEventHandler } from 'react'
import { useSelector } from 'react-redux'
import type { ReservedTimeAccessor } from '../../../schedule-access/scheduleAccessWrappers'
import type { ApplicationState } from '../../../store'
import { addMinutes } from '../../../utils/DayAndTimeUtil'
import { Blocked } from '../styled'
import { TimestampCorner } from './TimestampCorner'

type ReservedTimeProps = React.PropsWithChildren<{
    dayAndTime: IDayAndTime
    reservedTime: ReservedTimeAccessor
    onClick: MouseEventHandler<HTMLDivElement>
}>

export const ReservedTime = ({ dayAndTime, children, reservedTime, onClick }: ReservedTimeProps) => {
    const isSelected = useSelector<ApplicationState, boolean>((state) =>
        state.lectureSelection.selectedReservedTimes.includes(reservedTime.getReservedTimeId())
    )

    const startTime = dayAndTime
    const endTime = dayAndTime && addMinutes(dayAndTime, reservedTime.getDurationInMinutes())

    return (
        <ReservedTimeBackground onClick={onClick} isSelected={isSelected}>
            <Blocked>
                <TimestampCorner pos="topleft" dayAndTime={startTime} />
                {children}
                <TimestampCorner pos="bottomright" dayAndTime={endTime} />
            </Blocked>
        </ReservedTimeBackground>
    )
}

interface ReservedTimeBackgroundProps {
    isSelected: boolean
}

const ReservedTimeBackground = styled.div<ReservedTimeBackgroundProps>`
    border: ${({ isSelected }) => (isSelected ? '1px solid #1890ff' : 'none')};
    box-shadow: ${({ isSelected }) => (isSelected ? '0 0 0 3px #1890ff' : 'none')};
    width: 100%;
    height: 100%;
`
