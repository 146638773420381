import type { ITeacherProblemPair, ITimeProblemPair } from 'common-api'
import type { ScheduleAccessor } from '../../schedule-access/scheduleAccessWrappers'
import type { VersionedScheduleTransform } from '../../utils/scheduleTransforms'

export enum ScheduleActionTypes {
    SERVER_TRIGGERED_SCHEDULE_TRANSFORM = 'schedule/SERVER_TRIGGERED_SCHEDULE_UPDATE',

    LOCALLY_TRIGGERED_SCHEDULE_TRANSFORM = 'schedule/LOCALLY_TRIGGERED_SCHEDULE_UPDATE',
    APPEND_PENDING_SCHEDULE_TRANSFORM = 'schedule/APPEND_PENDING_SCHEDULE_TRANSFORM',
    ASSIGN_VERSION_TO_PENDING_SCHEDULE_TRANSFORM = 'schedule/ASSIGN_VERSION_TO_PENDING_SCHEDULE_TRANSFORM',

    START_DRAG_EVENT_GROUP = 'schedule/START_DRAG_EVENT_GROUP',
    END_DRAG_LECTURE = 'schedule/END_DRAG_LECTURE',
    FETCHED_PROBLEMATIC_TIMESLOTS_FOR_LECTURE = 'schedule/FETCHED_PROBLEMATIC_TIMESLOTS_FOR_SCHEDULE',

    START_DRAG_COURSE_ROUND = 'schedule/START_DRAG_COURSE_ROUND',
    END_DRAG_COURSE_ROUND = 'schedule/END_DRAG_COURSE_ROUND',
    FETCHED_PROBLEMATIC_TEACHERS_FOR_CR = 'schedule/FETCHED_PROBLEMATIC_TEACHERS_FOR_CR',

    SUBSCRIBE = 'schedule/subscribe',
    UNSUBSCRIBE = 'schedule/unsubscribe'
}

export type ScheduleVersion = number

export interface ScheduleState {
    readonly schedule: ScheduleAccessor
    readonly isDummy: boolean
    readonly pendingTransforms: VersionedScheduleTransform[]
    readonly problematicTimeslotsForDraggedLecture: ITimeProblemPair[]
    readonly problematicTeachersForDraggedCr: ITeacherProblemPair[]
    readonly subscriptionCount: number
}
