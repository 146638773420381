import type { Store } from 'redux'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import type { ApplicationState } from './store'
import { createRootReducer, rootSaga } from './store'

export default function configureStore(initialState: ApplicationState): Store<ApplicationState> {
    const composeEnhancers = composeWithDevTools({})
    const sagaMiddleware = createSagaMiddleware()

    const store = createStore(
        createRootReducer(),
        initialState as any, // Please, figure out why I need to annotate this with 'any' :-(
        composeEnhancers(applyMiddleware(sagaMiddleware))
    )

    sagaMiddleware.run(rootSaga)

    return store
}
