import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'
import { FilterableFilterDropdown } from '../../../../../components/FilterableFilterDropdown'
import { useSchedule } from '../../../../../store/schedule/hooks'

export const TeacherQualificationFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const { t } = useTranslation()
    const allSubjects = schedule.getSubjects().map((s) => ({ value: s.getSubjectId(), text: s.getName() }))

    return <FilterableFilterDropdown {...props} allFilters={allSubjects} noKeysAvailableDescription={t('NoSubjects')} />
}
