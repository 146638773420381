import { RGTheme } from '@silevis/reactgrid'
import { NestedStylesPartial } from '@silevis/reactgrid/dist/types/InternalModel'

export const gridStyles: NestedStylesPartial<RGTheme> = {
    focusIndicator: {
        background: 'rgb(230, 244, 255, .25)'
    },
    selectionIndicator: {
        background: 'rgb(230, 244, 255, .25)'
    }
}

export const MIN_ROWS_TO_SHOW = 50
