import type { DayOfWeek, ITimeProblemPair } from 'common-api'
import { max, min } from 'lodash'
import type { XYCoord } from 'react-dnd'
import { useSelector } from 'react-redux'
import type { ApplicationState } from '../../../../store'
import { useDevModeState } from '../../../../store/devmode/hooks'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { WeekDaySelectorState } from '../../../../store/weekDaySelector/types'
import { Positioned } from '../../styled'
import { isVirtualProblem } from '../ProblemsList/utils'
import { isLectureEvent } from '../ScheduleEvent'
import { MINUTES_PER_SLOT, minuteToDayAndTime, toMinuteOfDay } from '../util'
import { Blocked } from './styled'
import { DragEventType, getLayoutSpec } from './utils'

type YellowOrRedBlock = {
    color: 'red' | 'yellow'
    day: DayOfWeek
    startTime: number
    endTime: number
}

type RestrictedZonesProps = {
    currentOffset: XYCoord | null
    initialOffset: XYCoord | null
    dropBounds: DOMRect
    dragEvent: DragEventType
}

const RestrictedZones = ({ currentOffset, initialOffset, dropBounds, dragEvent }: Readonly<RestrictedZonesProps>) => {
    const devMode = useDevModeState()
    const problematicTimeSlots = useSelector<ApplicationState, ITimeProblemPair[]>((state) =>
        state.schedule.problematicTimeslotsForDraggedLecture.filter(
            (tpp) => devMode || !isVirtualProblem(tpp.problem.problem)
        )
    )

    const schedule = useLocalSchedule()
    const startOfDay = schedule.getSettings().startOfDay!
    const endOfDay = schedule.getSettings().endOfDay!

    const selectedWeekDays = useSelector<ApplicationState, WeekDaySelectorState['selectedWeekDays']>(
        (state) => state.weekDaySelector.selectedWeekDays
    )

    const layoutSpec = getLayoutSpec(
        startOfDay,
        endOfDay,
        {
            currentOffset,
            initialOffset,
            dropBounds,
            dragEvent
        },
        selectedWeekDays
    )

    if (!layoutSpec) {
        return null
    }

    if (!isLectureEvent(dragEvent.event)) {
        return null
    }

    const lecture = dragEvent.event.getLecture()

    const yellowAndRedBlocks = problematicTimeSlots.reduce<YellowOrRedBlock[]>((merged, tsp) => {
        if (!selectedWeekDays.includes(tsp.dayAndTime.day)) {
            return merged
        }

        const c: YellowOrRedBlock = {
            startTime: toMinuteOfDay(tsp.dayAndTime),
            endTime: toMinuteOfDay(tsp.dayAndTime) + MINUTES_PER_SLOT,
            day: tsp.dayAndTime.day,
            color: tsp.problem.score.hard < 0 ? 'red' : 'yellow'
        }

        const sameColorOverlapping = merged.filter(
            (b) =>
                b.day === c.day &&
                b.color === c.color &&
                ((c.startTime >= b.startTime && c.startTime <= b.endTime) ||
                    (c.endTime >= b.startTime && c.endTime <= b.endTime))
        )
        sameColorOverlapping.push(c)

        const mergedStartTime = min(sameColorOverlapping.map((b) => b.startTime))
        const mergedEndTime = max(sameColorOverlapping.map((b) => b.endTime))

        const nonOverlapping = merged.filter((b) => !sameColorOverlapping.includes(b))
        nonOverlapping.push({
            color: c.color,
            day: tsp.dayAndTime.day,
            startTime: mergedStartTime ?? 0,
            endTime: mergedEndTime ?? 0
        })

        return nonOverlapping
    }, [])

    return (
        <div>
            {yellowAndRedBlocks
                .map((b) => {
                    if (b.startTime === 8 * 60) {
                        return b
                    }

                    return {
                        ...b,
                        startTime: b.startTime + lecture.getDurationInMinutes() - MINUTES_PER_SLOT,
                        endTime:
                            yellowAndRedBlocks.filter((c) => b.day === c.day && b.endTime === c.startTime).length > 0
                                ? b.endTime + lecture.getDurationInMinutes() - MINUTES_PER_SLOT
                                : b.endTime
                    }
                })
                .filter((yellowAndRedBlock) => yellowAndRedBlock.startTime !== yellowAndRedBlock.endTime)
                .map((b, index) => (
                    <Positioned
                        startOfDay={startOfDay}
                        endOfDay={endOfDay}
                        key={index}
                        transition="all 0.1s ease 0s"
                        opacity={1}
                        zIndex="auto"
                        layoutSpec={{
                            type: 'allocated',
                            dayAndTime: minuteToDayAndTime(b.day, b.startTime),
                            column: 0,
                            columns: 1,
                            scheduleSelectorIndex: 0,
                            numSelectedSchedules: 1
                        }}
                        duration={b.endTime - b.startTime}
                        selectedWeekDays={selectedWeekDays}
                    >
                        <Blocked color={b.color} />
                    </Positioned>
                ))}
        </div>
    )
}

export default RestrictedZones
