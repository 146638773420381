import { range } from 'lodash'
import type { LectureAccessor, StudentGroupAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { formatHHMM } from '../../utils/DayAndTimeUtil'
import { combinedCmpFn, comparing } from '../../utils/compareUtil'
import { AppError } from '../../utils/errorutil'
import { intDiv } from '../../utils/mathUtil'
import { LUNCH_START, LUNCH_TIME_RANGE, MIN_LUNCH_BREAK_IN_MINUTES, SIZE_OF_KLASS } from './constants'
import type { BeginEndEvent, TimeOfDay, TimeRange } from './types'

const beginEndEventsForLecture = (lecture: LectureAccessor): BeginEndEvent[] => [
    {
        type: 'BEGIN',
        time: lecture.getDayAndTime()!
    },
    {
        type: 'END',
        time: addMinutes(lecture.getDayAndTime()!, lecture.getDurationInMinutes())
    }
]

const timeComparator = combinedCmpFn<TimeOfDay>(
    comparing((time) => time.hour),
    comparing((time) => time.minute)
)

const maxTime = (t1: TimeOfDay, t2: TimeOfDay) => (timeComparator(t1, t2) <= 0 ? t2 : t1)
const minTime = (t1: TimeOfDay, t2: TimeOfDay) => (timeComparator(t1, t2) <= 0 ? t1 : t2)

const beeComparator = combinedCmpFn<BeginEndEvent>(
    comparing((bee) => bee.time.hour),
    comparing((bee) => bee.time.minute),
    comparing((bee) => (bee.type === 'END' ? 0 : 1)) // End lecture before starting next.
)

const addMinutes = (time: TimeOfDay, minutes: number): TimeOfDay => {
    const [quo, rem] = intDiv(time.minute + minutes, 60)
    const minute = rem
    const hour = time.hour + quo

    return { hour, minute }
}

const durationInMinutes = (r: TimeRange) => 60 * (r.end.hour - r.start.hour) + (r.end.minute - r.start.minute)

const intersection = (r1: TimeRange, r2: TimeRange): TimeRange | undefined => {
    const maxStart = maxTime(r1.start, r2.start)
    const minEnd = minTime(r1.end, r2.end)

    if (timeComparator(maxStart, minEnd) >= 0) {
        return undefined
    }

    return { start: maxStart, end: minEnd }
}

const isLunchBreak = (breakRange: TimeRange) => {
    const lunchBreakRange = intersection(breakRange, LUNCH_TIME_RANGE)

    return lunchBreakRange !== undefined && durationInMinutes(lunchBreakRange) >= MIN_LUNCH_BREAK_IN_MINUTES
}

const findStartOfLunchBreak = (beginEndEvents: BeginEndEvent[]): TimeOfDay | undefined => {
    let t = undefined
    let runningLectures = 0

    for (const bee of beginEndEvents) {
        switch (bee.type) {
            case 'BEGIN':
                if (runningLectures === 0) {
                    // A break just ended. Let's check if it counts as a lunch break.
                    const firstLectureOfDay = t === undefined
                    if (!firstLectureOfDay && isLunchBreak({ start: t!, end: bee.time })) {
                        return maxTime(t!, LUNCH_START)
                    }
                }

                runningLectures++
                break
            case 'END':
                runningLectures--
                break
        }

        t = bee.time
    }

    if (runningLectures !== 0) {
        throw new AppError('Unexpected running lecture.')
    }

    // If the last lecture ends before lunch, should we still count it as a lunch break?
    // What if the last lecture ends at 9. No one will stick around until 11 anyway?
    //if (isLunchBreak({ start: t, end: END_OF_DAY })) {
    //    return maxTime(t, LUNCH_START)
    //}

    return undefined
}

// Returns
// [
//   { hhmm: '11:30', delta: 30 }
//   { hhmm: '12:30', delta: 20 }
//   { hhmm: '12:45', delta: -30 }
//   { hhmm: '13:45', delta: -20 }
// ]
const dayAndWeekDeltas = (classStudentGroups: StudentGroupAccessor[], lecturesForDayAndWeek: LectureAccessor[]) =>
    classStudentGroups.flatMap((studentGroup) => {
        const lecturesForWeekAndKlass = lecturesForDayAndWeek.filter((lecture) =>
            lecture.getCourseRound().getStudentGroup().doesOverlapWith(studentGroup)
        )

        // Begin / end events for this student group
        const beginEndEvents = lecturesForWeekAndKlass.flatMap(beginEndEventsForLecture)
        beginEndEvents.sort(beeComparator)

        // Find start of lunch break (if there is one)
        const startLunchBreak = findStartOfLunchBreak(beginEndEvents)

        if (startLunchBreak === undefined) {
            // For this day, week and klass there is no lunch break
            return []
        }

        return [
            { hhmm: formatHHMM(startLunchBreak), delta: SIZE_OF_KLASS },
            {
                hhmm: formatHHMM(addMinutes(startLunchBreak, MIN_LUNCH_BREAK_IN_MINUTES)),
                delta: -SIZE_OF_KLASS
            }
        ]
    })

type DataPoint = {
    hhmm: string
    load: number
}

// Returns:
// [
//  { hhmm: '12:15', load: 30 }
//  { hhmm: '12:30', load: 60 }
//  { hhmm: '12:45', load: 60 }
//  ...
// ]
const dayAndWeekGraph = (
    classStudentGroups: StudentGroupAccessor[],
    lecturesForDayAndWeek: LectureAccessor[]
): DataPoint[] => {
    const deltas = dayAndWeekDeltas(classStudentGroups, lecturesForDayAndWeek)

    // Pad with zero-deltas so we have full coverage of all hh:mm values
    const zeroDeltas = range(10, 14).flatMap((hour) =>
        range(0, 60, 5).map((minute) => ({
            hhmm: formatHHMM({ hour, minute }),
            delta: 0
        }))
    )
    const allDeltas: { hhmm: string; delta: number }[] = [...deltas, ...zeroDeltas].sort(
        comparing((dataPoint) => dataPoint.hhmm)
    )

    const graph: DataPoint[] = []
    let currentLoad = 0
    let lastHHMM = ''
    for (const deltaEntry of allDeltas) {
        currentLoad += deltaEntry.delta
        const newDataPoint = { hhmm: deltaEntry.hhmm, load: currentLoad }
        if (lastHHMM !== deltaEntry.hhmm) {
            // append
            graph.push(newDataPoint)
        } else {
            // replace last
            graph[graph.length - 1] = newDataPoint
        }

        lastHHMM = deltaEntry.hhmm
    }

    return graph
}

// Returns:
// [
//   { hhmm: '12:15', load: 30, week: 17 },
//   { hhmm: '12:30', load: 45, week: 17 },
//   { hhmm: '12:15', load: 20, week: 19 },
//   ...
// ]
const dayGraphs = (
    classStudentGroups: StudentGroupAccessor[],
    lecturesForDay: LectureAccessor[],
    selectedWeeks: number[]
) =>
    selectedWeeks.flatMap((week) =>
        dayAndWeekGraph(
            classStudentGroups,
            lecturesForDay.filter((lecture) => lecture.getWeekSelection().getWeeks().includes(week))
        ).map((dataPoint) => ({ ...dataPoint, week: `v.${week}` }))
    )

export {
    addMinutes,
    beeComparator,
    beginEndEventsForLecture,
    dayAndWeekDeltas,
    dayAndWeekGraph,
    dayGraphs,
    durationInMinutes,
    findStartOfLunchBreak,
    intersection,
    isLunchBreak,
    maxTime,
    minTime,
    timeComparator
}
