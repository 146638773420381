import { reject } from 'lodash'
import { ScheduleAccessor } from '../../schedule-access/scheduleAccessWrappers'
import { updateOrThrow } from '../../utils/collections'
import { throwOnUndefined } from '../../utils/miscUtil'
import type { VersionedScheduleTransform } from '../../utils/scheduleTransforms'
import type { ScheduleAction } from './actions'
import type { ScheduleState, ScheduleVersion } from './types'
import { ScheduleActionTypes } from './types'

export const initialState: ScheduleState = {
    schedule: new ScheduleAccessor({
        scheduleId: '78415590-9d98-451e-9e9e-66ca7d705a18',
        courseRounds: [],
        courses: [],
        eventGroups: [],
        reservedTimes: [],
        rooms: [],
        schedulingProblems: [],
        settings: {
            minBreakThresholds: {
                students: {
                    beforeLecture: { defaultMinutes: {}, onRoomChangeMinutes: {}, onSubjectChangeMinutes: {} },
                    afterLecture: { defaultMinutes: {}, onRoomChangeMinutes: {}, onSubjectChangeMinutes: {} }
                },
                teachers: {
                    beforeLecture: { defaultMinutes: {}, onRoomChangeMinutes: {}, onSubjectChangeMinutes: {} },
                    afterLecture: { defaultMinutes: {}, onRoomChangeMinutes: {}, onSubjectChangeMinutes: {} }
                }
            },
            lectureDurationThresholds: {
                minDuration: {},
                maxDuration: {}
            },
            startOfDay: {
                hour: 8,
                minute: 0
            },
            endOfDay: {
                hour: 17,
                minute: 0
            },
            lunchBreakSettings: {
                startOfLunchPeriod: { hour: 11, minute: 0 },
                endOfLunchPeriod: { hour: 13, minute: 0 },
                minLunchMinutes: 30
            },
            schoolYear: 0,
            schoolDays: [],
            scheduleType: 'GR'
        },
        studentGroups: [],
        subjects: [],
        teachers: [],
        version: 0,
        weekSelectionPresets: [
            {
                weekSelectionPresetId: 'd40e5c9f-6d1a-42de-9c07-e7548dce146d',
                displayName: 'Läsår',
                weeks: []
            }
        ],
        checklistStepsCompleted: []
    }),
    isDummy: true,
    pendingTransforms: [],
    problematicTimeslotsForDraggedLecture: [],
    problematicTeachersForDraggedCr: [],
    subscriptionCount: 0
}

const transformIsOutdated = (currentVersion: ScheduleVersion) => (transform: VersionedScheduleTransform) =>
    transform.version !== undefined && transform.version <= currentVersion

export const scheduleReducer = (state = initialState, action: ScheduleAction): ScheduleState => {
    switch (action.type) {
        case ScheduleActionTypes.SERVER_TRIGGERED_SCHEDULE_TRANSFORM: {
            const newSchedule = action.payload

            // Garbage collect any pending schedule transforms that have been incorporated into the schedule.
            const newPendingTransforms = reject(state.pendingTransforms, transformIsOutdated(newSchedule.version))

            return {
                ...state,
                isDummy: false,
                schedule: new ScheduleAccessor(newSchedule),
                pendingTransforms: newPendingTransforms
            }
        }
        case ScheduleActionTypes.APPEND_PENDING_SCHEDULE_TRANSFORM: {
            const versionedScheduleTransform = action.payload

            return {
                ...state,
                pendingTransforms: [...state.pendingTransforms, versionedScheduleTransform]
            }
        }
        case ScheduleActionTypes.ASSIGN_VERSION_TO_PENDING_SCHEDULE_TRANSFORM: {
            const { transformId, version } = action.payload

            // Update pending transform
            let newPendingTransforms = updateOrThrow(
                state.pendingTransforms,
                (transform) => transform.transformId === transformId,
                (transform) => ({ ...transform, version })
            )

            // It could be the case that we already have this transform incorporated in the schedule. This would happen
            // if:
            //
            // 1. A transform is appended to the queue of pending transforms
            // 2. The transform is submitted through a PUT request to the server.
            // 3. The server processes the transform and fires away a schedule update on the websocket.
            // 4. Client receives and updates the schedule through the websocket.
            // 4. Server responds to the transform PUT request.
            // 5. Client receives the version response, and end up here.
            //
            // So, to be sure, perform a garbage collection here.
            newPendingTransforms = reject(
                newPendingTransforms,
                transformIsOutdated(throwOnUndefined(state.schedule, 'Expected schedule to be defined').getVersion())
            )

            return {
                ...state,
                pendingTransforms: newPendingTransforms
            }
        }
        case ScheduleActionTypes.END_DRAG_LECTURE: {
            return {
                ...state,
                problematicTimeslotsForDraggedLecture: []
            }
        }
        case ScheduleActionTypes.FETCHED_PROBLEMATIC_TIMESLOTS_FOR_LECTURE: {
            return {
                ...state,
                problematicTimeslotsForDraggedLecture: action.payload
            }
        }
        case ScheduleActionTypes.END_DRAG_COURSE_ROUND: {
            return {
                ...state,
                problematicTeachersForDraggedCr: []
            }
        }
        case ScheduleActionTypes.FETCHED_PROBLEMATIC_TEACHERS_FOR_CR: {
            return {
                ...state,
                problematicTeachersForDraggedCr: action.payload
            }
        }
        case ScheduleActionTypes.SUBSCRIBE: {
            const newState = {
                ...state,
                subscriptionCount: state.subscriptionCount + 1
            }

            // console.log('[sse reducer] action = SUBSCRIBE. new subscriptionCount: ' + newState.subscriptionCount)
            return newState
        }
        case ScheduleActionTypes.UNSUBSCRIBE: {
            const newState = {
                ...state,
                subscriptionCount: state.subscriptionCount - 1
            }

            // console.log('[sse reducer] action = UNSUBSCRIBE. subscriptionCount: ' + newState.subscriptionCount)
            return newState
        }
        default: {
            return state
        }
    }
}
