import { Space } from 'antd'
import { ClearTableContentButton } from './ClearTableContentButton'
import { ResetTableContentButton } from './ResetTableContentButton'
import { SaveTableContentButton } from './SaveTableContentButton'
import classes from './style.module.css'
import { GridActionsFooterProps } from './types'

export function GridActionsFooter({ handleClearTableContent, onReset, onSave, saveEnabled }: GridActionsFooterProps) {
    return (
        <div className={classes.gridActionsFooter}>
            <Space>
                <ClearTableContentButton onClear={handleClearTableContent} />
                <ResetTableContentButton onReset={onReset} />
            </Space>
            <SaveTableContentButton onSave={onSave} enabled={saveEnabled} />
        </div>
    )
}
