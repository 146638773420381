import { Alert, Radio, Space } from 'antd'
import { ScheduleType } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import type { ScheduleTypeInputProps } from './types'

export const ScheduleTypeInput = ({ onChange, value }: ScheduleTypeInputProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    const onChangeWrapper = (newScheduleType: ScheduleType) => {
        if (typeof onChange === 'function') {
            onChange(newScheduleType)
        }
    }

    const options = [
        { value: ScheduleType.GR, label: t('ElementarySchool') },
        { value: ScheduleType.GY, label: t('HighSchool') }
    ]

    return (
        <Space direction="vertical">
            <Radio.Group
                options={options}
                onChange={(e) => onChangeWrapper(e.target.value as ScheduleType)}
                value={value}
                optionType="button"
            />
            {schedule.getCourses().length > 0 && value === ScheduleType.GR && (
                <Alert showIcon type="warning" message={t('HiddenCoursesInElementaryMode')} />
            )}
        </Space>
    )
}
