import { Card } from 'antd'
import { ReactNode } from 'react'
import { tableCardBodyStyle, tableCardHeaderStyle } from './constants'
import classes from './style.module.css'

type TableCardProps = {
    title: ReactNode
    children: ReactNode
}

export default function TableCard({ children, title }: TableCardProps) {
    return (
        <Card
            className={classes.tableCard}
            styles={{
                header: tableCardHeaderStyle,
                body: tableCardBodyStyle
            }}
            title={title}
        >
            {children}
        </Card>
    )
}
