import { PlusOutlined } from '@ant-design/icons'
import { IScheduleTransform } from 'common-api'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuid } from 'uuid'
import type { LectureId } from '../../../../commonTypes'
import Button from '../../../../components/Button'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { useLocalSchedule } from '../../../../store/schedule/hooks'

type NewLectureButtonProps = {
    onNewLecture: (newLectureId: LectureId) => void
}

export const NewLectureButton = (props: NewLectureButtonProps) => {
    const schedule = useLocalSchedule()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const handleNewLecture = () => {
        const newLectureId = uuid()
        dispatch(
            locallyTriggeredScheduleTransform(
                IScheduleTransform.eventGroupTransform({
                    newEventGroup: {
                        eventGroupId: uuid(),
                        timeslotPinned: false,
                        displayName: 'New Event Group',
                        lectures: [
                            {
                                lectureId: newLectureId,
                                courseRoundId: schedule.getCourseRounds()[0].getCourseRoundId(),
                                weekSelection: {
                                    weekSelectionPresetId: schedule.getWeekSelectionPresets()[0].weekSelectionPresetId,
                                    includes: [],
                                    excludes: []
                                },
                                relStartTimeInMinutes: 0,
                                durationInMinutes: 45,
                                requiredRoomAttributes: [],
                                roomPinned: false,
                                roomAssignments: [
                                    {
                                        pinned: false,
                                        requiredAttributes: []
                                    }
                                ],
                                labels: []
                            }
                        ]
                    }
                })
            )
        )
        props.onNewLecture(newLectureId)
    }

    return (
        <Button variant="primary" size="sm" startIcon={<PlusOutlined />} onClick={handleNewLecture}>
            {t('AddLecture')}
        </Button>
    )
}
