import { Select } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SearchPopup from '../../../../components/SearchPopup'
import { filterOnLabel } from '../../../../components/SearchPopupList/util'
import type { ApplicationState } from '../../../../store'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { userAddedScheduleSelector, userRemovedScheduleSelector } from '../../../../store/scheduleselector/actions'
import type { ScheduleSelectionState, ScheduleSelector } from '../../../../store/scheduleselector/types'
import { visitScheduleSelector } from '../../../../store/scheduleselector/types'
import { toTranslate } from '../../../../utils/miscUtil'
import { useFiltering } from '../FilterInput/hooks'
import {
    getAllOption,
    getCourseRoundOptions,
    getRoomOptions,
    getStudentGroupOptions,
    getTeacherOptions
} from './selectors'
import type { OptionGroup, ScheduleSearchSelectorProps, SelectorsByOptId } from './types'
import {
    courseRoundOptionValue,
    optionFilter,
    roomOptionValue,
    studentGroupOptionValue,
    teacherOptionValue
} from './utils'

export function ScheduleSearchSelector({ isUberSearch = true }: ScheduleSearchSelectorProps) {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const [open, setOpen] = useState(false)
    const filtering = useFiltering()
    const { t } = useTranslation()

    const scheduleSelection = useSelector<ApplicationState, ScheduleSelectionState>((state) => state.scheduleSelection)

    const currentlySelectedValues = scheduleSelection.selectedSchedules.map((ss) => {
        return visitScheduleSelector(ss, {
            studentGroupSelector: (s) => studentGroupOptionValue(s.studentGroupId),
            teacherSelector: (s) => teacherOptionValue(s.teacherId),
            courseRoundSelector: (s) => courseRoundOptionValue(s.courseRoundId),
            roomSelector: (s) => roomOptionValue(s.roomId),
            allSelector: () => 'all'
        })
    })

    const teacherOptions = getTeacherOptions(schedule, currentlySelectedValues, isUberSearch)
    const studentGroupOptions = getStudentGroupOptions(schedule, currentlySelectedValues, isUberSearch)
    const courseRoundOptions = getCourseRoundOptions(schedule, currentlySelectedValues, isUberSearch)
    const roomOptions = getRoomOptions(schedule, currentlySelectedValues, isUberSearch)
    const allOption = getAllOption(t)

    function onDeselect(optionValue: string) {
        const selector = selectorsByOptId[optionValue]

        if (selector) {
            dispatch(userRemovedScheduleSelector(selector))
        }
    }

    function onSelect(optionValue: string) {
        setOpen(false)
        const selector = selectorsByOptId[optionValue]

        if (selector) {
            return dispatch(userAddedScheduleSelector(selector))
        }
    }

    // Create selector lookup dict
    const allOptions = [...teacherOptions, ...studentGroupOptions, ...courseRoundOptions, ...roomOptions, allOption]
    const selectorsByOptId: SelectorsByOptId = Object.assign(
        {},
        ...allOptions.map((opt) => ({ [opt.value]: opt.selector as ScheduleSelector }))
    )

    const options: OptionGroup[] = [
        {
            label: t('Teacher'),
            options: teacherOptions.filter((opt) => filterOnLabel(opt.displayLabel ?? opt.label, filtering.filterText))
        },
        {
            label: toTranslate('Klasser'),
            options: studentGroupOptions.filter((opt) => filterOnLabel(opt.label, filtering.filterText))
        },
        {
            label: toTranslate('Kursomgångar'),
            options: courseRoundOptions.filter((opt) => filterOnLabel(opt.label, filtering.filterText))
        },
        {
            label: toTranslate('Salar'),
            options: roomOptions.filter((opt) => filterOnLabel(opt.label, filtering.filterText))
        },
        { label: t('Other'), options: [allOption] }
    ]

    if (isUberSearch) {
        return (
            <SearchPopup
                onSearch={filtering.setFilterText}
                value={currentlySelectedValues}
                filtering={filtering}
                options={options}
                onSelect={onSelect}
                onDeselect={onDeselect}
            />
        )
    }

    return (
        <Select
            placeholder={t('SearchPlaceholders.SearchSchedule')}
            style={{ width: '100%', maxWidth: '30em' }}
            filterOption={optionFilter}
            onDropdownVisibleChange={setOpen}
            open={open}
            onSelect={onSelect}
            onDeselect={onDeselect}
            maxTagCount={5}
            showSearch
            options={options}
        />
    )
}
