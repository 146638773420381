import {
    DurationProblemType,
    INoRoomSetProblem,
    IProblem,
    IRequiredRoomAttributeProblem,
    IRoomConflictProblem,
    ISameCourseMultipleTimesOnSameDayProblem,
    IScheduleOverrunProblem,
    IStudentGroupBreakProblem,
    IStudentGroupConflictProblem,
    IStudentGroupLunchProblem,
    ITeacherBreakProblem,
    ITeacherLunchProblem,
    IUnscheduledLectureProblem
} from 'common-api'
import type { TFunction } from 'i18next'
import type { LectureId } from '../../../../commonTypes'
import type { ScheduleAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { addMinutes, formatDuration, formatHHMM, seDayName } from '../../../../utils/DayAndTimeUtil'
import { AppError } from '../../../../utils/errorutil'
import { relatedTeachers } from '../../../../utils/problems'
import { courseRoundDisplayName, teacherDisplayName } from '../../../../utils/scheduleUtils'

const courseRoundText = (schedule: ScheduleAccessor, lectureId: LectureId, t: TFunction) => {
    if (!schedule.doesLectureIdExist(lectureId)) {
        return t('UnknownCourseRound')
    }

    return courseRoundDisplayName(schedule.findLecture(lectureId).getCourseRound(), true)
}

export const problemMessageText = (schedule: ScheduleAccessor, problem: IProblem, t: TFunction) => {
    return IProblem.visit(problem, {
        endTimeProblem: (p) =>
            `${t('LectureEndsTooLate')} ${courseRoundDisplayName(
                schedule.findLecture(p.lectureId).getCourseRound(),
                true
            )}`,

        teacherConflictProblem: (p) => {
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))
            const teacherName = schedule.findTeacher(p.teacherId).getTeacherSchoolId()

            return t('TeacherDoubleBooked', {
                teacherName,
                day,
                fromHHMM,
                toHHMM
            })
        },

        studentGroupLunchProblem: (p: IStudentGroupLunchProblem) => {
            return t('ClassHasTooShortLunch', {
                Class: schedule.findStudentGroup(p.studentGroupId).getDisplayName(),
                Day: seDayName(p.day)
            })
        },

        teacherBreakProblem: (p: ITeacherBreakProblem) => {
            const aLecture = schedule.findLecture(p.breakProblem.afterLectureId)
            const bLecture = schedule.findLecture(p.breakProblem.beforeLectureId)

            const d1 = aLecture.getDayAndTime()
            const d2 = bLecture.getDayAndTime()

            const teacherId = aLecture.getCourseRound().getTeachers()[0].getTeacherSchoolId()

            if (d1 === undefined || d2 === undefined) {
                return t('TeacherHasTooShortBreak', { teacherId })
            }

            const day = seDayName((d1 || d2).day)

            return t('TeacherHasTooShortBreakOnDay', { teacherId, day })
        },

        studentGroupBreakProblem: (p: IStudentGroupBreakProblem) => {
            // When a lecture is being dragged, it only has a (temporary) timestamp in the backend,
            // so one of the two lectures referred to in this problem can lack a day and time.
            const aLecture = schedule.findLecture(p.breakProblem.afterLectureId)
            const bLecture = schedule.findLecture(p.breakProblem.beforeLectureId)

            const d1 = aLecture.getDayAndTime()
            const d2 = bLecture.getDayAndTime()

            if (d1 === undefined && d2 === undefined) {
                console.log('Error: Both d1 and d2 are undefined. This should not happen.')

                return t('ProblemTypeLabel.StudentGroupBreakProblem')
            }

            const day = seDayName((d1 || d2)!.day)
            // TODO: Look for klasses in the overlap between sg for lecture 1 and sg for lecture 2.
            const sgName = aLecture.getCourseRound().getStudentGroup().getDisplayName()

            return t('StudentsHasTooShortBreakOnDay', { sgName, day })
        },

        roomConflictProblem: (p: IRoomConflictProblem) => {
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))
            const roomName = schedule.findRoom(p.roomId).getName()

            return t('RoomDoubleBooked', { roomName, day, fromHHMM, toHHMM })
        },

        roomReservedTimeConflictProblem: (p) => {
            const roomName = schedule.findRoom(p.roomId).getName()
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))

            return t('RoomScheduledOnBlockedTime', {
                roomName,
                day,
                fromHHMM,
                toHHMM
            })
        },

        requiredRoomAttributeProblem: (p: IRequiredRoomAttributeProblem) => {
            const roomName = schedule.findRoom(p.roomId).getName()
            const missingAttributes = p.missingAttributes

            return t('RoomMissingAttributes', { roomName, missingAttributes })
        },

        noRoomSetProblem: (p: INoRoomSetProblem) =>
            `${t('NoRoomSelected')} ${courseRoundText(schedule, p.lectureId, t)}`,

        unscheduledLectureProblem: (p: IUnscheduledLectureProblem) =>
            `${t('NotScheduled')} ${courseRoundText(schedule, p.lectureId, t)}`,

        scheduleOverrunProblem: (p: IScheduleOverrunProblem) =>
            `${t('LectureEndsTooLate')} ${courseRoundText(schedule, p.lectureId, t)}`,

        sameCourseMultipleTimesOnSameDayProblem: (p: ISameCourseMultipleTimesOnSameDayProblem) =>
            `${t('SameCourseMultipleTimesOnSameDay')} ${courseRoundText(schedule, p.lectureIds[0], t)}`,

        sameSubjectMultipleTimesOnSameDayProblem: (p) => {
            const lecture = schedule.findLecture(p.lectureIds[0])
            const sgName = lecture.getCourseRound().getStudentGroup().getDisplayName()
            const subjectName = lecture.getCourseRound().getSubject().getName()

            return t('SameSubjectMultipleTimesOnSameDay', { subjectName, sgName })
        },

        studentGroupConflictProblem: (p: IStudentGroupConflictProblem) => {
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))
            const sgNames = p.studentGroupIds
                .map((sgId) => schedule.findStudentGroup(sgId))
                .map((sg) => sg.getDisplayName())
                .join(', ')

            return t('StudentGroupsDoubleBooked', { sgNames, day, fromHHMM, toHHMM })
        },

        teacherFrameTimeProblem: (p) => {
            const teacherSchoolId = schedule.findTeacher(p.teacherId).getTeacherSchoolId()

            return t('TeacherFrameTimeExceeded', {
                teacherSchoolId,
                frameTime: formatDuration(p.frameTimeInMinutes)
            })
        },

        teacherLunchProblem: (p: ITeacherLunchProblem) => {
            const teacherSchoolId = schedule.findTeacher(p.teacherId).getTeacherSchoolId()
            const dayName = seDayName(p.day)

            return t('TeacherHasNoLunchBreak', { teacherSchoolId, dayName })
        },

        teacherReservedTimeConflictProblem: (p) => {
            const teacherSchoolId = schedule.findTeacher(p.teacherId).getTeacherSchoolId()
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))

            return t('TeacherScheduledOnBlockedTime', {
                teacherSchoolId,
                day,
                fromHHMM,
                toHHMM
            })
        },

        studentGroupReservedTimeConflictProblem: (p) => {
            const studentGroupName = schedule.findStudentGroup(p.studentGroupId).getDisplayName()
            const day = seDayName(p.dayAndTime.day)
            const fromHHMM = formatHHMM(p.dayAndTime)
            const toHHMM = formatHHMM(addMinutes(p.dayAndTime, p.durationInMinutes))

            return t('ProblemTypeLabel.StudentGroupReservedTimeConflictProblem', {
                studentGroupName,
                day,
                fromHHMM,
                toHHMM
            })
        },

        lectureDurationProblem: (p) => {
            const adjective = p.type === DurationProblemType.TOO_SHORT ? t('Short') : t('Long')

            return `${t('LectureIsToo')} ${adjective}: ${courseRoundText(schedule, p.lectureId, t)}`
        },

        totalCourseRoundDuration: (p) => {
            return t('ProblemTypeLabel.CourseRoundDurationProblem', {
                courseName: schedule.findCourseRound(p.courseRoundId).getDisplayName(),
                type: p.type === DurationProblemType.TOO_LONG ? t('Many') : t('Few')
            })
        },

        unassignedCourseRoundProblem: (p) => {
            return t('ProblemTypeLabel.UnAssignedCourseRoundProblemLabel', {
                courseName: schedule.findCourseRound(p.courseRoundId).getDisplayName()
            })
        },

        teacherNotQualifiedProblem: (p) => {
            return t('ProblemTypeLabel.TeacherNotQualifiedProblem', {
                teacherName: teacherDisplayName(schedule.findTeacher(p.teacherId)),
                subjectCode: schedule.findCourseRound(p.courseRoundId).getSubject().getCode()
            })
        },

        teacherOverloadProblem: (p) => {
            const teacherName = teacherDisplayName(schedule.findTeacher(p.teacherId))
            const prefix = t('ProblemTypeLabel.TeacherOverloadProblem.Prefix', { teacherName })
            const vtSuffix = t('ProblemTypeLabel.TeacherOverloadProblem.SpringSuffix', { hours: p.springOverloadHours })
            const htSuffix = t('ProblemTypeLabel.TeacherOverloadProblem.FallSuffix', { hours: p.fallOverloadHours })
            const and = t('ProblemTypeLabel.TeacherOverloadProblem.And')

            return (
                prefix +
                (p.fallOverloadHours > 0 && p.springOverloadHours > 0
                    ? `${htSuffix} ${and} ${vtSuffix}`
                    : p.fallOverloadHours > 0
                      ? htSuffix
                      : vtSuffix)
            )
        },

        unknown: (problem: IProblem) => {
            throw new AppError('Failed to render schedule problem type', { problem })
        }
    })
}

export const problemTypeLabel = (problem: IProblem, t: TFunction) =>
    IProblem.visit<string>(problem, {
        studentGroupReservedTimeConflictProblem: () => t('ProblemTypeLabel.StudentGroupReservedTimeConflictProblem'),
        endTimeProblem: () => t('ProblemTypeLabel.EndTimeProblem'),
        teacherConflictProblem: () => t('ProblemTypeLabel.TeacherConflictProblem'),
        studentGroupLunchProblem: () => t('ProblemTypeLabel.StudentGroupLunchProblem'),
        teacherBreakProblem: () => t('ProblemTypeLabel.TeacherBreakProblem'),
        studentGroupBreakProblem: () => t('ProblemTypeLabel.StudentGroupBreakProblem'),
        roomConflictProblem: () => t('ProblemTypeLabel.RoomConflictProblem'),
        roomReservedTimeConflictProblem: () => t('ProblemTypeLabel.RoomReservedTimeConflictProblem'),
        requiredRoomAttributeProblem: () => t('ProblemTypeLabel.RequiredRoomAttributeProblem'),
        noRoomSetProblem: () => t('ProblemTypeLabel.NoRoomSetProblem'),
        unscheduledLectureProblem: () => t('ProblemTypeLabel.UnscheduledLectureProblem'),
        scheduleOverrunProblem: () => t('ProblemTypeLabel.ScheduleOverrunProblem'),
        sameCourseMultipleTimesOnSameDayProblem: () => t('ProblemTypeLabel.SameCourseMultipleTimesOnSameDayProblem'),
        sameSubjectMultipleTimesOnSameDayProblem: () => t('ProblemTypeLabel.SameSubjectMultipleTimesOnSameDayProblem'),
        studentGroupConflictProblem: () => t('ProblemTypeLabel.StudentGroupConflictProblem'),
        teacherFrameTimeProblem: () => t('ProblemTypeLabel.TeacherFrameTimeProblem'),
        teacherLunchProblem: () => t('ProblemTypeLabel.TeacherLunchProblem'),
        teacherReservedTimeConflictProblem: () => t('ProblemTypeLabel.TeacherReservedTimeConflictProblem'),
        lectureDurationProblem: (p) => {
            const type = p.type === DurationProblemType.TOO_SHORT ? 'TooShort' : 'TooLong'

            return t(`ProblemTypeLabel.LectureDurationProblem.${type}`)
        },
        totalCourseRoundDuration: (p) => {
            const type = p.type === DurationProblemType.TOO_LONG ? 'TooLong' : 'TooShort'

            return t(`ProblemTypeLabel.TotalCourseRoundDuration.${type}`)
        },
        unassignedCourseRoundProblem: () => t('ProblemTypeLabel.UnassignedCourseRoundProblem'),
        teacherNotQualifiedProblem: () => t('ProblemTypeLabel.TeacherNotQualifiedProblemLabel'),
        teacherOverloadProblem: () => t('ProblemTypeLabel.TeacherOverloadProblemLabel'),
        unknown: (problem) => {
            throw new AppError('Failed to render schedule problem type', { problem })
        }
    })

export const teacherLabels = (problem: IProblem, schedule: ScheduleAccessor) =>
    relatedTeachers(schedule, problem)
        .map((tid) => schedule.findTeacher(tid))
        .map((t) => t.getTeacherSchoolId())
