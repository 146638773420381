import { Flex, Select, Space } from 'antd'
import type { IWeekSelection } from 'common-api'
import { range, without } from 'lodash'
import { useState, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import type { WeekSelectionPresetId } from '../../commonTypes'
import { useLocalSchedule } from '../../store/schedule/hooks'

const { Option } = Select

type WeekSelectionInputProps = {
    value?: IWeekSelection
    onChange?: (newValue: IWeekSelection) => void
    layout: 'horizontal' | 'vertical'
}

export const WeekSelectionInput = ({ value, onChange, layout }: WeekSelectionInputProps) => {
    const { t } = useTranslation()
    const includes = value?.includes || []
    const excludes = value?.excludes || []
    const [showIncludes, setShowIncludes] = useState(includes.length > 0)
    const [showExcludes, setShowExcludes] = useState(excludes.length > 0)
    const schedule = useLocalSchedule()

    const weekSelectionPresetId =
        value?.weekSelectionPresetId || schedule.getWeekSelectionPresets()[0].weekSelectionPresetId

    const onChangeWrapper = (
        newWeekSelectionPresetId: WeekSelectionPresetId = weekSelectionPresetId,
        newIncludes: number[] = includes,
        newExcludes: number[] = excludes
    ): void => {
        if (typeof onChange === 'function') {
            onChange({
                weekSelectionPresetId: newWeekSelectionPresetId,
                includes: newIncludes,
                excludes: newExcludes
            })
        }
    }

    const inPreset = schedule.findWeekSelectionPreset(weekSelectionPresetId).weeks.toSorted((w1, w2) => w1 - w2)

    const excludeOptions = inPreset.map((w) => ({ label: `${w}`, value: w }))
    const includeOptions = without(range(1, 52), ...inPreset).map((w) => ({
        label: `${w}`,
        value: w
    }))

    const presetSelect = (
        <Select
            style={{
                flexGrow: 1,
                minWidth: `${10 + (!showIncludes ? 3.5 : 0) + (!showExcludes ? 3.5 : 0)}em`
            }}
            onChange={(value) => {
                setShowIncludes(false)
                setShowExcludes(false)
                onChangeWrapper(value, [], [])
            }}
            value={weekSelectionPresetId}
            labelRender={(strValue) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{strValue.label}</div>
                        <Space>
                            <span>
                                {!showExcludes && (
                                    <a
                                        onMouseDown={(e) => {
                                            e.stopPropagation()
                                        }}
                                        onClick={() => {
                                            setShowExcludes(true)
                                        }}
                                    >
                                        {t('Except')}
                                    </a>
                                )}
                                {!showIncludes && !showExcludes && <span style={{ color: '#ccc' }}>{' / '}</span>}
                                {!showIncludes && (
                                    <a
                                        onMouseDown={(e) => {
                                            e.stopPropagation()
                                        }}
                                        onClick={() => {
                                            setShowIncludes(true)
                                        }}
                                    >
                                        {t('With')}
                                    </a>
                                )}
                            </span>
                            {(!showIncludes || !showIncludes) && <span style={{ color: '#ccc' }}>|</span>}
                        </Space>
                    </div>
                )
            }}
        >
            {schedule.getWeekSelectionPresets().map((preset) => (
                <Option key={preset.weekSelectionPresetId}>{preset.displayName}</Option>
            ))}
        </Select>
    )

    const excludesInput = (
        <Select
            style={{ minWidth: '7em' }}
            value={excludes}
            mode="multiple"
            allowClear
            options={excludeOptions}
            onChange={(newExcludes) => {
                onChangeWrapper(undefined, undefined, newExcludes)
            }}
        />
    )

    const includesInput = (
        <Select
            style={{ minWidth: '7em' }}
            value={includes}
            mode="multiple"
            allowClear
            options={includeOptions}
            onChange={(newIncludes) => {
                onChangeWrapper(undefined, newIncludes, undefined)
            }}
        />
    )

    const includeExclude = (label: string, input: ReactNode) =>
        layout === 'vertical' ? (
            <Flex style={{ alignItems: 'center', gap: 8, width: '100%' }}>
                <div>...{label}</div>
                <div style={{ flex: 1 }}>{input}</div>
            </Flex>
        ) : (
            <>
                <div>{label}</div>
                <div style={{ flex: 1 }}>{input}</div>
            </>
        )

    return (
        <Flex style={{ width: '100%', alignItems: 'center', gap: 8 }} vertical={layout === 'vertical'}>
            {presetSelect}
            {showExcludes && includeExclude(t('Without'), excludesInput)}
            {showIncludes && includeExclude(t('With'), includesInput)}
        </Flex>
    )
}
