import type { CourseRoundId } from '../../commonTypes'
import { colorByType } from '../../pages/schedule/components/util'
import { useLocalSchedule } from '../../store/schedule/hooks'
import styled from '../../utils/styled'

interface CourseRoundProps {
    courseRoundId: CourseRoundId
    selected?: boolean
    isInFilter?: boolean
}

export const CourseRoundCard = (props: CourseRoundProps) => {
    const schedule = useLocalSchedule()
    const courseRound = schedule.findCourseRound(props.courseRoundId)

    return (
        <Card
            color={colorByType(courseRound.getSubject().getCode())}
            selected={props.selected}
            isInFilter={props.isInFilter}
        >
            <strong>{courseRound.getDisplayName()}</strong>
            <div>{courseRound.getTotalHours()}h</div>
            <div>{courseRound.getCourse()?.getCode() || courseRound.getSubject().getCode()}</div>
        </Card>
    )
}
interface CardProps {
    color: string
    selected?: boolean
    isInFilter?: boolean
}

export const Card = styled('div')<CardProps>`
    background: ${(props) => props.color};
    border: ${(props) =>
        props.selected
            ? '2px solid var(--color-border-feedback-active)'
            : '1px solid var(--color-border-neutral-container-darkest)'};
    overflow: hidden;
    height: 100%;
    font-size: 0.7em;
    padding: 0.2em;
    opacity: ${(props) => (props.isInFilter ? 1 : 0.5)};
`
