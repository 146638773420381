import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const StudentGroupLabelFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const allLabelFilters = schedule.getStudentGroupLabels().map((l) => ({ value: l, text: l }))

    return (
        <FilterableFilterDropdown {...props} allFilters={allLabelFilters} noKeysAvailableDescription={t('NoLabels')} />
    )
}
