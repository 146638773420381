import { message } from 'antd'
import { trim, uniqBy } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { undefinedToNull } from '../../../components/event-groups/utils'
import { Endpoints } from '../../../services/Endpoints'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { AppError } from '../../../utils/errorutil'
import {
    courseRoundToCourseRoundWithDependencies,
    createDstCourse,
    deleteDstCourse,
    idForCourseRound,
    listDstCourses,
    nameForCourseRound,
    updateDstCourse
} from '../courses'
import { ExportError } from '../export-util'
import { createDstGroup, deleteDstGroup, listDstGroups, updateDstGroup } from '../groups'
import {
    createDstLesson,
    deleteDstLesson,
    lectureToLectureWithDependencies,
    listDstLessons,
    updateDstLesson
} from '../lessons'
import { createDstPeriod, createUpdateDstPeriod, deleteDstPeriod, listDstPeriods } from '../periods'
import { createDstRoom, deleteDstRoom, listDstRooms, updateDstRoom } from '../rooms'
import { createDstTeacher, deleteDstTeacher, listDstTeachers, updateDstTeacher } from '../teachers'
import { exportEntities } from '../utils/exportEntities'

export interface UseExportScheduleResult {
    selectedDstScheduleId: string | undefined
    setSelectedDstScheduleId: (id: string | undefined) => void
    newScheduleTitle: string
    setNewScheduleTitle: (title: string) => void
    exportInProgress: boolean
    exportMessage: string
    exportScheduleWithProgressWrapper: () => void
}

export function useExportSchedule(): UseExportScheduleResult {
    const [selectedDstScheduleId, setSelectedDstScheduleId] = useState<string | undefined>(undefined)
    const [newScheduleTitle, setNewScheduleTitle] = useState<string>('')
    const [exportInProgress, setExportInProgress] = useState(false)
    const [exportMessage, setExportMessage] = useState('')

    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const settings = schedule.getSettings()

    const createNewSchedule = async () => {
        const defaultEventType = await Endpoints.meitnerApi
            .scheduleServiceListEventTypes({ query: {} })
            .then((response) => {
                console.log('Found these event types: ', response.event_types)

                return response.event_types.find((et) => et.is_default)
            })

        if (defaultEventType === undefined) {
            throw new ExportError(t('Errors.NoSuitableEventType'))
        }

        return await Endpoints.meitnerApi
            .scheduleServiceCreate({
                event_type_id: defaultEventType.id,
                start_date: `${schedule.getSettings().schoolYear}-05-31`,
                end_date: `${schedule.getSettings().schoolYear + 1}-05-31`,
                source: '',
                title: newScheduleTitle
            })
            .then((response) => response.created.id!)
    }

    const exportSchedule = async () => {
        if (selectedDstScheduleId === undefined) {
            throw new AppError('Expected dstScheduleId to be set.')
        }

        const dstScheduleId = selectedDstScheduleId !== '' ? selectedDstScheduleId : await createNewSchedule()

        const courseRoundsWithDeps = uniqBy(
            schedule.getCourseRounds().map(courseRoundToCourseRoundWithDependencies),
            idForCourseRound
        )

        const seenNames = new Set<string>()
        const duplicateNames: string[] = []
        for (const name of courseRoundsWithDeps.map(nameForCourseRound).map(trim)) {
            if (seenNames.has(name)) {
                duplicateNames.push(name)
            } else {
                seenNames.add(name)
            }
        }

        if (duplicateNames.length !== 0) {
            throw new ExportError(
                (
                    <div>
                        {t('DuplicateNamesInSubjectsCourses')}
                        <ul>
                            {duplicateNames.map((dup, index) => (
                                <li key={index} style={{ textAlign: 'left' }}>
                                    {dup}
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            )
        }

        // Rooms
        setExportMessage(t('TransferStatus.Rooms'))
        const roomIdsMap = await exportEntities(
            dstScheduleId,
            (srcRoom) => srcRoom.roomId,
            (dstRoom) => undefinedToNull(dstRoom.external_id),
            (dstRoom) => dstRoom.id!,
            () => schedule.getRooms().map((r) => r.getConjureObject()),
            listDstRooms,
            createDstRoom,
            deleteDstRoom,
            updateDstRoom
        )

        // Teachers
        setExportMessage(t('TransferStatus.Teachers'))
        const teacherIdsMap = await exportEntities(
            dstScheduleId,
            (srcTeacher) => srcTeacher.teacherId,
            (dstTeacher) => undefinedToNull(dstTeacher.external_id),
            (dstTeacher) => dstTeacher.id!,
            () => schedule.getTeachers().map((t) => t.getConjureObject()),
            listDstTeachers,
            createDstTeacher,
            deleteDstTeacher,
            updateDstTeacher
        )

        // Periods
        setExportMessage(t('TransferStatus.Periods'))
        const periodIdsMap = await exportEntities(
            dstScheduleId,
            (srcWsp) => srcWsp.weekSelectionPresetId,
            (dstPeriod) => undefinedToNull(dstPeriod.external_id),
            (dstPeriod) => dstPeriod.id!,
            () => schedule.getWeekSelectionPresets(),
            listDstPeriods,
            createDstPeriod(settings.schoolYear, settings.schoolDays),
            deleteDstPeriod,
            createUpdateDstPeriod(settings.schoolYear, settings.schoolDays),
            true
        )

        // Groups
        setExportMessage(t('TransferStatus.StudentGroups'))
        const groupIdsMap = await exportEntities(
            dstScheduleId,
            (srcSg) => srcSg.studentGroupId,
            (dstGroup) => undefinedToNull(dstGroup.external_id),
            (dstGroup) => dstGroup.id!,
            () => schedule.getStudentGroups().map((sg) => sg.getConjureObject()),
            listDstGroups,
            createDstGroup,
            deleteDstGroup,
            updateDstGroup
        )

        // Courses
        setExportMessage(t('TransferStatus.Courses'))
        const courseIdsMap = await exportEntities(
            dstScheduleId,
            idForCourseRound,
            (dstCourse) => undefinedToNull(dstCourse.external_id),
            (dstCourse) => dstCourse.id!,
            () => courseRoundsWithDeps,
            listDstCourses,
            createDstCourse,
            deleteDstCourse,
            updateDstCourse
        )

        // Lessons
        setExportMessage(t('TransferStatus.Lectures'))
        const lecturesWithDeps = schedule.getLectures().map(lectureToLectureWithDependencies)
        await exportEntities(
            dstScheduleId,
            (srcLecture) => srcLecture.lecture.lectureId,
            (dstLesson) => undefinedToNull(dstLesson.external_id),
            (dstLesson) => dstLesson.id!,
            () => lecturesWithDeps,
            listDstLessons,
            createDstLesson(roomIdsMap, groupIdsMap, periodIdsMap, courseIdsMap, teacherIdsMap),
            deleteDstLesson,
            updateDstLesson(roomIdsMap, groupIdsMap, periodIdsMap, courseIdsMap, teacherIdsMap)
        )
    }

    const exportScheduleWithProgressWrapper = () => {
        setExportInProgress(true)
        exportSchedule()
            .then(() => {
                setExportInProgress(false)
                setExportMessage('')
                setNewScheduleTitle('')

                return message.success(t('TransferComplete'))
            })
            .catch((exportError) => message.error(exportError.content))
            .finally(() => {
                setExportMessage('')
                setExportInProgress(false)
            })
    }

    return {
        selectedDstScheduleId,
        setSelectedDstScheduleId,
        newScheduleTitle,
        setNewScheduleTitle,
        exportInProgress,
        exportMessage,
        exportScheduleWithProgressWrapper
    }
}
