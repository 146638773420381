import { SaveOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { SaveTableContentButtonProps } from './types'

export const SaveTableContentButton = ({ enabled, onSave }: SaveTableContentButtonProps) => {
    const { t } = useTranslation()

    return (
        <Button icon={<SaveOutlined />} type="primary" onClick={onSave} disabled={!enabled}>
            {t('Save')}
        </Button>
    )
}
