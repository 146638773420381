import { PushpinFilled, PushpinOutlined } from '@ant-design/icons'

type TogglePinProps = {
    isPinned: boolean
    onChange: (newIsPinned: boolean) => void
}

export const TogglePin = (props: TogglePinProps) => {
    const PinIcon = props.isPinned ? PushpinFilled : PushpinOutlined

    return <PinIcon onClick={() => props.onChange(!props.isPinned)} />
}
