import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'
import { useSchedule } from '../../../store/schedule/hooks'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const LectureLabelFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const allLabelFilters = schedule
        .getLectures()
        .flatMap((l) => l.getLabels())
        .map((l) => ({
            value: l,
            text: l
        }))
    const { t } = useTranslation()

    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allLabelFilters}
            noKeysAvailableDescription={t('NoLectures')}
        />
    )
}
