import { useState } from 'react'
import { isSubsequenceCase, isSubstringCase } from '../ScheduleSearchSelector/searchUtil'
import type { FilteringState } from './types'

export const useFiltering = (): FilteringState => {
    const [filterText, setFilterText] = useState('')
    const [filterHighlighting, setFilterHighlighting] = useState(true)
    const [caseSensitive, setCaseSensitive] = useState(false)
    const [subseq, setSubseq] = useState(false)

    return {
        filterText,
        setFilterText,
        filterHighlighting,
        setFilterHighlighting,
        caseSensitive,
        setCaseSensitive,
        subseq,
        setSubseq,
        isMatch: (text: string) =>
            subseq
                ? isSubsequenceCase(filterText, text, caseSensitive)
                : isSubstringCase(filterText, text, caseSensitive)
    }
}
