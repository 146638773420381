import { PlusOutlined } from '@ant-design/icons'
import { List, Space, Switch } from 'antd'
import type {
    IBeforeAfterMinBreakThresholds,
    IHardSoftThresholds,
    IMinBreakThresholdComponents,
    IMinBreakThresholds
} from 'common-api'
import type { TFunction } from 'i18next'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { nullToUndefined } from '../../utils/miscUtil'
import { AddBreakRuleDialog } from './AddBreakRuleDialog'
import type { BreakRuleFormValues } from './AddBreakRuleDialog/types'
import { NoCustomBreakSettings } from './NoCustomBreakSettings'

export const EMPTY_MIN_BREAK_THRESHOLDS: IMinBreakThresholds = {
    students: {
        beforeLecture: {
            defaultMinutes: {},
            onRoomChangeMinutes: {},
            onSubjectChangeMinutes: {}
        },
        afterLecture: {
            defaultMinutes: {},
            onRoomChangeMinutes: {},
            onSubjectChangeMinutes: {}
        }
    },
    teachers: {
        beforeLecture: {
            defaultMinutes: {},
            onRoomChangeMinutes: {},
            onSubjectChangeMinutes: {}
        },
        afterLecture: {
            defaultMinutes: {},
            onRoomChangeMinutes: {},
            onSubjectChangeMinutes: {}
        }
    }
}

export type StudentsOrTeachers = 'students' | 'teachers'
export type BeforeOrAfter = 'before' | 'after'
export type BreakCondition = 'default' | 'roomChange' | 'subjectChange'

export type BreakSetting = {
    studentsOrTeachers: StudentsOrTeachers
    beforeOrAfter: BeforeOrAfter
    condition: BreakCondition
    hardMinutes: number | undefined
    softMinutes: number | undefined
}

const conditionPart = (condition: BreakCondition, thr: IHardSoftThresholds) => ({
    condition,
    hardMinutes: nullToUndefined(thr.hardMinutes),
    softMinutes: nullToUndefined(thr.softMinutes)
})

const beforeAfterPart = (beforeOrAfter: BeforeOrAfter, thr: IMinBreakThresholdComponents) => [
    { beforeOrAfter, ...conditionPart('default', thr.defaultMinutes) },
    { beforeOrAfter, ...conditionPart('roomChange', thr.onRoomChangeMinutes) },
    {
        beforeOrAfter,
        ...conditionPart('subjectChange', thr.onSubjectChangeMinutes)
    }
]

const studentTeacherPart = (studentsOrTeachers: StudentsOrTeachers, thr: IBeforeAfterMinBreakThresholds) => [
    ...beforeAfterPart('before', thr.beforeLecture).map((x) => ({
        studentsOrTeachers,
        ...x
    })),
    ...beforeAfterPart('after', thr.afterLecture).map((x) => ({
        studentsOrTeachers,
        ...x
    }))
]

const thresholdsToBreakSettings = (thresholds: IMinBreakThresholds | undefined): BreakSetting[] =>
    thresholds === undefined
        ? []
        : [
              ...studentTeacherPart('students', thresholds.students),
              ...studentTeacherPart('teachers', thresholds.teachers)
          ].filter((setting) => setting.hardMinutes !== undefined)

const breakSettingDisplayString = (setting: BreakSetting, t: TFunction) => {
    const words = [
        setting.studentsOrTeachers === 'students' ? t('Students') : t('Teachers'),
        setting.beforeOrAfter === 'before' ? t('Before') : t('After'),
        `${t('LectureSmall')}:`,
        ...(setting.condition === 'default'
            ? []
            : [`( ${t('At')}${setting.condition === 'roomChange' ? t('RoomChangeSmall') : t('SubjectChangeSmall')})`]),
        `${t('Atleast')} ${setting.hardMinutes} ${t('Minutes')}`,
        ...(setting.softMinutes === undefined ? [] : [`, ${t('Preferably')} ${setting.softMinutes} ${t('Minutes')}`])
    ]

    return words.join(' ')
}

type MinBreakThresholdsInputProps = {
    value?: IMinBreakThresholds
    onChange?: (newMinBreakThresholds: IMinBreakThresholds | undefined) => void
    collapseEmptyToUndefined?: boolean
}

const mergeHardSoft = (
    override: boolean,
    vals: IHardSoftThresholds,
    { hardThreshold, softThreshold }: BreakRuleFormValues
): IHardSoftThresholds =>
    override
        ? {
              softMinutes: softThreshold,
              hardMinutes: hardThreshold
          }
        : vals

const mergeComponents = (
    override: boolean,
    vals: IMinBreakThresholdComponents,
    { condition, ...ruleRest }: BreakRuleFormValues
): IMinBreakThresholdComponents =>
    override
        ? {
              defaultMinutes: mergeHardSoft(condition === 'default', vals.defaultMinutes, ruleRest),
              onRoomChangeMinutes: mergeHardSoft(condition === 'roomChange', vals.onRoomChangeMinutes, ruleRest),
              onSubjectChangeMinutes: mergeHardSoft(
                  condition === 'subjectChange',
                  vals.onSubjectChangeMinutes,
                  ruleRest
              )
          }
        : vals

const mergeBeforeAfter = (
    override: boolean,
    vals: IBeforeAfterMinBreakThresholds,
    { beforeOrAfter, ...ruleRest }: BreakRuleFormValues
): IBeforeAfterMinBreakThresholds =>
    override
        ? {
              beforeLecture: mergeComponents(beforeOrAfter === 'before', vals.beforeLecture, ruleRest),
              afterLecture: mergeComponents(beforeOrAfter === 'after', vals.afterLecture, ruleRest)
          }
        : vals

const mergeThresholds = (
    vals: IMinBreakThresholds,
    { studentsOrTeachers, ...rest }: BreakRuleFormValues
): IMinBreakThresholds => ({
    students: mergeBeforeAfter(studentsOrTeachers === 'students', vals.students, rest),
    teachers: mergeBeforeAfter(studentsOrTeachers === 'teachers', vals.teachers, rest)
})

export const MinBreakThresholdsInput = ({
    onChange,
    value = EMPTY_MIN_BREAK_THRESHOLDS,
    collapseEmptyToUndefined = true
}: MinBreakThresholdsInputProps) => {
    const [isAddRuleDialogVisible, setIsAddRuleDialogVisible] = useState(false)
    const breakSettings = thresholdsToBreakSettings(value!)
    const { t } = useTranslation()

    return (
        <>
            <List<BreakSetting>
                style={{ backgroundColor: 'white' }}
                dataSource={breakSettings}
                pagination={false}
                bordered
                locale={{ emptyText: <NoCustomBreakSettings /> }}
                renderItem={(br, index) => (
                    <List.Item
                        actions={[
                            <a
                                key={index}
                                href="#"
                                onClick={() => {
                                    if (typeof onChange === 'function') {
                                        // Last item to be removed? Collapse to undefined thresholds
                                        if (breakSettings.length === 1 && collapseEmptyToUndefined) {
                                            onChange(undefined)

                                            return
                                        }

                                        const brEmptyVals = {
                                            ...br,
                                            hardMinutes: undefined,
                                            softMinutes: undefined
                                        }
                                        onChange(mergeThresholds(value || EMPTY_MIN_BREAK_THRESHOLDS, brEmptyVals))
                                    }
                                }}
                            >
                                {t('Remove')}
                            </a>
                        ]}
                    >
                        <List.Item.Meta title={breakSettingDisplayString(br, t)} />
                    </List.Item>
                )}
            />
            <div style={{ marginTop: '1em' }}>
                <Space>
                    <Switch />
                    <span>{t('ShowInheritedSettings')}</span>
                </Space>
            </div>
            <div style={{ marginTop: '1em' }}>
                <a href="#" onClick={() => setIsAddRuleDialogVisible(true)}>
                    <PlusOutlined /> {t('AddSetting')}
                </a>
            </div>
            <AddBreakRuleDialog
                open={isAddRuleDialogVisible}
                handleOk={(rule) => {
                    setIsAddRuleDialogVisible(false)

                    // Translate rule to something that updates value, and call onChange with new value.
                    if (typeof onChange === 'function') {
                        onChange(mergeThresholds(value || EMPTY_MIN_BREAK_THRESHOLDS, rule))
                    }
                }}
                handleCancel={() => setIsAddRuleDialogVisible(false)}
            />
        </>
    )
}
