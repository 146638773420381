import { Card, Empty, Space } from 'antd'
import classes from './style.module.css'
import { TeacherDiffCardProps } from './types'

export const TeacherDiffCard = <T,>({ icon, title, renderChange, noChangesText, changes }: TeacherDiffCardProps<T>) => (
    <Card
        title={
            <Space>
                {icon}
                {title}
            </Space>
        }
        style={{ flex: 1 }}
    >
        {changes.length > 0 ? (
            <ul className={classes.topLevelTeacherList}>{changes.map(renderChange)}</ul>
        ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={noChangesText} />
        )}
    </Card>
)
