import { useTranslation } from 'react-i18next'
import { TeacherId } from '../../../commonTypes'
import { useLocalSchedule } from '../../../store/schedule/hooks'
import { ManyToManySelector } from '../../many-to-many-selector/ManyToManySelector'
import { TeacherListTooltip } from './TeacherListTooltip'
import { MultiTeacherQualificationSelectorProps } from './types'

export const MultiTeacherQualificationSelector = ({ value, onChange }: MultiTeacherQualificationSelectorProps) => {
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    return (
        <ManyToManySelector<TeacherId, string>
            value={value}
            onChange={onChange}
            mode="multiple"
            preexistingOptionValues={schedule.getSubjects().map((s) => s.getSubjectId())}
            optionLabelFn={(subjectId) => schedule.findSubject(subjectId).getName()}
            partialCoverSuffix={(teacherIds) => (
                <TeacherListTooltip
                    title={t('ThisSubjectOnlyApplysToTheFollowingTeachers')}
                    teachers={teacherIds.map((teacherId) => schedule.findTeacher(teacherId))}
                />
            )}
        />
    )
}
