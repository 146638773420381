import { Form, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { IScheduleTransform } from 'common-api'
import { cloneDeep } from 'lodash'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { StudentGroupId } from '../../../commonTypes'
import BulkInput from '../../../components/BulkInput'
import Button from '../../../components/Button'
import type { StudentGroupAccessor } from '../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../store/schedule/actions'
import { comparing } from '../../../utils/compareUtil'
import { StudentGroupLabelSelector } from './StudentGroupLabelSelector'
import { GroupOverlapMultiInput } from './overlaps/GroupOverlapMultiInput'
import { OverlapMode } from './overlaps/OverlapMode'

type StudentGroupDetailsProps = {
    studentGroups: StudentGroupAccessor[]
}

type StudentGroupFormValues = {
    displayNames: string[]
    descriptions: string[]
    overlaps: Map<StudentGroupId, StudentGroupId[]>
    nonOverlaps: Map<StudentGroupId, StudentGroupId[]>
    labels: Map<StudentGroupId, string[]>
}

const StudentGroupDetails = ({ studentGroups }: StudentGroupDetailsProps) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [form] = useForm()

    const sortedStudentGroups = studentGroups.toSorted(comparing((sg) => sg.getDisplayName()))

    const saveStudentGroups = (formValues: StudentGroupFormValues) => {
        const transforms = sortedStudentGroups.map((studentGroup, index) =>
            IScheduleTransform.studentGroupTransform({
                newStudentGroup: {
                    ...cloneDeep(studentGroup.getConjureObject()),
                    displayName: formValues.displayNames[index],
                    description: formValues.descriptions[index],
                    overlaps: formValues.overlaps.get(studentGroup.getStudentGroupId())!,
                    nonOverlaps: formValues.nonOverlaps.get(studentGroup.getStudentGroupId())!,
                    labels: formValues.labels.get(sortedStudentGroups[index].getStudentGroupId())!
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(transforms)))
        message.success(t(studentGroups.length === 1 ? 'StudentGroupSaved' : 'StudentGroupsSaved'))
    }

    const byStudentGroupId = <T,>(sgFn: (sg: StudentGroupAccessor) => T) =>
        new Map(sortedStudentGroups.map((sg) => [sg.getStudentGroupId(), sgFn(sg)]))

    const initialValues = {
        displayNames: sortedStudentGroups.map((sg) => sg.getDisplayName()),
        descriptions: sortedStudentGroups.map((sg) => sg.getDescription()),
        overlaps: byStudentGroupId((sg) => sg.getOverlaps().map((sg) => sg.getStudentGroupId())),
        nonOverlaps: byStudentGroupId((sg) => sg.getNonOverlaps().map((sg) => sg.getStudentGroupId())),
        labels: byStudentGroupId((sg) => sg.getLabels())
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Form
                form={form}
                name="studentGroupsForm"
                initialValues={initialValues}
                onFinish={saveStudentGroups}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: '700px', width: '100%' }}
                labelWrap
                colon={false}
            >
                {/* DISPLAY NAMES */}
                <Form.Item name="displayNames" label={t('Name')}>
                    <BulkInput multiplicityMessage={t('SelectSingleStudentGroupToEditName')} />
                </Form.Item>

                {/* DESCRIPTIONS */}
                <Form.Item name="descriptions" label={t('Description')}>
                    <BulkInput useTextArea multiplicityMessage={t('SelectSingleStudentGroupToEditDescription')} />
                </Form.Item>

                {/* LABELS */}
                <Form.Item label={t('Labels')} name="labels">
                    <StudentGroupLabelSelector />
                </Form.Item>

                {/* OVERLAPPS */}
                <Form.Item name="overlaps" label={t('OverlapingStudentGroups')}>
                    <GroupOverlapMultiInput mode={OverlapMode.OVERLAP} />
                </Form.Item>

                {/* NON-OVERLAPS */}
                <Form.Item name="nonOverlaps" label={t('NonOverlapingStudentGroups')}>
                    <GroupOverlapMultiInput mode={OverlapMode.NON_OVERLAP} />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8 }}>
                    <Button variant="primary" type="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default memo(StudentGroupDetails)
