import type { SVGProps } from 'react'

const SvgDoor = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" className="door_svg__icon" viewBox="0 0 1024 1024" {...props}>
        <title>Door</title>
        <path d="M247 64c-17.7 0-32 14.3-32 32v792H85.72v72h852.56v-72H809V96c0-17.7-14.3-32-32-32H247zm40 72h450v752H287V136zm301.164 343.97v63.803h96.61v-63.802h-96.61z" />
    </svg>
)

export default SvgDoor
