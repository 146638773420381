import { NonEditableCell, RGTheme, Row } from '@silevis/reactgrid'
import { NestedStylesPartial } from '@silevis/reactgrid/dist/types/InternalModel'
import { TextCellWithValidation } from './TextCellWithValidation'

export const MIN_ROWS_TO_SHOW = 50

export const gridStyles: NestedStylesPartial<RGTheme> = {
    focusIndicator: {
        background: 'rgb(230, 244, 255, .25)'
    },
    selectionIndicator: {
        background: 'rgb(230, 244, 255, .25)'
    }
}

export const HEADER_ROW: Row = {
    rowIndex: 0,
    height: 30
}

export const DEFAULT_CONTENT_CELL = {
    Template: TextCellWithValidation,
    isFocusable: true,
    isSelectable: true,
    props: {
        style: {
            textAlign: 'left'
        }
    }
}

export const DEFAULT_HEADER_CELL = {
    Template: NonEditableCell,
    isFocusable: false,
    isSelectable: false,
    props: {
        style: {
            backgroundColor: '#f5f5f5',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold'
        }
    }
}
