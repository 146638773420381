import * as React from 'react'
import type { SVGProps } from 'react'

const SvgFileRoyalSchedule = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" className="file-royalschedule_svg__icon" viewBox="0 0 1024 1024" {...props}>
        <path d="M854.6 288.6 639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7M790.2 326H602V137.8zm1.8 562H232V136h302v216c0 23.196 18.804 42 42 42h216z" />
        <path d="M476.35 456.07a16 16 0 0 0-16.035 16.041v91.996h91.405c8.886 0 16.041 7.15 16.041 16.035v87.515h166.75a16.007 16.007 0 0 0 16.041-16.041v-179.51a16.007 16.007 0 0 0-16.041-16.041zm91.41 211.59h-91.41a16 16 0 0 1-16.035-16.041V564.11h-166.76a16 16 0 0 0-16.041 16.035v179.51a16.007 16.007 0 0 0 16.041 16.041h258.16a16.007 16.007 0 0 0 16.041-16.041z" />
    </svg>
)

export default SvgFileRoyalSchedule
