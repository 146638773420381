import { ISubject, ITeacher } from 'common-api'
import { v4 as uuid } from 'uuid'
import { splitAndCleanCommaSepList } from '../../../../../../textUtil'
import { opt } from '../../../../../../utils/miscUtil'
import { TeacherGridContentColumn, TeacherGridMetaDataColumn, TeacherGridRow } from './types'
import { isNonEmptyTeacherRow, parsePercentageStringToFraction } from './util'

const MAXIMUM_SCORE = 4
const MINIMUM_ACCEPTABLE_SCORE = 2

const matchScore = (row: TeacherGridRow, teacher: ITeacher) =>
    (teacher.teacherSchoolId === row[TeacherGridContentColumn.Signature] ? 1 : 0) +
    (teacher.firstName === row[TeacherGridContentColumn.FirstName] ? 1 : 0) +
    (teacher.lastName === row[TeacherGridContentColumn.LastName] ? 1 : 0)

// Match rows with existing teachers
const matchRowsWithExistingTeachers = (existingTeachers: ITeacher[], rows: TeacherGridRow[]) => {
    /* eslint-disable no-loop-func */

    // (There are more clever ways to implement this functionality, such as the Hungarian method, Ford-Fulkerson, or
    // Kuhn's Algorithm, but the complexity is high, and the result is rarely better than the simple greedy algorithm
    // below.)

    // Algorithm:
    // 1. Set desiredScore to MAXIMUM_SCORE
    // 2. For each (row, teacher) pair that satisfies the desiredScore:
    //    2a. Store it in result map
    //    2b. Remove the matched row and teacher
    // 4. Lower desiredScore, and repeat process until all rows are matched or MINIMUM_ACCEPTABLE_SCORE is reached.

    const result = new Map<number, ITeacher>()
    let existingTeachersLeftToMatch = [...existingTeachers]
    let rowsLeftToMatch = [...rows]
    let desiredScore = MAXIMUM_SCORE
    while (desiredScore >= MINIMUM_ACCEPTABLE_SCORE && rowsLeftToMatch.length > 0) {
        const unmatchRowsAtThisScore = []
        for (let rowToMatch of rowsLeftToMatch) {
            const i = existingTeachersLeftToMatch.findIndex((t) => matchScore(rowToMatch, t) >= desiredScore)
            if (i !== -1) {
                result.set(rowToMatch[TeacherGridMetaDataColumn.RowIndex], existingTeachersLeftToMatch[i])
                existingTeachersLeftToMatch.splice(i, 1)
            } else {
                unmatchRowsAtThisScore.push(rowToMatch)
            }
        }

        desiredScore--
        rowsLeftToMatch = unmatchRowsAtThisScore
    }

    return result
}

export const parseCommaSepSubjectList = (
    existingSubjects: ISubject[],
    commaSepSubjectNames: string
): [ISubject[], string[]] => {
    const subjectNames = splitAndCleanCommaSepList(commaSepSubjectNames)
    const foundSubjects = []
    const notFoundSubjectNames = []
    for (let subjectName of subjectNames) {
        const existingSubject = existingSubjects.find((s) => s.name.toLowerCase() == subjectName.toLowerCase())
        if (existingSubject !== undefined) {
            foundSubjects.push(existingSubject)
        } else {
            notFoundSubjectNames.push(subjectName)
        }
    }
    return [foundSubjects, notFoundSubjectNames]
}

export const parseTeacherRows = (
    existingTeachers: ITeacher[],
    existingSubjects: ISubject[],
    rowsIncludingEmpty: TeacherGridRow[]
): ITeacher[] => {
    const rows = rowsIncludingEmpty.filter(isNonEmptyTeacherRow)
    const matchedTeachers = matchRowsWithExistingTeachers(existingTeachers, rows)
    return rows.map((row) => {
        const matchingTeacher = matchedTeachers.get(row[TeacherGridMetaDataColumn.RowIndex])

        // Id
        const teacherId = opt(matchingTeacher)
            .map((t) => t.teacherId)
            .orElseGet(uuid)

        // Teacher school id
        const teacherSchoolId = row[TeacherGridContentColumn.Signature]

        // First name
        const firstName = row[TeacherGridContentColumn.FirstName]

        // Last name
        const lastName = row[TeacherGridContentColumn.LastName]

        // Qualifications
        const [subjects, _] = parseCommaSepSubjectList(existingSubjects, row[TeacherGridContentColumn.Qualifications])
        const qualifications = subjects.map((s) => s.subjectId)

        // Work percentage
        let workPercentageAsFraction = parsePercentageStringToFraction(row[TeacherGridContentColumn.WorkPercentage])
        if (Number.isNaN(workPercentageAsFraction)) {
            workPercentageAsFraction = 1
        }
        const workPercentage = Math.round(workPercentageAsFraction * 100)

        return {
            teacherId,
            teacherSchoolId,
            firstName,
            lastName,
            qualifications,
            workPercentage
        }
    })
}
