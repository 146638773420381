import { useTranslation } from 'react-i18next'
import { EntityListTooltip } from './EntityListTooltip'
import { RoomsListTooltipProps } from './types'

export const RoomsListTooltip = ({ title, rooms }: RoomsListTooltipProps) => {
    const { t } = useTranslation()

    return (
        <EntityListTooltip
            title={title}
            entities={rooms}
            entityLabelFn={(r) => r.getName()}
            entityKeyFn={(r) => r.getRoomId()}
            singularEntityLabel={t('RoomsListTooltip.Singular')}
            pluralEntityLabel={t('RoomsListTooltip.Plural')}
        />
    )
}
