import Icon, { HighlightFilled, SearchOutlined } from '@ant-design/icons'
import { Input, Space, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import SvgAa from '../../../../icons/SvgAa'
import SvgSkip from '../../../../icons/SvgSkip'
import type { FilterInputProps } from './types'

const enabledDisabledColor = (isEnabled: boolean) => (isEnabled ? '#777' : '#ccc')

export const FilterInput = ({
    filterHighlighting,
    setFilterHighlighting,
    caseSensitive,
    setCaseSensitive,
    subseq,
    setSubseq,
    filterText,
    setFilterText,
    disabled = false
}: FilterInputProps & { disabled?: boolean }) => {
    const { t } = useTranslation()

    return (
        <Input
            allowClear
            disabled={disabled}
            autoFocus
            prefix={<SearchOutlined style={{ color: '#ccc' }} />}
            placeholder={t('Filter')}
            value={filterText}
            suffix={
                <Space>
                    {disabled ? (
                        <HighlightFilled style={{ color: enabledDisabledColor(false) }} />
                    ) : (
                        <Tooltip title={t('HighlightSearchText')}>
                            <HighlightFilled
                                style={{ color: enabledDisabledColor(filterHighlighting) }}
                                onClick={() => setFilterHighlighting(!filterHighlighting)}
                            />
                        </Tooltip>
                    )}
                    {disabled ? (
                        <Icon component={SvgAa} style={{ color: enabledDisabledColor(false) }} />
                    ) : (
                        <Tooltip title={t('CaseSensitive')}>
                            <Icon
                                component={SvgAa}
                                style={{ color: enabledDisabledColor(caseSensitive) }}
                                onClick={() => setCaseSensitive(!caseSensitive)}
                            />
                        </Tooltip>
                    )}
                    {disabled ? (
                        <Icon component={SvgSkip} style={{ color: enabledDisabledColor(false) }} />
                    ) : (
                        <Tooltip title={t('AllowSkipInMatching')}>
                            <Icon
                                component={SvgSkip}
                                style={{ color: enabledDisabledColor(subseq) }}
                                onClick={() => setSubseq(!subseq)}
                            />
                        </Tooltip>
                    )}
                </Space>
            }
            onChange={(event) => setFilterText(event.target.value)}
        />
    )
}
