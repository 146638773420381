/**
 * Constant reference to `undefined` that we expect to get minified and therefore reduce total code size
 */
var __undefined = undefined;
var ScheduleService = /** @class */ (function () {
    function ScheduleService(bridge) {
        this.bridge = bridge;
    }
    /**
     * Retrieves the schedule for the given id
     */
    ScheduleService.prototype.getSchedule = function (scheduleId) {
        return this.bridge.call("ScheduleService", "getSchedule", "GET", "/schedule/{scheduleId}", __undefined, __undefined, __undefined, [
            scheduleId,
        ], __undefined, __undefined);
    };
    ScheduleService.prototype.getProblematicTimeslotsForEventGroup = function (scheduleId, eventGroupId) {
        return this.bridge.call("ScheduleService", "getProblematicTimeslotsForEventGroup", "GET", "/schedule/{scheduleId}/eventGroup/{eventGroupId}/problematicTimeslots", __undefined, __undefined, __undefined, [
            scheduleId,
            eventGroupId,
        ], __undefined, __undefined);
    };
    ScheduleService.prototype.getProblematicTeachersForCourseRound = function (scheduleId, courseRoundId) {
        return this.bridge.call("ScheduleService", "getProblematicTeachersForCourseRound", "GET", "/schedule/{scheduleId}/courseRound/{courseRoundId}/problemsByTeacher", __undefined, __undefined, __undefined, [
            scheduleId,
            courseRoundId,
        ], __undefined, __undefined);
    };
    ScheduleService.prototype.updateSchedule = function (scheduleId, transform) {
        return this.bridge.call("ScheduleService", "updateSchedule", "PUT", "/schedule/{scheduleId}/transform", transform, __undefined, __undefined, [
            scheduleId,
        ], __undefined, __undefined);
    };
    ScheduleService.prototype.loadDemoState = function (scheduleId) {
        return this.bridge.call("ScheduleService", "loadDemoState", "PUT", "/schedule/{scheduleId}/load-demo-state", __undefined, __undefined, __undefined, [
            scheduleId,
        ], __undefined, __undefined);
    };
    return ScheduleService;
}());
export { ScheduleService };
