import { Flex, Table } from 'antd'
import type { CourseRoundId } from '../../../../commonTypes'
import { VisibleColumnsSelector } from '../../../../components/VisibleColumnsSelector'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { comparing } from '../../../../utils/compareUtil'
import { rowFilter } from '../../../../utils/filtering'
import { FilterInput } from '../../../schedule/components/FilterInput'
import { useFiltering } from '../../../schedule/components/FilterInput/hooks'
import type { CourseRoundRow } from '../../types'
import { NewCourseRoundButton } from '../NewCourseRoundButton'
import { useCourseRoundColumns } from './columns'
import type { CourseRoundsTableProps } from './types'

const CourseRoundsTable = ({ selectedRowKeys, setSelectedRowKeys }: CourseRoundsTableProps) => {
    const filtering = useFiltering()

    const schedule = useLocalSchedule()
    const { columns, setVisibleColumns } = useCourseRoundColumns(filtering)

    const dataSourceUnfiltered: CourseRoundRow[] = schedule.getCourseRounds().map((courseRound) => ({ courseRound }))
    const dataSource = dataSourceUnfiltered
        .filter(rowFilter(filtering, columns))
        .toSorted(comparing(({ courseRound }) => courseRound.getDisplayName()))

    return (
        <Table<CourseRoundRow>
            title={() => (
                <Flex gap={8}>
                    <FilterInput {...filtering} />
                    <VisibleColumnsSelector columns={columns} setVisibleColumns={setVisibleColumns} />
                    <NewCourseRoundButton
                        onNewCourseRound={(newCourseRoundId) => setSelectedRowKeys([newCourseRoundId])}
                    />
                </Flex>
            )}
            bordered
            rowSelection={{
                selectedRowKeys,
                onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys as CourseRoundId[])
            }}
            dataSource={dataSource}
            showSorterTooltip={false}
            columns={columns}
            size="small"
            rowKey={({ courseRound }) => courseRound.getCourseRoundId()}
            sticky
            onRow={({ courseRound }) => ({
                onClick: () => setSelectedRowKeys([courseRound.getCourseRoundId()])
            })}
            style={{ height: '100%' }}
            pagination={false}
        />
    )
}

export default CourseRoundsTable
