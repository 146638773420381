import { ITeacher } from 'common-api'
import { CmpFn, combinedCmpFn, comparing } from '../../../../../../utils/compareUtil'
import { fullJoin } from '../../../../../import-export/export-util'

export type DiffResult<T> = {
    created: T[]
    updated: [T, T][]
    deleted: T[]
}

export const diff = <T>(orig: T[], niew: T[], idFn: (e: T) => string, cmpFn: CmpFn<T>): DiffResult<T> => {
    const created = []
    const updated: [T, T][] = []
    const deleted = []

    for (let [origE, newE] of fullJoin(orig, idFn, niew, idFn)) {
        if (origE === undefined && newE !== undefined) {
            created.push(newE)
        } else if (origE !== undefined && newE === undefined) {
            deleted.push(origE)
        } else if (origE !== undefined && newE !== undefined && cmpFn(origE, newE) !== 0) {
            updated.push([origE, newE])
        }
    }

    return { created, updated, deleted }
}

export const teacherCmp = combinedCmpFn<ITeacher>(
    comparing((t) => t.teacherSchoolId),
    comparing((t) => t.firstName),
    comparing((t) => t.lastName),
    comparing((t) => t.qualifications.toSorted((q1, q2) => q1.localeCompare(q2, 'sv')).join(',')),
    comparing((t) => t.workPercentage)
)

export const anyDiff = (diffResult: DiffResult<ITeacher>) => {
    return diffResult.deleted.length > 0 || diffResult.created.length > 0 || diffResult.updated.length > 0
}
