import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TypeScale from '../../styles/TypeScale'
import Button from '../Button'
import Modal from '../Modal'
import classes from './style.module.css'

type AlertOptions = {
    title: string
    content?: string | ReactNode
    buttonText?: string
    cancelText?: string
    destructive?: boolean
    onContinue: () => void
    onCancel?: () => void
}

export type AlertContextState = {
    isOpen: boolean
    open: () => void
    close: () => void
    set: Dispatch<SetStateAction<AlertOptions>>
}

export const AlertContext = createContext<AlertContextState>({
    isOpen: false,
    open: () => {},
    close: () => {},
    set: () => {}
})

type AlertProviderProps = {
    children: ReactNode
}

const AlertProvider = ({ children }: AlertProviderProps) => {
    const { t } = useTranslation()
    const [visible, setVisible] = useState(false)

    const [alert, setAlert] = useState<AlertOptions>({
        title: '',
        content: undefined,
        buttonText: undefined,
        cancelText: undefined,
        destructive: undefined,
        onContinue: () => {},
        onCancel: () => {}
    })

    const { title } = alert

    const open = useCallback(() => {
        setVisible(true)
    }, [])

    const close = useCallback(() => {
        setVisible(false)
    }, [])

    const handleCancel = () => {
        close()
        alert.onCancel?.()
    }

    const handleContinue = () => {
        close()
        alert.onContinue()
    }

    const contextValue = useMemo(() => {
        return {
            isOpen: visible,
            open,
            close,
            set: setAlert
        }
    }, [visible, open, close])

    return (
        <AlertContext.Provider value={contextValue}>
            <Modal aria-label={title ?? ''} open={visible} onClose={handleCancel} className={classes.alert}>
                <div className={classes.alert__content}>
                    <h5 className={TypeScale.Heading_XL}>{title}</h5>
                    <p className={classes.alert__description}>{alert.content}</p>
                </div>
                <div className={classes.alert__footer}>
                    <Button variant="tertiary" onClick={handleCancel} className={classes.alert__button}>
                        {alert.cancelText ?? t('Cancel')}
                    </Button>
                    <Button autoFocus variant="primary" className={classes.alert__button} onClick={handleContinue}>
                        {alert.buttonText ?? t('Continue')}
                    </Button>
                </div>
            </Modal>
            {children}
        </AlertContext.Provider>
    )
}

type UseAlertOptions = {
    onChangeOpen?: (isOpen: boolean) => void
}

export const useAlert = (options?: UseAlertOptions): AlertContextState => {
    const context = useContext(AlertContext)

    useEffect(() => {
        if (options?.onChangeOpen) {
            options.onChangeOpen(context.isOpen)
        }
    }, [context.isOpen, options])

    return context
}

export default AlertProvider
