import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import { useDragLayer } from 'react-dnd'
import { useLocalSchedule } from '../../store/schedule/hooks'
import { DndItemTypes } from '../../utils/DndItemTypes'
import { CourseRoundCard } from './CourseRoundCard'
import { Positioned } from './Positioned'
import { CrDragCourseRoundType, mousePositionToRow } from './util'
import { useDispatch } from 'react-redux'
import { startDragCourseRound } from '../../store/schedule/actions'
import { ProblemListPopover } from '../../pages/schedule/components/ProblemListPopover'
import { IProblemWithScore } from 'common-api'
import { toProblemListProblem } from '../../pages/schedule/components/ProblemsList/utils'
import { useTranslation } from 'react-i18next'

interface DragLayerProps {
    maxes: number[]
    problemsByRow: IProblemWithScore[][]
    maxVisibleHours: number
}

export const DragLayer = (props: DragLayerProps) => {
    const dispatch = useDispatch()
    const schedule = useLocalSchedule()
    const { t } = useTranslation()

    const ref = useRef<HTMLDivElement>(null)

    const { itemType, item, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialClientOffset(),
        currentOffset: monitor.getClientOffset()
    }))

    const dragCourseRound = item as CrDragCourseRoundType
    const scheduleId = schedule.getScheduleId()
    const courseRoundId = dragCourseRound?.courseRoundId

    useEffect(() => {
        if (courseRoundId !== undefined) {
            dispatch(startDragCourseRound(scheduleId, courseRoundId))
        }
        return () => {}
    }, [dispatch, scheduleId, courseRoundId])

    const hoveredRow = mousePositionToRow(currentOffset, ref.current?.getBoundingClientRect())

    function renderItem() {
        switch (itemType) {
            case DndItemTypes.EVENT:
                return props.maxes.map((max, row) => {
                    const problemsForRow = props.problemsByRow[row].map((pws) => toProblemListProblem(schedule, pws, t))
                    return row !== item.row ? (
                        <Positioned
                            key={row}
                            row={row}
                            startHour={max}
                            totalHours={schedule.findCourseRound(item.courseRoundId).getTotalHours()}
                            maxVisibleHours={props.maxVisibleHours}
                            visible
                        >
                            {hoveredRow === row && (
                                <ProblemListPopover
                                    key={`popover-key-${row}`}
                                    problems={problemsForRow}
                                    open={problemsForRow.length > 0}
                                >
                                    <CourseRoundCard isInFilter courseRoundId={item.courseRoundId} />
                                </ProblemListPopover>
                            )}
                        </Positioned>
                    ) : undefined
                })
            default:
                return null
        }
    }

    return <DragLayerWrapper ref={ref}>{renderItem()}</DragLayerWrapper>
}

const DragLayerWrapper = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    padding-right: 1px;
    padding-left: 1px;
`
