import { PlusOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { RoomAssignmentInput } from './RoomAssignmentInput'
import { RoomAssignmentInputListProps } from './types'

export const RoomAssignmentListInput = ({ value, onChange }: RoomAssignmentInputListProps) => {
    const { t } = useTranslation()
    const addNewRoomAssignment = () =>
        onChange([...value, { roomId: undefined, requiredAttributes: [], pinned: false }])

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            {value.map((ra, index) => (
                <RoomAssignmentInput
                    key={index + JSON.stringify(ra)}
                    value={ra}
                    onChange={(newRoomAssignment) => onChange(value.toSpliced(index, 1, newRoomAssignment))}
                    onDelete={() => onChange(value.toSpliced(index, 1))}
                />
            ))}
            <Button onClick={addNewRoomAssignment} type="link" style={{ padding: 0 }}>
                <PlusOutlined />
                {t('AddRoom')}
            </Button>
        </Space>
    )
}
