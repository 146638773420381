import { useTranslation } from 'react-i18next'
import { EntityListTooltip } from '../../../../components/MultiRoomAttributeSelector/RoomsListTooltip/EntityListTooltip'
import { StudentGroupListTooltipProps } from './types'

export const StudentGroupListTooltip = ({ title, studentGroups }: StudentGroupListTooltipProps) => {
    const { t } = useTranslation()

    return (
        <EntityListTooltip
            title={title}
            entities={studentGroups}
            entityLabelFn={(sg) => sg.getDisplayName()}
            entityKeyFn={(sg) => sg.getStudentGroupId()}
            singularEntityLabel={t('StudentGroup')}
            pluralEntityLabel={t('MainMenu.StudentGroups')}
        />
    )
}
