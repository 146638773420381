import { ColumnHeightOutlined, MenuOutlined, SettingTwoTone, VerticalAlignMiddleOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Collapse, Dropdown, Empty } from 'antd'
import type * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AutoSchedulerControls } from '../../../../components/AutoSchedulerControls'
import { Checkmark } from '../../../../components/Checkmark'
import { DevUtilPanel } from '../../../../components/dev-util/DevUtilPanel'
import LecturesActionsPanel from '../../../../components/lectures/LectureActions'
import { LectureDetails } from '../../../../components/lectures/LectureDetails'
import { ReservedTimesActionsPanel } from '../../../../components/reserved-times/ReservedTimeActions'
import { ReservedTimeDetails } from '../../../../components/reserved-times/ReservedTimeDetails'
import type { ApplicationState } from '../../../../store'
import { useAutoSchedulerRunningState } from '../../../../store/autoscheduler/hooks'
import { AutoSchedulerRunningState } from '../../../../store/autoscheduler/types'
import { useDevModeState } from '../../../../store/devmode/hooks'
import { clearLectureSelection, clearReservedTimeSelection } from '../../../../store/lectureselector/actions'
import type { LectureSelectionState } from '../../../../store/lectureselector/types'
import { useLocalSchedule } from '../../../../store/schedule/hooks'
import { isBlocker } from '../../../../utils/problems'
import { ProblemsList } from '../ProblemsList'
import { ErrorIcon, WarningIcon } from '../ProblemsList/styled'
import type { GroupExpandedState, ProblemGrouping } from '../ProblemsList/types'
import { isVirtualProblem, toProblemListProblem } from '../ProblemsList/utils'
import classes from './style.module.css'

const { Panel } = Collapse

const ErrWarnSummary = () => {
    const devMode = useDevModeState()
    const schedule = useLocalSchedule()

    const [errCount, warnCount] = schedule
        .getSchedulingProblems()
        .filter((pws) => devMode || !isVirtualProblem(pws.problem))
        .map((problemWithScore) => (isBlocker(problemWithScore) ? [1, 0] : [0, 1]))
        .reduce(([prevErr, prevWarn], [deltaErr, deltaWarn]) => [prevErr + deltaErr, prevWarn + deltaWarn], [0, 0])

    const iconWithText = (icon: React.ReactNode, text: number) => (
        <>
            {icon} {text}
        </>
    )

    return (
        <>
            {errCount > 0 && iconWithText(<ErrorIcon />, errCount)}
            {errCount > 0 && warnCount > 0 && ' / '}
            {warnCount > 0 && iconWithText(<WarningIcon />, warnCount)}
        </>
    )
}

export const PanelSider = () => {
    const dispatch = useDispatch()
    const devMode = useDevModeState()
    const autoSchedulerState = useAutoSchedulerRunningState()

    const schedule = useLocalSchedule()
    const { t } = useTranslation()
    const selectionState = useSelector<ApplicationState, LectureSelectionState>((state) => state.lectureSelection)
    const [expanded, setExpanded] = useState<string[]>(['1', '2'])
    const [problemGrouping, setProblemGrouping] = useState<ProblemGrouping>('PROBLEM_TYPE')
    const [isProblemsMenuOpen, setProblemsMenuOpen] = useState(false)
    const [expandedState, setExpandedState] = useState<GroupExpandedState>()

    const problemGroupingMenuItem = (pg: ProblemGrouping, labelString: string) => ({
        key: pg,
        icon: <Checkmark style={{ marginRight: '.8em' }} selected={problemGrouping === pg} />,
        label: (
            <a
                href="#"
                onClick={(e) => {
                    setProblemGrouping(pg)
                    e.stopPropagation()
                    setProblemsMenuOpen(false)
                }}
            >
                {labelString}
            </a>
        )
    })

    const items: MenuProps['items'] = [
        problemGroupingMenuItem('PROBLEM_TYPE', t('GroupAfterType')),
        problemGroupingMenuItem('TEACHERS', t('GroupAfterTeacher')),
        problemGroupingMenuItem('KLASSES', t('GroupAfterClass')),
        { type: 'divider' },
        {
            key: 'collapse all',
            icon: <VerticalAlignMiddleOutlined />,
            label: (
                <a
                    href="#"
                    onClick={(e) => {
                        setExpandedState('COLLAPSED')
                        e.stopPropagation()
                        setProblemsMenuOpen(false)
                    }}
                >
                    {t('CollapseAll')}
                </a>
            )
        },
        {
            key: 'expand all',
            icon: <ColumnHeightOutlined />,
            label: (
                <a
                    href="#"
                    onClick={(e) => {
                        setExpandedState('EXPANDED')
                        e.stopPropagation()
                        setProblemsMenuOpen(false)
                    }}
                >
                    {t('ExpandAll')}
                </a>
            )
        }
    ]

    return (
        <div className={classes.panelSlider}>
            <Collapse
                defaultActiveKey={expanded}
                bordered={false}
                style={{ minHeight: '100vh', height: '100%' }}
                onChange={(keys) => {
                    if (Array.isArray(keys)) {
                        setExpanded(keys)
                    }
                }}
            >
                <Panel header={t('EditEvent')} key="1" style={{ overflowY: 'auto' }}>
                    {selectionState.selectedLectures.length > 0 ? (
                        <LectureDetails
                            key={schedule.getVersion() + selectionState.selectedLectures.join(',')}
                            lectureIds={selectionState.selectedLectures}
                        />
                    ) : selectionState.selectedReservedTimes.length > 0 ? (
                        <ReservedTimeDetails
                            key={schedule.getVersion() + selectionState.selectedReservedTimes.join(',')}
                            reservedTimeIds={selectionState.selectedReservedTimes}
                        />
                    ) : (
                        <Empty description={t('NoEventSelected')} />
                    )}
                </Panel>

                <Panel header={t('Actions')} key="2">
                    {selectionState.selectedLectures.length > 0 ? (
                        <LecturesActionsPanel
                            key={schedule.getVersion() + selectionState.selectedLectures.join(',')}
                            lectureIds={selectionState.selectedLectures}
                            onDeleteLecture={() => dispatch(clearLectureSelection())}
                        />
                    ) : selectionState.selectedReservedTimes.length > 0 ? (
                        <ReservedTimesActionsPanel
                            key={schedule.getVersion() + selectionState.selectedReservedTimes.join(',')}
                            reservedTimeIds={selectionState.selectedReservedTimes}
                            onDeleteReservedTime={() => dispatch(clearReservedTimeSelection())}
                        />
                    ) : (
                        <Empty description={t('NoEventSelected')} />
                    )}
                </Panel>

                <Panel
                    header={t('Problems')}
                    key="3"
                    style={{ maxHeight: '30em', overflowY: 'auto' }}
                    extra={
                        expanded.includes('3') ? (
                            <Dropdown menu={{ items }} trigger={['click']} open={isProblemsMenuOpen}>
                                <MenuOutlined
                                    onClick={(e) => {
                                        setProblemsMenuOpen(true)
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                />
                            </Dropdown>
                        ) : (
                            <ErrWarnSummary />
                        )
                    }
                >
                    <ProblemsList
                        problems={schedule.getSchedulingProblems().map((pws) => toProblemListProblem(schedule, pws, t))}
                        problemGrouping={problemGrouping}
                        initialGroupExpandedState={expandedState}
                    />
                </Panel>

                <Panel
                    header={t('Automation')}
                    key="4"
                    extra={autoSchedulerState === AutoSchedulerRunningState.RUNNING ? <SettingTwoTone spin /> : null}
                >
                    <AutoSchedulerControls />
                </Panel>

                {devMode ? (
                    <Panel header={t('Development')} key="5" style={{ overflowY: 'auto' }}>
                        <DevUtilPanel />
                    </Panel>
                ) : null}
            </Collapse>
        </div>
    )
}
