import { Form, Input, message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { type ILectureDurationThresholds, type IMinBreakThresholds, IScheduleTransform } from 'common-api'
import { range } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { SubjectId } from '../../../../commonTypes'
import BulkGeneralInput from '../../../../components/BulkGeneralInput'
import Button from '../../../../components/Button'
import { LectureDurationThresholdsInput } from '../../../../components/LectureDurationThresholdsInput'
import { MinBreakThresholdsInput } from '../../../../components/course-rounds/MinBreakThresholdsInput'
import type { SubjectAccessor } from '../../../../schedule-access/scheduleAccessWrappers'
import { locallyTriggeredScheduleTransform } from '../../../../store/schedule/actions'
import { comparing } from '../../../../utils/compareUtil'
import { toTranslate } from '../../../../utils/miscUtil'
import { MultiSubjectColorInput } from '../../../schedule/components/MultiSubjectColorInput'

type SubjectDetailsProps = {
    subjects: SubjectAccessor[]
}

type SubjectFormValues = {
    subjectIds: SubjectId[]
    codes: string[]
    names: string[]
    colors: string[]
    minBreakThresholds: (IMinBreakThresholds | undefined)[]
    lectureDurationThresholds: (ILectureDurationThresholds | undefined)[]
}

export const SubjectDetails = ({ subjects }: SubjectDetailsProps) => {
    const dispatch = useDispatch()
    const [form] = useForm()
    const { t } = useTranslation()

    const formValuesFromAccessors = (subjects: SubjectAccessor[]): SubjectFormValues => ({
        subjectIds: subjects.map((s) => s.getSubjectId()),
        codes: subjects.map((s) => s.getCode()),
        names: subjects.map((s) => s.getName()),
        colors: subjects.map((s) => s.getColor()),
        minBreakThresholds: subjects.map((s) => s.getMinBreakThresholds()),
        lectureDurationThresholds: subjects.map((s) => s.getLectureDurationThresholds())
    })

    subjects.sort(comparing((s) => s.getName()))

    const saveSubjects = (formValues: SubjectFormValues) => {
        const crTransforms = range(0, formValues.subjectIds.length).map((index) =>
            IScheduleTransform.subjectTransform({
                newSubject: {
                    subjectId: formValues.subjectIds[index],
                    code: formValues.codes[index],
                    name: formValues.names[index],
                    color: formValues.colors[index],
                    minBreakThresholds: formValues.minBreakThresholds[index],
                    lectureDurationThresholds: formValues.lectureDurationThresholds[index]
                }
            })
        )
        dispatch(locallyTriggeredScheduleTransform(IScheduleTransform.bulkTransform(crTransforms)))
        message.success(t(subjects.length === 1 ? 'Success.SubjectSaved' : 'Success.SubjectsSaved'))
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Form
                form={form}
                name="subjectForm"
                initialValues={formValuesFromAccessors(subjects)}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: '700px', width: '100%' }}
                onFinish={saveSubjects}
                labelWrap
                colon={false}
            >
                <Form.List name="subjectIds">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item hidden {...field}>
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.List name="codes">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item
                                label={index === 0 ? t('SubjectCode') : ' '}
                                style={{
                                    marginBottom: index === subjects.length - 1 ? undefined : '5px'
                                }}
                                {...field}
                            >
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.List name="names">
                    {(fields) =>
                        fields.map((field, index) => (
                            <Form.Item
                                label={index === 0 ? t('Names') : ' '}
                                style={{
                                    marginBottom: index === subjects.length - 1 ? undefined : '5px'
                                }}
                                {...field}
                            >
                                <Input />
                            </Form.Item>
                        ))
                    }
                </Form.List>
                <Form.Item name="colors" label={toTranslate('Färg')}>
                    <MultiSubjectColorInput subjectIds={subjects.map(s => s.getSubjectId())}  />
                </Form.Item>
                <Form.Item name="minBreakThresholds" label={t('BreakSettings')}>
                    <BulkGeneralInput<IMinBreakThresholds | undefined>
                        renderInput={(value, onChange) => <MinBreakThresholdsInput value={value} onChange={onChange} />}
                        multiplicityMessage={t('SelectSingleSubject.BreakRules')}
                    />
                </Form.Item>
                <Form.Item name="lectureDurationThresholds" label={t('LectureDuration')}>
                    <BulkGeneralInput<ILectureDurationThresholds | undefined>
                        renderInput={(value, onChange) => (
                            <LectureDurationThresholdsInput
                                value={value}
                                onChange={onChange}
                                inheritLabels={{
                                    currentEntityTypeName: t('EntityTypes.Subject'),
                                    inheritEntityTypeName: t('EntityTypes.Schedule')
                                }}
                                inheritedValues={subjects[0].getLectureDurationThresholds(true)}
                            />
                        )}
                        multiplicityMessage={t('SelectSingleSubject.LectureDurations')}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8 }}>
                    <Button variant="primary" type="submit">
                        {t('Save')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
