import { Cell, Row } from '@silevis/reactgrid'
import { IndexedLocation } from '@silevis/reactgrid/dist/types/InternalModel'
import { Flex } from 'antd'
import { ITeacher } from 'common-api'
import { isEqual, merge } from 'lodash'
import { useRef, useState } from 'react'
import EditTable from '../../../../../../EditTable'
import { useLocalSchedule } from '../../../../../../store/schedule/hooks'
import { BackgroundChangesAlert } from './BackgroundChangesAlert'
import { DEFAULT_CONTENT_CELL, DEFAULT_HEADER_CELL, HEADER_ROW, MIN_ROWS_TO_SHOW } from './constants'
import { anyDiff, diff, teacherCmp } from './diff'
import { GridActionsFooter } from './GridActionsFooter'
import { handleCopy } from './handleCopy'
import { handlePaste } from './handlePaste'
import {
    conjureSubjectsFromSubjects,
    conjureTeacherFromTeachers,
    sortedTeachersFromSchedule,
    useTeacherGridColumns
} from './hooks'
import { parseTeacherRows } from './teacherData'
import {
    ALL_CONTENT_COLUMNS,
    TeacherGridContentColumn,
    TeacherGridMetaDataColumn,
    TeacherGridProps,
    TeacherGridRow
} from './types'
import { formatPercentInput, generateEmptyTeacherRow, generateEmptyTeacherRows } from './util'
import { cellValidationErrors } from './validation'
import { useFocusedCell } from '../../../../../../EditTable/hooks'

export const TeacherGrid = ({
    teachers,
    setTeachers,
    onReset,
    onSave,
    teachersWhenEditingStarted
}: TeacherGridProps) => {
    const reactGridWrapper = useRef<HTMLDivElement>(null)
    const columns = useTeacherGridColumns(reactGridWrapper)

    const schedule = useLocalSchedule()
    const liveSubjects = conjureSubjectsFromSubjects(schedule.getSubjects())
    const liveTeachers = conjureTeacherFromTeachers(sortedTeachersFromSchedule(schedule))

    const validationProblems = cellValidationErrors(schedule, teachers)

    const [focusedCell, setFocusedCell] = useFocusedCell()

    const backgroundUpdates = diff<ITeacher>(teachersWhenEditingStarted, liveTeachers, (t) => t.teacherId, teacherCmp)

    const parsedTeachersFromCurrentEditTable = parseTeacherRows(liveTeachers, liveSubjects, teachers)
    const changesMade = anyDiff(diff(parsedTeachersFromCurrentEditTable, liveTeachers, (t) => t.teacherId, teacherCmp))

    const emptyRowsToAdd = Math.max(0, MIN_ROWS_TO_SHOW - teachers.length)
    const teacherGridData = [
        ...teachers,
        ...generateEmptyTeacherRows(teachers.length, teachers.length + emptyRowsToAdd)
    ]

    const rows = [
        HEADER_ROW,
        ...teacherGridData.map<Row>((_, index) => ({
            rowIndex: index + 1,
            height: 30
        }))
    ]

    const onAddMoreRows = (numRows: number) => {
        setTeachers([
            ...teacherGridData,
            ...generateEmptyTeacherRows(teacherGridData.length, teacherGridData.length + numRows)
        ])
    }

    const cells: Cell[] = columns
        .map((titledCol) => titledCol.title)
        .map((title, colIndex) =>
            merge({}, DEFAULT_HEADER_CELL, {
                rowIndex: 0,
                colIndex,
                props: {
                    value: title,
                    style: {
                        justifyContent: 'start'
                    }
                }
            })
        )

    teacherGridData.forEach((teacherRow, teacherIndex) => {
        const rowIndex = teacherIndex + 1 // + 1 to adjust for header row
        columns.forEach(({ colIndex, gridColumn }) => {
            if (gridColumn === TeacherGridMetaDataColumn.RowIndex) {
                cells.push(
                    merge({}, DEFAULT_HEADER_CELL, {
                        rowIndex,
                        colIndex,
                        props: {
                            value: `${rowIndex}`,
                            style: {
                                justifyContent: 'center'
                            }
                        }
                    })
                )
            } else {
                const overrides =
                    gridColumn == TeacherGridContentColumn.WorkPercentage
                        ? {
                              props: {
                                  onTextChanged: (newText: string) => {
                                      const newTeacherGridData = [...teacherGridData]
                                      newTeacherGridData[teacherIndex][TeacherGridContentColumn.WorkPercentage] =
                                          formatPercentInput(newText)
                                      setTeachers(newTeacherGridData)
                                  }
                              }
                          }
                        : {}
                cells.push(
                    merge(
                        {},
                        DEFAULT_CONTENT_CELL,
                        {
                            rowIndex,
                            colIndex,
                            props: {
                                text: teacherRow[gridColumn],
                                onTextChanged: (newText: string) => {
                                    const newTeacherGridData = [...teacherGridData]
                                    newTeacherGridData[teacherIndex][gridColumn] = newText
                                    setTeachers(newTeacherGridData)
                                },
                                validationProblems: validationProblems
                                    .filter((vp) => isEqual(vp.location, { rowIndex, colIndex }))
                                    .map((vp) => vp.problem)
                            }
                        },
                        overrides
                    )
                )
            }
        })
    })

    const handleClearTableContent = () => {
        setTeachers(generateEmptyTeacherRows(0, Math.max(teachers.length, MIN_ROWS_TO_SHOW)))
    }

    return (
        <Flex vertical style={{ height: '100%' }} ref={reactGridWrapper}>
            <EditTable
                onAddMoreRows={onAddMoreRows}
                onCellFocused={setFocusedCell}
                rows={rows}
                cells={cells}
                columns={columns}
                onCopy={handleCopy}
                onPaste={(e) => {
                    // Convert from "grid data coordinates" to "teacher data coordinates" (skip header row)
                    const targetCell = { rowIndex: focusedCell.rowIndex - 1, colIndex: focusedCell.colIndex }

                    const formatters = new Map<keyof TeacherGridRow, (input: string) => string>()
                    formatters.set(Number(TeacherGridContentColumn.WorkPercentage), formatPercentInput)

                    const newTeachers = handlePaste<TeacherGridRow>({
                        columns: ALL_CONTENT_COLUMNS,
                        currentRows: teacherGridData,
                        event: e,
                        generateEmptyRow: (dstRowIndex) => generateEmptyTeacherRow(dstRowIndex, '100 %'),
                        indexLocation: targetCell,
                        formatters
                    })
                    setTeachers(newTeachers)
                }}
            />
            {anyDiff(backgroundUpdates) && <BackgroundChangesAlert diff={backgroundUpdates} />}
            <GridActionsFooter
                onReset={onReset}
                onSave={onSave}
                saveEnabled={changesMade}
                handleClearTableContent={handleClearTableContent}
            />
        </Flex>
    )
}
