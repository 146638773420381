import type { FilterDropdownProps } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'
import { useSchedule } from '../../../store/schedule/hooks'
import { FilterableFilterDropdown } from '../../FilterableFilterDropdown'

export const TeacherFilterDropdown = (props: FilterDropdownProps) => {
    const schedule = useSchedule()
    const allTeacherFilters = schedule.getTeachers().map((s) => ({
        value: s.getTeacherId(),
        text: s.getTeacherSchoolId()
    }))

    const { t } = useTranslation()

    return (
        <FilterableFilterDropdown
            {...props}
            allFilters={allTeacherFilters}
            noKeysAvailableDescription={t('NoTeachers')}
        />
    )
}
