import type { ISchedule, IScheduleTransform, ITeacherProblemPair, ITimeProblemPair } from 'common-api'
import type { ActionType } from 'typesafe-actions'
import { action } from 'typesafe-actions'
import type { EventGroupId, ScheduleId } from '../../commonTypes'
import type * as scheduleActions from './actions'
import type { ScheduleVersion } from './types'
import { ScheduleActionTypes } from './types'

export const serverTriggeredScheduleTransform = (data: ISchedule) =>
    action(ScheduleActionTypes.SERVER_TRIGGERED_SCHEDULE_TRANSFORM, data)

export const locallyTriggeredScheduleTransform = (transform: IScheduleTransform) =>
    action(ScheduleActionTypes.LOCALLY_TRIGGERED_SCHEDULE_TRANSFORM, transform)

export const appendPendingScheduleTransform = (transform: IScheduleTransform, transformId: string) =>
    action(ScheduleActionTypes.APPEND_PENDING_SCHEDULE_TRANSFORM, {
        ...transform,
        transformId
    })

export const assignVersionToPendingScheduleTransform = (transformId: string, version: ScheduleVersion) =>
    action(ScheduleActionTypes.ASSIGN_VERSION_TO_PENDING_SCHEDULE_TRANSFORM, {
        transformId,
        version
    })

export const startDragLecture = (scheduleId: ScheduleId, eventGroupId: EventGroupId) =>
    action(ScheduleActionTypes.START_DRAG_EVENT_GROUP, {
        scheduleId,
        eventGroupId
    })

export const endDragLecture = () => action(ScheduleActionTypes.END_DRAG_LECTURE)

export const fetchedProblematicTimeslotsForLecture = (problematicTimeProblemPairs: ITimeProblemPair[]) =>
    action(ScheduleActionTypes.FETCHED_PROBLEMATIC_TIMESLOTS_FOR_LECTURE, problematicTimeProblemPairs)

export const startDragCourseRound = (scheduleId: ScheduleId, courseRoundId: EventGroupId) =>
    action(ScheduleActionTypes.START_DRAG_COURSE_ROUND, {
        scheduleId,
        courseRoundId
    })

export const endDragCourseRound = () => action(ScheduleActionTypes.END_DRAG_COURSE_ROUND)

export const fetchedProblematicTeachersForCr = (problematicTeacherCrProblemPairs: ITeacherProblemPair[]) =>
    action(ScheduleActionTypes.FETCHED_PROBLEMATIC_TEACHERS_FOR_CR, problematicTeacherCrProblemPairs)

export const subscribe = (scheduleId: ScheduleId) => action(ScheduleActionTypes.SUBSCRIBE, scheduleId)
export const unsubscribe = (scheduleId: ScheduleId) => action(ScheduleActionTypes.UNSUBSCRIBE, scheduleId)

export type ScheduleAction = ActionType<typeof scheduleActions>
