import { useState } from 'react'
import type { LectureId } from '../../commonTypes'
import TablePage from '../../components/PageContent'
import LecturesActionsPanel from '../../components/lectures/LectureActions'
import { LectureDetails } from '../../components/lectures/LectureDetails'
import LecturesTable from './components/LecturesTable'
import { useLocalSchedule } from '../../store/schedule/hooks'

export const LecturesIndexPage = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<LectureId[]>([])
    const schedule = useLocalSchedule()

    // If selected lecture has been deleted (for example in another browser tab) the selection may have become invalid.
    const validSelectedRowKeys = selectedRowKeys.filter((selectedLectureId) =>
        schedule.doesLectureIdExist(selectedLectureId)
    )

    const actionsPanel = (
        <LecturesActionsPanel lectureIds={validSelectedRowKeys} onDeleteLecture={() => setSelectedRowKeys([])} />
    )
    const detailsPanel = <LectureDetails key={validSelectedRowKeys.join(',')} lectureIds={validSelectedRowKeys} />

    return (
        <TablePage
            selectedRowKeys={validSelectedRowKeys}
            onEscape={() => setSelectedRowKeys([])}
            table={<LecturesTable selectedRowKeys={validSelectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />}
            sidePanels={[
                { key: 'edit', header: 'Redigera', panel: detailsPanel },
                { key: 'actions', header: 'Åtgärder', panel: actionsPanel }
            ]}
        />
    )
}
